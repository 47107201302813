.top-header__user {
  min-width: 180px;
  height: 45px;
  margin-right: 25px;
  position: relative;

  > button {
    display: flex;
    background-color: transparent;
    border: none;
    padding: 0;
    align-items: center;
  }

  &__avatar {
    height: 45px;
    width: 45px;
    max-width: 45px;
    @include object-fit(cover);
    flex-shrink: 0;
    border-radius: 50%;
    margin-right: 8px;
    border: 1px solid #3b4668;
    
    &.fallback-profile {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    span.reflex-sdds-icon {
      background-color: var(--tds-white);
      width: 18px;
      height: 18px;
    }
  }

  &__menu {
    position: absolute;
    top: 50px;
    right: 0;
    width: 200px;
    background-color: $white;
    display: flex;
    flex-direction: column;
    padding: 0 8px;
    border: 1px solid $scania-light-grey;
    z-index: 1041;

    @include media-breakpoint-down(md) {
      position: initial;
      top: auto;
      width: 100%;
    }

    &__item {
      background-color: transparent; 
      color: $scania-black;
      border: none;
      text-align: left;
      font-size: 11px;
      padding: 11px 10px;
      border-bottom: 1px solid $platina;
      font-family: $font-family-base;

      &:hover, &:visited {
        color: $scania-black;
        text-decoration: underline;
      }

      &:last-child {
        border-bottom: none;
      }
    }

    &-overlay {
      position: fixed;
      border: none;
      z-index: 1040; /* TODO: use bootstrap variable $zindex-modal-backdrop; */
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background-color: transparent;

      &--hidden {
        visibility: hidden;
      }

      @include media-breakpoint-down(md) {
        display: none;
      }
    }
  }

  .fallback {
    margin-right: 8px;

    i {
      color: $scania-white;
      font-size: 24px;
      height: 45px;
      width: 45px;
      border: 1px solid #3b4668;
      border-radius: 50%;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__attributes {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 0;
    text-align: left;


    &__name {
      color: var(--tds-white);
      font-size: 13px;
      font-weight: bold;
      line-height: 15px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      min-width: 0;
    }

    &__country {
      color: var(--tds-white);
      font-size: 9px;
      line-height: 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      min-width: 0;
      margin-top: 5px;

      & > i {
        font-size: 11px;
        margin-left: 10px;
      }
    }

  }

  &-placeholder--tablet {
    display: none;
  }

  @include media-breakpoint-down(md) {
    width: 45px;
    min-width: auto;

    &-placeholder {
      display: none;
    }

    &-placeholder--tablet {
      display: block;
    }

    &__avatar, .fallback {
      margin-right: 0;
    }

    &__attributes {
      display: none;
    }
  }

  @include media-breakpoint-down(md) {

    > a {
      padding-left: 0;
    }

    background-color: $scania-blue;
    width: 100%;
    padding: 0 30px;
    height: 55px;
    margin-right: 0;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #3b4668;

    &__avatar {
      height: 39px;
      width: 39px;
      margin-right: 10px;
    }

    .fallback {
      margin-right: 10px;

      i {
        font-size: 20px;
        height: 39px;
        width: 39px;
      }
    }

    &__attributes {
      display: flex;

      &__name {
        font-size: 13px;
        line-height: 15px;
      }

      &__country {
        margin-top: 3px;
        font-size: 12px;
        line-height: 14px;
      }
    }

  }
}
