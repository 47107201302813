$CONNECT_ENV: acceptance-test; $BOOTSTRAP_VERSION: "1.0.0";
/*FONTAWESOME*/
@import "~reflex-bootstrap/src/scss/vendor/font-awesome/fontawesome";
@import "~reflex-bootstrap/src/scss/vendor/font-awesome/fa-brands";
@import "~reflex-bootstrap/src/scss/vendor/font-awesome/fa-light";
@import "~reflex-bootstrap/src/scss/vendor/font-awesome/fa-regular";
@import "~reflex-bootstrap/src/scss/vendor/font-awesome/fa-solid";
/* SDDS */
@import '~@scania/theme-light/dist/styles/sdds-theme.css';
@import '~@scania/grid/dist/scss/grid';
@import './legacy/tegel.css';
@import url('@scania/tegel/dist/tegel/tegel.css');
@import url('https://cdn.digitaldesign.scania.com/icons/dist/1.0.0/fonts/css/sdds-icons.css');

/*REFLEX BOOTSTRAP*/
@import
  "~reflex-bootstrap/src/scss/abstracts/_functions",
  "~reflex-bootstrap/src/scss/abstracts/_variables",
  "~reflex-bootstrap/src/scss/abstracts/_colors",
  "~reflex-bootstrap/src/scss/abstracts/_mixins";
/*BOOTSTRAP*/
@import
  "~bootstrap/scss/_functions",
  "~bootstrap/scss/_variables",
  "~bootstrap/scss/_mixins";


.reflex {
  box-sizing: border-box;
  font-family: "Scania sans";
  line-height: 1.15;
  font-size: 16px;
  /*importing all scss from apps */
  @import "./variables";
  @import "./icons";
  @import "../apps/**/*.scss";
}
