
.profile {
  position: relative;
  &.container {
    padding-left: 45px;
    padding-right: 45px;
  }
  &-banner {
    height: 200px;
    background-color: $scania-blue;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  &-avatar {
    position: absolute;
    bottom: -65px;
    left: 70px;
    width: 150px;
    height: 150px;

    &__error {
      display: none;
    }

    .fallback-profile {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-color: $scania-blue;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 2px solid $silver;

      span.reflex-sdds-icon {
        width: 75px;
        height: 75px;
        background-color: $scania-white;
      }
    }

    &__placeholder {
      position: absolute;
      bottom: -65px;
      left: 70px;
      border-radius: 50%;
      width: 150px;
      height: 150px;
    }

    img {
      border-radius: 50%;
      width: 150px;
      height: 150px;
      @include object-fit(cover);
      border: 2px solid $silver;
      background-color: $scania-blue;
    }

    input[type=file] {
      display: none;
    }

    &__edit {
      display: flex;
      position: absolute;
      bottom: 0;
      left: 145px;
      width: 100%;
      justify-content: center;
      white-space: nowrap;
      flex-wrap: wrap;


      @media screen and (max-width: 380px) {
        left: 135px;
        width: 95%;
      }

      @media screen and (max-width: 320px) {
        left: 135px;
        width: 80%;
      }
    }
    
    &__delete-button,
    &__edit-button {
      width: 130px;

      @media screen and (max-width: 380px) {
        width: 90px;
      }

      @media screen and (max-width: 320px) {
        width: 80px;
      }
    }
  }
  &-icon {
    position: absolute;
    bottom: -65px;
    left: 70px;
    i {
      border-radius: 50%;
      width: 150px;
      height: 150px;
      border: 2px solid $silver;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $scania-blue;
      @include fontAwesome(user) {
        font-size: 65px;
        color: $scania-white;
      }
    }
  }
  &-wrapper {
    margin-top: 60px;
    &__edit, &__teams {
      display: flex;
      margin-left: auto;
    }
  }
  &-header {
    display: flex;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 15px;
    .profile-item, h1 {
      margin-bottom: 0;
    }
    @include media-breakpoint-down(sm) {
      flex-direction: column-reverse;
      align-items: flex-start;
      .profile-item, h1 {
        margin-top: 10px;
      }
    }
  }
  &-privacy {
    margin-bottom: 15px;
  }
  &-item {
    margin-bottom: 12px;
    width: 100%;
    > div, a {
      @include ellipsis();
    }
    .reflex-select__container {
      overflow: visible
    }
    &.profile-title {
      font-size: 12px;
      font-weight: bold;
      color: $scania-black;
      width: auto;
      h1 {
        @include ellipsis();
      }
      @include media-breakpoint-down(sm) {
        width: 100%;
      }
    }
    a.profile-btn {
      width: auto;
    }
  }
  &-about {
    > p {
      white-space: pre-wrap;
      overflow-wrap: break-word;
      word-wrap: break-word;
      word-break: break-word;
    }
  }
  &-information {
    &__jobTitle > div {
      white-space: inherit;
      overflow: inherit;
    }
    &__location {
      &-item {
        white-space: pre-line;
      }
    }
  }
  &-badges {
    & .profile-title {
      margin-bottom: 4px;
    }
    & .banner-alert {
      margin-bottom: 12px;
    }

    &__item {
      @include media-breakpoint-down(sm) {
        flex: 0 0 33%;
      }
      @include media-breakpoint-down(xs) {
        flex: 0 0 50%;
      }
    }
  }
  &-skills,
  &-subscriptions {
    margin-bottom: 12px;

    .profile-title {
      @include ellipsis();
      margin-bottom: 4px;
    }
    .reflex-select__container {
      max-width: 100%;

      &--subscriptions {
        margin: 4px 118px 4px 4px;
      }

      &--skills {
        margin: 4px 48px 4px 4px;
      }

      &--custom {
        position: relative;

        .sdds-chip {
          margin-right: 0;
          margin-bottom: 0;
          border-radius: 100px 0 0 100px;
          padding: 8px 8px 8px 16px;
        }

        .reflex-subscriptions-toggles {
          display: inline-flex;
          align-items: center;
          top: 0;
          position: absolute;
          bottom: 0;
          padding-right: 16px;
          border-radius: 0 100px 100px 0;
          background-color: var(--sdds-chips-background);
          
          & :first-child {
            margin-right: 10px;
          }

          & .sdds-toggle {
            flex-wrap: nowrap;

            &.disabled {
              .sdds-toggle-switch::before {
                background-color: var(--sdds-grey-400);
              }
            }

            & label {
              padding: 0;
              margin: 0;
              width: 16px;
              height: 16px;
              font-size: 16px;

              & i {
                width: 16px;
                height: 16px;
                margin-left: 2px;
              }
            }
          }

          & .sdds-toggle-webcomponent {
            display: flex;
            margin-right: 5px;

            & label {
              display: none;
            }
          }
        }

        button {
          all: unset;
          color: var(--sdds-chips-color);
          background-color: var(--sdds-chips-background);
          padding: 8px 16px;
          border-radius: 0 100px 100px 0;
          position: absolute;
          top: 0;
          bottom: 0;
          right: -49px;
          border-left: 1px solid var(--sdds-chips-background-hover);

          &.disabled {
            background-color: var(--sdds-chips-background-hover);
          }

          &:hover {
            background-color: var(--sdds-chips-background-hover);
            cursor: pointer;
          }
        }
      }
    }


  }
  &-error {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50vh;
  }
  &__placeholder-text {
    margin-top: 40px;
  }
  &__placeholder-card {
    height: 50px;
  }

&-edit {
  &__cancel {
    margin-right: 15px;
  }
}

}
// MOVE TO BOOTSTRAP
.spinner-border {
  margin-left: 10px;
  display: inline-block;
  width: 1rem;
  height: 1rem;
  vertical-align: text-bottom;
  border: .25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border .75s linear infinite;
  animation: spinner-border .75s linear infinite;
}

@keyframes spinner-border {
  to { transform: rotate(360deg); }
}

.reflex-input {
  border-color: $scania-light-grey;
  color: $scania-black;
  min-height: 50px;
  padding: 14px;
  &::placeholder {
    color: $medium-grey
  }
}


.form-control:disabled {
  cursor: not-allowed;
  background-color: #f5f5f5;
  color: #7a7a7a;
}

// MOVE TO BOOTSTRAP
