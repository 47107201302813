$CONNECT_ENV: acceptance-test; $BOOTSTRAP_VERSION: "1.0.0";
:root,
html {
  --sdds-background-image-scania-wordmark-png: url(https://cdn.digitaldesign.scania.com/logotype/1.0.0/scania_wordmark/scania-wordmark.png);
  --sdds-background-image-scania-wordmark-svg: url(https://cdn.digitaldesign.scania.com/logotype/1.0.0/scania_wordmark/scania-wordmark.svg);
  --sdds-background-image-scania-wordmark-white-png: url(https://cdn.digitaldesign.scania.com/logotype/1.0.0/scania_wordmark-white/scania-wordmark-white.png);
  --sdds-background-image-scania-wordmark-white-svg: url(https://cdn.digitaldesign.scania.com/logotype/1.0.0/scania_wordmark-white/scania-wordmark-white.svg);
  --sdds-background-image-scania-symbol-png: url(https://cdn.digitaldesign.scania.com/logotype/1.0.0/scania_symbol/scania-symbol.png);
  --sdds-background-image-scania-symbol-svg: url(https://cdn.digitaldesign.scania.com/logotype/1.0.0/scania_symbol/scania-symbol.svg);
  --sdds-background-image-scania-logotype-png: url(https://cdn.digitaldesign.scania.com/logotype/1.0.0/scania_logotype/scania-logotype.png);
  --sdds-background-image-scania-logotype-svg: url(https://cdn.digitaldesign.scania.com/logotype/1.0.0/scania_logotype/scania-logotype.svg)
}

:root,
html {
  --sdds-grey-958: #0d0f13;
  --sdds-grey-900: #1d2229;
  --sdds-grey-868: #242a33;
  --sdds-grey-846: #2b323d;
  --sdds-grey-800: #37414f;
  --sdds-grey-700: #56657a;
  --sdds-grey-600: #868fa2;
  --sdds-grey-500: #b0b7c4;
  --sdds-grey-400: #cdd1db;
  --sdds-grey-300: #dbdfe6;
  --sdds-grey-200: #e7e9ee;
  --sdds-grey-100: #edeff3;
  --sdds-grey-50: #f9fafb;
  --sdds-blue-900: #001533;
  --sdds-blue-800: #041e42;
  --sdds-blue-700: #0f3263;
  --sdds-blue-600: #16417f;
  --sdds-blue-500: #2058a8;
  --sdds-blue-400: #2b70d3;
  --sdds-blue-300: #4a89f3;
  --sdds-blue-200: #87afe8;
  --sdds-blue-100: #bacde8;
  --sdds-blue-50: #e4e9f1;
  --sdds-red-900: #450307;
  --sdds-red-800: #6a080f;
  --sdds-red-700: #950a16;
  --sdds-red-600: #d6001c;
  --sdds-red-500: #de2b36;
  --sdds-red-400: #f35359;
  --sdds-red-300: #f58e90;
  --sdds-red-200: #f8b6b7;
  --sdds-red-100: #fadbdc;
  --sdds-red-50: #fdf4f5;
  --sdds-green-900: #112616;
  --sdds-green-800: #1c3822;
  --sdds-green-700: #2c5234;
  --sdds-green-600: #386e44;
  --sdds-green-500: #438151;
  --sdds-green-400: #539e63;
  --sdds-green-300: #89b691;
  --sdds-green-200: #a6d1ad;
  --sdds-green-100: #caebd0;
  --sdds-green-50: #f1f8f2;
  --sdds-orange-900: #331103;
  --sdds-orange-800: #893b15;
  --sdds-orange-700: #c65416;
  --sdds-orange-600: #eb6200;
  --sdds-orange-500: #fa6e23;
  --sdds-orange-400: #f98a67;
  --sdds-orange-300: #f9a38d;
  --sdds-orange-200: #f9cac0;
  --sdds-orange-100: #fbe3df;
  --sdds-orange-50: #fdf5f3;
  --sdds-cta: #0f3263;
  --sdds-negative: #ff2340;
  --sdds-warning: #f1c21b;
  --sdds-positive: #1dab8b;
  --sdds-information: #2b70d3;
  --sdds-black: #000;
  --sdds-white: #fff;
  --sdds-blue: #041e42;
  --sdds-red: #d6001c
}

.sdds-background-grey-958 {
  background-color: #0d0f13
}

.sdds-background-grey-900 {
  background-color: #1d2229
}

.sdds-background-grey-868 {
  background-color: #242a33
}

.sdds-background-grey-846 {
  background-color: #2b323d
}

.sdds-background-grey-800 {
  background-color: #37414f
}

.sdds-background-grey-700 {
  background-color: #56657a
}

.sdds-background-grey-600 {
  background-color: #868fa2
}

.sdds-background-grey-500 {
  background-color: #b0b7c4
}

.sdds-background-grey-400 {
  background-color: #cdd1db
}

.sdds-background-grey-300 {
  background-color: #dbdfe6
}

.sdds-background-grey-200 {
  background-color: #e7e9ee
}

.sdds-background-grey-100 {
  background-color: #edeff3
}

.sdds-background-grey-50 {
  background-color: #f9fafb
}

.sdds-background-blue-900 {
  background-color: #001533
}

.sdds-background-blue-800 {
  background-color: #041e42
}

.sdds-background-blue-700 {
  background-color: #0f3263
}

.sdds-background-blue-600 {
  background-color: #16417f
}

.sdds-background-blue-500 {
  background-color: #2058a8
}

.sdds-background-blue-400 {
  background-color: #2b70d3
}

.sdds-background-blue-300 {
  background-color: #4a89f3
}

.sdds-background-blue-200 {
  background-color: #87afe8
}

.sdds-background-blue-100 {
  background-color: #bacde8
}

.sdds-background-blue-50 {
  background-color: #e4e9f1
}

.sdds-background-red-900 {
  background-color: #450307
}

.sdds-background-red-800 {
  background-color: #6a080f
}

.sdds-background-red-700 {
  background-color: #950a16
}

.sdds-background-red-600 {
  background-color: #d6001c
}

.sdds-background-red-500 {
  background-color: #de2b36
}

.sdds-background-red-400 {
  background-color: #f35359
}

.sdds-background-red-300 {
  background-color: #f58e90
}

.sdds-background-red-200 {
  background-color: #f8b6b7
}

.sdds-background-red-100 {
  background-color: #fadbdc
}

.sdds-background-red-50 {
  background-color: #fdf4f5
}

.sdds-background-green-900 {
  background-color: #112616
}

.sdds-background-green-800 {
  background-color: #1c3822
}

.sdds-background-green-700 {
  background-color: #2c5234
}

.sdds-background-green-600 {
  background-color: #386e44
}

.sdds-background-green-500 {
  background-color: #438151
}

.sdds-background-green-400 {
  background-color: #539e63
}

.sdds-background-green-300 {
  background-color: #89b691
}

.sdds-background-green-200 {
  background-color: #a6d1ad
}

.sdds-background-green-100 {
  background-color: #caebd0
}

.sdds-background-green-50 {
  background-color: #f1f8f2
}

.sdds-background-orange-900 {
  background-color: #331103
}

.sdds-background-orange-800 {
  background-color: #893b15
}

.sdds-background-orange-700 {
  background-color: #c65416
}

.sdds-background-orange-600 {
  background-color: #eb6200
}

.sdds-background-orange-500 {
  background-color: #fa6e23
}

.sdds-background-orange-400 {
  background-color: #f98a67
}

.sdds-background-orange-300 {
  background-color: #f9a38d
}

.sdds-background-orange-200 {
  background-color: #f9cac0
}

.sdds-background-orange-100 {
  background-color: #fbe3df
}

.sdds-background-orange-50 {
  background-color: #fdf5f3
}

.sdds-background-cta {
  background-color: #0f3263
}

.sdds-background-negative {
  background-color: #ff2340
}

.sdds-background-warning {
  background-color: #f1c21b
}

.sdds-background-positive {
  background-color: #1dab8b
}

.sdds-background-information {
  background-color: #2b70d3
}

.sdds-background-black {
  background-color: #000
}

.sdds-background-white {
  background-color: #fff
}

.sdds-background-blue {
  background-color: #041e42
}

.sdds-background-red {
  background-color: #d6001c
}

.sdds-text-grey-958 {
  color: #0d0f13
}

.sdds-text-grey-900 {
  color: #1d2229
}

.sdds-text-grey-868 {
  color: #242a33
}

.sdds-text-grey-846 {
  color: #2b323d
}

.sdds-text-grey-800 {
  color: #37414f
}

.sdds-text-grey-700 {
  color: #56657a
}

.sdds-text-grey-600 {
  color: #868fa2
}

.sdds-text-grey-500 {
  color: #b0b7c4
}

.sdds-text-grey-400 {
  color: #cdd1db
}

.sdds-text-grey-300 {
  color: #dbdfe6
}

.sdds-text-grey-200 {
  color: #e7e9ee
}

.sdds-text-grey-100 {
  color: #edeff3
}

.sdds-text-grey-50 {
  color: #f9fafb
}

.sdds-text-blue-900 {
  color: #001533
}

.sdds-text-blue-800 {
  color: #041e42
}

.sdds-text-blue-700 {
  color: #0f3263
}

.sdds-text-blue-600 {
  color: #16417f
}

.sdds-text-blue-500 {
  color: #2058a8
}

.sdds-text-blue-400 {
  color: #2b70d3
}

.sdds-text-blue-300 {
  color: #4a89f3
}

.sdds-text-blue-200 {
  color: #87afe8
}

.sdds-text-blue-100 {
  color: #bacde8
}

.sdds-text-blue-50 {
  color: #e4e9f1
}

.sdds-text-red-900 {
  color: #450307
}

.sdds-text-red-800 {
  color: #6a080f
}

.sdds-text-red-700 {
  color: #950a16
}

.sdds-text-red-600 {
  color: #d6001c
}

.sdds-text-red-500 {
  color: #de2b36
}

.sdds-text-red-400 {
  color: #f35359
}

.sdds-text-red-300 {
  color: #f58e90
}

.sdds-text-red-200 {
  color: #f8b6b7
}

.sdds-text-red-100 {
  color: #fadbdc
}

.sdds-text-red-50 {
  color: #fdf4f5
}

.sdds-text-green-900 {
  color: #112616
}

.sdds-text-green-800 {
  color: #1c3822
}

.sdds-text-green-700 {
  color: #2c5234
}

.sdds-text-green-600 {
  color: #386e44
}

.sdds-text-green-500 {
  color: #438151
}

.sdds-text-green-400 {
  color: #539e63
}

.sdds-text-green-300 {
  color: #89b691
}

.sdds-text-green-200 {
  color: #a6d1ad
}

.sdds-text-green-100 {
  color: #caebd0
}

.sdds-text-green-50 {
  color: #f1f8f2
}

.sdds-text-orange-900 {
  color: #331103
}

.sdds-text-orange-800 {
  color: #893b15
}

.sdds-text-orange-700 {
  color: #c65416
}

.sdds-text-orange-600 {
  color: #eb6200
}

.sdds-text-orange-500 {
  color: #fa6e23
}

.sdds-text-orange-400 {
  color: #f98a67
}

.sdds-text-orange-300 {
  color: #f9a38d
}

.sdds-text-orange-200 {
  color: #f9cac0
}

.sdds-text-orange-100 {
  color: #fbe3df
}

.sdds-text-orange-50 {
  color: #fdf5f3
}

.sdds-text-cta {
  color: #0f3263
}

.sdds-text-negative {
  color: #ff2340
}

.sdds-text-warning {
  color: #f1c21b
}

.sdds-text-positive {
  color: #1dab8b
}

.sdds-text-information {
  color: #2b70d3
}

.sdds-text-black {
  color: #000
}

.sdds-text-white {
  color: #fff
}

.sdds-text-blue {
  color: #041e42
}

.sdds-text-red {
  color: #d6001c
}

@font-face {
  font-family: "Scania Sans";
  font-weight: bold;
  unicode-range: U+0400-04FF;
  src: url("https://cdn.digitaldesign.scania.com/fonts/scania-sans/1.0.0/cyrillic/ScaniaSansCY-Bold.woff") format("woff")
}

@font-face {
  font-family: "Scania Sans";
  font-style: italic;
  unicode-range: U+0400-04FF;
  src: url("https://cdn.digitaldesign.scania.com/fonts/scania-sans/1.0.0/cyrillic/ScaniaSansCY-Italic.woff") format("woff")
}

@font-face {
  font-family: "Scania Sans";
  unicode-range: U+0400-04FF;
  src: url("https://cdn.digitaldesign.scania.com/fonts/scania-sans/1.0.0/cyrillic/ScaniaSansCY-Regular.woff") format("woff")
}

@font-face {
  font-family: "Scania Sans Condensed";
  font-weight: bold;
  unicode-range: U+0400-04FF;
  src: url("https://cdn.digitaldesign.scania.com/fonts/scania-sans/1.0.0/cyrillic/ScaniaSansCYCondensed-Bold.woff") format("woff")
}

@font-face {
  font-family: "Scania Sans Condensed";
  font-style: italic;
  unicode-range: U+0400-04FF;
  src: url("https://cdn.digitaldesign.scania.com/fonts/scania-sans/1.0.0/cyrillic/ScaniaSansCYCondensed-Italic.woff") format("woff")
}

@font-face {
  font-family: "Scania Sans Condensed";
  unicode-range: U+0400-04FF;
  src: url("https://cdn.digitaldesign.scania.com/fonts/scania-sans/1.0.0/cyrillic/ScaniaSansCYCondensed-Regular.woff") format("woff")
}

@font-face {
  font-family: "Scania Sans Headline";
  font-weight: bold;
  unicode-range: U+0400-04FF;
  src: url("https://cdn.digitaldesign.scania.com/fonts/scania-sans/1.0.0/cyrillic/ScaniaSansCYHeadline-Bold.woff") format("woff")
}

@font-face {
  font-family: "Scania Sans Headline";
  unicode-range: U+0400-04FF;
  src: url("https://cdn.digitaldesign.scania.com/fonts/scania-sans/1.0.0/cyrillic/ScaniaSansCYHeadline-Regular.woff") format("woff")
}

@font-face {
  font-family: "Scania Sans Semi Condensed";
  font-weight: bold;
  unicode-range: U+0400-04FF;
  src: url("https://cdn.digitaldesign.scania.com/fonts/scania-sans/1.0.0/cyrillic/ScaniaSansCYSemiCondensed-Bold.woff") format("woff")
}

@font-face {
  font-family: "Scania Sans Semi Condensed";
  font-style: italic;
  unicode-range: U+0400-04FF;
  src: url("https://cdn.digitaldesign.scania.com/fonts/scania-sans/1.0.0/cyrillic/ScaniaSansCYSemiCondensed-Italic.woff") format("woff")
}

@font-face {
  font-family: "Scania Sans Semi Condensed";
  unicode-range: U+0400-04FF;
  src: url("https://cdn.digitaldesign.scania.com/fonts/scania-sans/1.0.0/cyrillic/ScaniaSansCYSemiCondensed-Regular.woff") format("woff")
}

@font-face {
  font-family: "Scania Sans";
  font-weight: bold;
  src: url("https://cdn.digitaldesign.scania.com/fonts/scania-sans/1.0.0/latin/ScaniaSans-Bold.woff") format("woff")
}

@font-face {
  font-family: "Scania Sans";
  font-style: italic;
  src: url("https://cdn.digitaldesign.scania.com/fonts/scania-sans/1.0.0/latin/ScaniaSans-Italic.woff") format("woff")
}

@font-face {
  font-family: "Scania Sans";
  src: url("https://cdn.digitaldesign.scania.com/fonts/scania-sans/1.0.0/latin/ScaniaSans-Regular.woff") format("woff")
}

@font-face {
  font-family: "Scania Sans Condensed";
  font-weight: bold;
  src: url("https://cdn.digitaldesign.scania.com/fonts/scania-sans/1.0.0/latin/ScaniaSansCondensed-Bold.woff") format("woff")
}

@font-face {
  font-family: "Scania Sans Condensed";
  font-style: italic;
  src: url("https://cdn.digitaldesign.scania.com/fonts/scania-sans/1.0.0/latin/ScaniaSansCondensed-Italic.woff") format("woff")
}

@font-face {
  font-family: "Scania Sans Condensed";
  src: url("https://cdn.digitaldesign.scania.com/fonts/scania-sans/1.0.0/latin/ScaniaSansCondensed-Regular.woff") format("woff")
}

@font-face {
  font-family: "Scania Sans Headline";
  font-weight: bold;
  src: url("https://cdn.digitaldesign.scania.com/fonts/scania-sans/1.0.0/latin/ScaniaSansHeadline-Bold.woff") format("woff")
}

@font-face {
  font-family: "Scania Sans Headline";
  src: url("https://cdn.digitaldesign.scania.com/fonts/scania-sans/1.0.0/latin/ScaniaSansHeadline-Regular.woff") format("woff")
}

@font-face {
  font-family: "Scania Sans Semi Condensed";
  font-weight: bold;
  src: url("https://cdn.digitaldesign.scania.com/fonts/scania-sans/1.0.0/latin/ScaniaSansSemiCondensed-Bold.woff") format("woff")
}

@font-face {
  font-family: "Scania Sans Semi Condensed";
  font-style: italic;
  src: url("https://cdn.digitaldesign.scania.com/fonts/scania-sans/1.0.0/latin/ScaniaSansSemiCondensed-Italic.woff") format("woff")
}

@font-face {
  font-family: "Scania Sans Semi Condensed";
  src: url("https://cdn.digitaldesign.scania.com/fonts/scania-sans/1.0.0/latin/ScaniaSansSemiCondensed-Regular.woff") format("woff")
}

:root {
  --sdds-headline-01: normal normal bold 40px/40px "Scania Sans", arial, helvetica, sans-serif;
  --sdds-headline-01-ls: 0;
  --sdds-headline-02: normal normal bold 32px/32px "Scania Sans", arial, helvetica, sans-serif;
  --sdds-headline-02-ls: -0.04em;
  --sdds-headline-03: normal normal bold 24px/24px "Scania Sans", arial, helvetica, sans-serif;
  --sdds-headline-03-ls: -0.03em;
  --sdds-headline-04: normal normal bold 20px/24px "Scania Sans", arial, helvetica, sans-serif;
  --sdds-headline-04-ls: -0.02em;
  --sdds-headline-05: normal normal bold 14px/24px "Scania Sans", arial, helvetica, sans-serif;
  --sdds-headline-05-ls: -0.02em;
  --sdds-headline-06: normal normal bold 14px/16px "Scania Sans", arial, helvetica, sans-serif;
  --sdds-headline-06-ls: -0.02em;
  --sdds-headline-07: normal normal bold 14px/16px "Scania Sans Semi Condensed", arial, helvetica,
    sans-serif;
  --sdds-headline-07-ls: -0.01em;
  --sdds-paragraph-01: normal normal normal 24px/32px "Scania Sans", arial, helvetica, sans-serif;
  --sdds-paragraph-01-ls: -0.03em;
  --sdds-paragraph-02: normal normal normal 20px/24px "Scania Sans", arial, helvetica, sans-serif;
  --sdds-paragraph-02-ls: -0.01em;
  --sdds-body-01: normal normal normal 16px/24px "Scania Sans", arial, helvetica, sans-serif;
  --sdds-body-01-ls: -0.02em;
  --sdds-body-02: normal normal normal 14px/20px "Scania Sans", arial, helvetica, sans-serif;
  --sdds-body-02-ls: -0.02em;
  --sdds-detail-01: normal normal normal 16px/24px "Scania Sans Semi Condensed",
    "Scania Sans Condensed", arial, helvetica, sans-serif;
  --sdds-detail-01-ls: -0.01em;
  --sdds-detail-02: normal normal normal 14px/16px "Scania Sans Semi Condensed",
    "Scania Sans Condensed", arial, helvetica, sans-serif;
  --sdds-detail-02-ls: -0.01em;
  --sdds-detail-03: normal normal normal 14px/20px "Scania Sans Semi Condensed",
    "Scania Sans Condensed", arial, helvetica, sans-serif;
  --sdds-detail-03-ls: -0.01em;
  --sdds-detail-04: normal normal bold 12px/16px "Scania Sans", arial, helvetica, sans-serif;
  --sdds-detail-04-ls: 0.08em;
  --sdds-detail-04-tt: uppercase;
  --sdds-detail-05: normal normal normal 12px/16px "Scania Sans Semi Condensed",
    "Scania Sans Condensed", arial, helvetica, sans-serif;
  --sdds-detail-05-ls: 0;
  --sdds-detail-06: normal normal bold 10px/16px "Scania Sans", arial, helvetica, sans-serif;
  --sdds-detail-06-ls: 0.08em;
  --sdds-detail-06-tt: uppercase;
  --sdds-detail-07: normal normal normal 10px/8px "Scania Sans Semi Condensed",
    "Scania Sans Condensed", arial, helvetica, sans-serif;
  --sdds-detail-07-ls: 0;
  --sdds-expressive-headline-01: normal normal bold 80px/80px "Scania Sans Headline", arial,
    helvetica, sans-serif;
  --sdds-expressive-headline-01-ls: 0;
  --sdds-expressive-headline-02: normal normal bold 56px/56px "Scania Sans Headline", arial,
    helvetica, sans-serif;
  --sdds-expressive-headline-02-ls: 0;
  --sdds-headline-ex-01: normal normal bold 40px/40px "Scania Sans Headline", arial, helvetica,
    sans-serif;
  --sdds-headline-ex-01-ls: 0;
  --sdds-headline-ex-02: normal normal bold 32px/32px "Scania Sans Headline", arial, helvetica,
    sans-serif;
  --sdds-headline-ex-02-ls: -0.04em;
  --sdds-headline-ex-03: normal normal bold 24px/24px "Scania Sans Headline", arial, helvetica,
    sans-serif;
  --sdds-headline-ex-03-ls: -0.03em;
  --sdds-headline-ex-04: normal normal bold 20px/24px "Scania Sans Headline", arial, helvetica,
    sans-serif;
  --sdds-headline-ex-04-ls: -0.01em;
  --sdds-headline-ex-05: normal normal bold 16px/24px "Scania Sans Headline", arial, helvetica,
    sans-serif;
  --sdds-headline-ex-05-ls: -0.02em;
  --sdds-headline-ex-06: normal normal bold 14px/16px "Scania Sans Headline", arial, helvetica,
    sans-serif;
  --sdds-headline-ex-06-ls: -0.02em;
  --sdds-headline-ex-07: normal normal bold 14px/16px "Scania Sans Semi Condensed", arial, helvetica,
    sans-serif;
  --sdds-headline-ex-07-ls: -0.01em;
  --sdds-paragraph-ex-01: normal normal normal 24px/32px "Scania Sans", arial, helvetica, sans-serif;
  --sdds-paragraph-ex-01-ls: -0.03em;
  --sdds-paragraph-ex-02: normal normal normal 20px/24px "Scania Sans", arial, helvetica, sans-serif;
  --sdds-paragraph-ex-02-ls: -0.01em;
  --sdds-body-ex-01: normal normal normal 16px/24px "Scania Sans", arial, helvetica, sans-serif;
  --sdds-body-ex-01-ls: -0.02em;
  --sdds-body-ex-02: normal normal normal 14px/20px "Scania Sans", arial, helvetica, sans-serif;
  --sdds-body-ex-02-ls: -0.01em;
  --sdds-detail-ex-01: normal normal normal 16px/24px "Scania Sans Semi Condensed",
    "Scania Sans Condensed", arial, helvetica, sans-serif;
  --sdds-detail-ex-01-ls: -0.01em;
  --sdds-detail-ex-02: normal normal normal 14px/16px "Scania Sans Semi Condensed",
    "Scania Sans Condensed", arial, helvetica, sans-serif;
  --sdds-detail-ex-02-ls: -0.01em;
  --sdds-detail-ex-03: normal normal normal 14px/20px "Scania Sans Semi Condensed",
    "Scania Sans Condensed", arial, helvetica, sans-serif;
  --sdds-detail-ex-03-ls: -0.01em;
  --sdds-detail-ex-04: normal normal bold 12px/16px "Scania Sans", arial, helvetica, sans-serif;
  --sdds-detail-ex-04-ls: 0.08em;
  --sdds-detail-ex-04-tt: uppercase;
  --sdds-detail-ex-05: normal normal normal 12px/16px "Scania Sans Semi Condensed",
    "Scania Sans Condensed", arial, helvetica, sans-serif;
  --sdds-detail-ex-05-ls: 0;
  --sdds-detail-ex-06: normal normal bold 10px/16px "Scania Sans", arial, helvetica, sans-serif;
  --sdds-detail-ex-06-ls: 0.08em;
  --sdds-detail-ex-06-tt: uppercase;
  --sdds-detail-ex-07: normal normal normal 10px/8px "Scania Sans Semi Condensed",
    "Scania Sans Condensed", arial, helvetica, sans-serif;
  --sdds-detail-ex-07-ls: 0
}

.sdds-headline-01 {
  font-family: "Scania Sans Headline", arial, helvetica, sans-serif;
  font-weight: bold;
  font-size: 2.5rem;
  line-height: 1;
  letter-spacing: 0
}

.sdds-headline-02 {
  font-family: "Scania Sans", arial, helvetica, sans-serif;
  font-weight: bold;
  font-size: 2rem;
  line-height: 1;
  letter-spacing: -0.04em
}

.sdds-headline-03 {
  font-family: "Scania Sans", arial, helvetica, sans-serif;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1;
  letter-spacing: -0.03em
}

.sdds-headline-04 {
  font-family: "Scania Sans", arial, helvetica, sans-serif;
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 1.2;
  letter-spacing: -0.01em
}

.sdds-headline-05 {
  font-family: "Scania Sans", arial, helvetica, sans-serif;
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1.5;
  letter-spacing: -0.02em
}

.sdds-headline-06 {
  font-family: "Scania Sans", arial, helvetica, sans-serif;
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1.14;
  letter-spacing: -0.02em
}

.sdds-headline-07 {
  font-family: "Scania Sans Semi Condensed", arial, helvetica, sans-serif;
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1.14;
  letter-spacing: -0.01em
}

.sdds-paragraph-01 {
  font-family: "Scania Sans", arial, helvetica, sans-serif;
  font-size: 1.5rem;
  line-height: 1.33;
  letter-spacing: -0.03em
}

.sdds-paragraph-02 {
  font-family: "Scania Sans", arial, helvetica, sans-serif;
  font-size: 1.25rem;
  line-height: 1.2;
  letter-spacing: -0.01em
}

.sdds-body-01 {
  font-family: "Scania Sans", arial, helvetica, sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: -0.02em
}

.sdds-body-02 {
  font-family: "Scania Sans", arial, helvetica, sans-serif;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: -0.01em
}

.sdds-detail-01 {
  font-family: "Scania Sans Semi Condensed", "Scania Sans Condensed", arial, helvetica, sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: -0.01em
}

.sdds-detail-02 {
  font-family: "Scania Sans Semi Condensed", "Scania Sans Condensed", arial, helvetica, sans-serif;
  font-size: 0.875rem;
  line-height: 1.14;
  letter-spacing: -0.01em
}

.sdds-detail-03 {
  font-family: "Scania Sans Semi Condensed", "Scania Sans Condensed", arial, helvetica, sans-serif;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: -0.01em
}

.sdds-detail-04 {
  font-family: "Scania Sans", arial, helvetica, sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 0.75rem;
  line-height: 1.33;
  letter-spacing: 0.08em
}

.sdds-detail-05 {
  font-family: "Scania Sans Semi Condensed", "Scania Sans Condensed", arial, helvetica, sans-serif;
  font-size: 0.75rem;
  line-height: 1.33;
  letter-spacing: 0
}

.sdds-detail-06 {
  font-family: "Scania Sans", arial, helvetica, sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 0.625rem;
  line-height: 1.6;
  letter-spacing: 0.08em
}

.sdds-detail-07 {
  font-family: "Scania Sans Semi Condensed", "Scania Sans Condensed", arial, helvetica, sans-serif;
  font-size: 0.625rem;
  line-height: 0.8;
  letter-spacing: 0
}

.sdds-expressive-headline-01 {
  font-family: "Scania Sans Headline", arial, helvetica, sans-serif;
  font-weight: bold;
  font-size: 5rem;
  line-height: 1;
  letter-spacing: 0
}

.sdds-expressive-headline-02 {
  font-family: "Scania Sans Headline", arial, helvetica, sans-serif;
  font-weight: bold;
  font-size: 3.5rem;
  line-height: 1;
  letter-spacing: 0
}

h1 {
  font-family: "Scania Sans Headline", arial, helvetica, sans-serif;
  font-weight: bold;
  font-size: 2.5rem;
  line-height: 1;
  letter-spacing: 0
}

h2 {
  font-family: "Scania Sans", arial, helvetica, sans-serif;
  font-weight: bold;
  font-size: 2rem;
  line-height: 1;
  letter-spacing: -0.04em
}

h3 {
  font-family: "Scania Sans", arial, helvetica, sans-serif;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1;
  letter-spacing: -0.03em
}

h4 {
  font-family: "Scania Sans", arial, helvetica, sans-serif;
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 1.2;
  letter-spacing: -0.01em
}

h5 {
  font-family: "Scania Sans", arial, helvetica, sans-serif;
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1.5;
  letter-spacing: -0.02em
}

h6 {
  font-family: "Scania Sans", arial, helvetica, sans-serif;
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1.14;
  letter-spacing: -0.02em
}

.h7 {
  font-family: "Scania Sans Semi Condensed", arial, helvetica, sans-serif;
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1.14;
  letter-spacing: -0.01em
}

:root,
html {
  --sdds-spacing-layout-8: 8px;
  --sdds-spacing-layout-16: 16px;
  --sdds-spacing-layout-24: 24px;
  --sdds-spacing-layout-32: 32px;
  --sdds-spacing-layout-48: 48px;
  --sdds-spacing-layout-64: 64px;
  --sdds-spacing-layout-72: 72px;
  --sdds-spacing-layout-96: 96px;
  --sdds-spacing-layout-128: 128px;
  --sdds-spacing-layout-160: 160px;
  --sdds-spacing-element-2: 2px;
  --sdds-spacing-element-4: 4px;
  --sdds-spacing-element-8: 8px;
  --sdds-spacing-element-12: 12px;
  --sdds-spacing-element-16: 16px;
  --sdds-spacing-element-20: 20px;
  --sdds-spacing-element-24: 24px;
  --sdds-spacing-element-32: 32px;
  --sdds-spacing-element-40: 40px;
  --sdds-spacing-element-48: 48px;
  --sdds-screen-s: 320px;
  --sdds-screen-md: 672px;
  --sdds-screen-l: 1056px;
  --sdds-screen-xl: 1312px;
  --sdds-screen-xxl: 1584px;
  --sdds-grid-xs: 0;
  --sdds-grid-sm: 608px;
  --sdds-grid-md: 800px;
  --sdds-grid-lg: 992px;
  --sdds-grid-xlg: 1184px;
  --sdds-grid-xxlg: 1376px;
  --sdds-grid-max: 1584px
}

:root,
html {
  --sdds-spacing-layout-8: 8px;
  --sdds-spacing-layout-16: 16px;
  --sdds-spacing-layout-24: 24px;
  --sdds-spacing-layout-32: 32px;
  --sdds-spacing-layout-48: 48px;
  --sdds-spacing-layout-64: 64px;
  --sdds-spacing-layout-72: 72px;
  --sdds-spacing-layout-96: 96px;
  --sdds-spacing-layout-128: 128px;
  --sdds-spacing-layout-160: 160px;
  --sdds-spacing-element-2: 2px;
  --sdds-spacing-element-4: 4px;
  --sdds-spacing-element-8: 8px;
  --sdds-spacing-element-12: 12px;
  --sdds-spacing-element-16: 16px;
  --sdds-spacing-element-20: 20px;
  --sdds-spacing-element-24: 24px;
  --sdds-spacing-element-32: 32px;
  --sdds-spacing-element-40: 40px;
  --sdds-spacing-element-48: 48px;
  --sdds-screen-s: 320px;
  --sdds-screen-md: 672px;
  --sdds-screen-l: 1056px;
  --sdds-screen-xl: 1312px;
  --sdds-screen-xxl: 1584px;
  --sdds-grid-xs: 0;
  --sdds-grid-sm: 608px;
  --sdds-grid-md: 800px;
  --sdds-grid-lg: 992px;
  --sdds-grid-xlg: 1184px;
  --sdds-grid-xxlg: 1376px;
  --sdds-grid-max: 1584px
}

.sdds-container,
.sdds-container-fluid {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  box-sizing: border-box
}

.sdds-container-fluid {
  max-width: 2560px
}

@media (min-width: 0) {

  .sdds-container,
  .sdds-container-fluid {
    padding-right: 16px;
    padding-left: 16px
  }

  .sdds-container {
    max-width: 100%
  }
}

@media (min-width: 0) {
  .sdds-hide-xs {
    display: none !important
  }

  .sdds-show-xs {
    display: block !important
  }
}

@media (min-width: 608px) {

  .sdds-container,
  .sdds-container-fluid {
    padding-right: 16px;
    padding-left: 16px
  }

  .sdds-container {
    max-width: 576px
  }
}

@media (min-width: 608px) {
  .sdds-hide-sm {
    display: none !important
  }

  .sdds-show-sm {
    display: block !important
  }
}

@media (min-width: 800px) {

  .sdds-container,
  .sdds-container-fluid {
    padding-right: 16px;
    padding-left: 16px
  }

  .sdds-container {
    max-width: 768px
  }
}

@media (min-width: 800px) {
  .sdds-hide-md {
    display: none !important
  }

  .sdds-show-md {
    display: block !important
  }
}

@media (min-width: 992px) {

  .sdds-container,
  .sdds-container-fluid {
    padding-right: 16px;
    padding-left: 16px
  }

  .sdds-container {
    max-width: 960px
  }
}

@media (min-width: 992px) {
  .sdds-hide-lg {
    display: none !important
  }

  .sdds-show-lg {
    display: block !important
  }
}

@media (min-width: 1184px) {

  .sdds-container,
  .sdds-container-fluid {
    padding-right: 16px;
    padding-left: 16px
  }

  .sdds-container {
    max-width: 1152px
  }
}

@media (min-width: 1184px) {
  .sdds-hide-xlg {
    display: none !important
  }

  .sdds-show-xlg {
    display: block !important
  }
}

@media (min-width: 1376px) {

  .sdds-container,
  .sdds-container-fluid {
    padding-right: 16px;
    padding-left: 16px
  }

  .sdds-container {
    max-width: 1344px
  }
}

@media (min-width: 1376px) {
  .sdds-hide-xxlg {
    display: none !important
  }

  .sdds-show-xxlg {
    display: block !important
  }
}

@media (min-width: 1584px) {

  .sdds-container,
  .sdds-container-fluid {
    padding-right: 24px;
    padding-left: 24px
  }

  .sdds-container {
    max-width: 1536px
  }
}

@media (min-width: 1584px) {
  .sdds-hide-max {
    display: none !important
  }

  .sdds-show-max {
    display: block !important
  }
}

.sdds-row {
  display: flex;
  flex-wrap: wrap
}

@media (min-width: 0) {
  .sdds-col-xs-1 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 8px;
    padding-right: 8px;
    box-sizing: border-box;
    flex-basis: 8.3333333333%;
    max-width: 8.3333333333%
  }

  .sdds-col-xs-1.sdds-no-padding {
    padding: 0
  }

  .sdds-col-xs-2 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 8px;
    padding-right: 8px;
    box-sizing: border-box;
    flex-basis: 16.6666666667%;
    max-width: 16.6666666667%
  }

  .sdds-col-xs-2.sdds-no-padding {
    padding: 0
  }

  .sdds-col-xs-3 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 8px;
    padding-right: 8px;
    box-sizing: border-box;
    flex-basis: 25%;
    max-width: 25%
  }

  .sdds-col-xs-3.sdds-no-padding {
    padding: 0
  }

  .sdds-col-xs-4 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 8px;
    padding-right: 8px;
    box-sizing: border-box;
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%
  }

  .sdds-col-xs-4.sdds-no-padding {
    padding: 0
  }

  .sdds-col-xs-5 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 8px;
    padding-right: 8px;
    box-sizing: border-box;
    flex-basis: 41.6666666667%;
    max-width: 41.6666666667%
  }

  .sdds-col-xs-5.sdds-no-padding {
    padding: 0
  }

  .sdds-col-xs-6 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 8px;
    padding-right: 8px;
    box-sizing: border-box;
    flex-basis: 50%;
    max-width: 50%
  }

  .sdds-col-xs-6.sdds-no-padding {
    padding: 0
  }

  .sdds-col-xs-7 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 8px;
    padding-right: 8px;
    box-sizing: border-box;
    flex-basis: 58.3333333333%;
    max-width: 58.3333333333%
  }

  .sdds-col-xs-7.sdds-no-padding {
    padding: 0
  }

  .sdds-col-xs-8 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 8px;
    padding-right: 8px;
    box-sizing: border-box;
    flex-basis: 66.6666666667%;
    max-width: 66.6666666667%
  }

  .sdds-col-xs-8.sdds-no-padding {
    padding: 0
  }

  .sdds-col-xs-9 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 8px;
    padding-right: 8px;
    box-sizing: border-box;
    flex-basis: 75%;
    max-width: 75%
  }

  .sdds-col-xs-9.sdds-no-padding {
    padding: 0
  }

  .sdds-col-xs-10 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 8px;
    padding-right: 8px;
    box-sizing: border-box;
    flex-basis: 83.3333333333%;
    max-width: 83.3333333333%
  }

  .sdds-col-xs-10.sdds-no-padding {
    padding: 0
  }

  .sdds-col-xs-11 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 8px;
    padding-right: 8px;
    box-sizing: border-box;
    flex-basis: 91.6666666667%;
    max-width: 91.6666666667%
  }

  .sdds-col-xs-11.sdds-no-padding {
    padding: 0
  }

  .sdds-col-xs-12 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 8px;
    padding-right: 8px;
    box-sizing: border-box;
    flex-basis: 100%;
    max-width: 100%
  }

  .sdds-col-xs-12.sdds-no-padding {
    padding: 0
  }
}

@media (min-width: 0) {

  .sdds-col,
  .sdds-col-xs {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
    min-width: 8.3333%;
    padding-left: 8px;
    padding-right: 8px
  }
}

@media (min-width: 608px) {
  .sdds-col-sm-1 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 8px;
    padding-right: 8px;
    box-sizing: border-box;
    flex-basis: 8.3333333333%;
    max-width: 8.3333333333%
  }

  .sdds-col-sm-1.sdds-no-padding {
    padding: 0
  }

  .sdds-col-sm-2 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 8px;
    padding-right: 8px;
    box-sizing: border-box;
    flex-basis: 16.6666666667%;
    max-width: 16.6666666667%
  }

  .sdds-col-sm-2.sdds-no-padding {
    padding: 0
  }

  .sdds-col-sm-3 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 8px;
    padding-right: 8px;
    box-sizing: border-box;
    flex-basis: 25%;
    max-width: 25%
  }

  .sdds-col-sm-3.sdds-no-padding {
    padding: 0
  }

  .sdds-col-sm-4 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 8px;
    padding-right: 8px;
    box-sizing: border-box;
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%
  }

  .sdds-col-sm-4.sdds-no-padding {
    padding: 0
  }

  .sdds-col-sm-5 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 8px;
    padding-right: 8px;
    box-sizing: border-box;
    flex-basis: 41.6666666667%;
    max-width: 41.6666666667%
  }

  .sdds-col-sm-5.sdds-no-padding {
    padding: 0
  }

  .sdds-col-sm-6 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 8px;
    padding-right: 8px;
    box-sizing: border-box;
    flex-basis: 50%;
    max-width: 50%
  }

  .sdds-col-sm-6.sdds-no-padding {
    padding: 0
  }

  .sdds-col-sm-7 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 8px;
    padding-right: 8px;
    box-sizing: border-box;
    flex-basis: 58.3333333333%;
    max-width: 58.3333333333%
  }

  .sdds-col-sm-7.sdds-no-padding {
    padding: 0
  }

  .sdds-col-sm-8 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 8px;
    padding-right: 8px;
    box-sizing: border-box;
    flex-basis: 66.6666666667%;
    max-width: 66.6666666667%
  }

  .sdds-col-sm-8.sdds-no-padding {
    padding: 0
  }

  .sdds-col-sm-9 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 8px;
    padding-right: 8px;
    box-sizing: border-box;
    flex-basis: 75%;
    max-width: 75%
  }

  .sdds-col-sm-9.sdds-no-padding {
    padding: 0
  }

  .sdds-col-sm-10 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 8px;
    padding-right: 8px;
    box-sizing: border-box;
    flex-basis: 83.3333333333%;
    max-width: 83.3333333333%
  }

  .sdds-col-sm-10.sdds-no-padding {
    padding: 0
  }

  .sdds-col-sm-11 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 8px;
    padding-right: 8px;
    box-sizing: border-box;
    flex-basis: 91.6666666667%;
    max-width: 91.6666666667%
  }

  .sdds-col-sm-11.sdds-no-padding {
    padding: 0
  }

  .sdds-col-sm-12 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 8px;
    padding-right: 8px;
    box-sizing: border-box;
    flex-basis: 100%;
    max-width: 100%
  }

  .sdds-col-sm-12.sdds-no-padding {
    padding: 0
  }
}

@media (min-width: 608px) {

  .sdds-col,
  .sdds-col-sm {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
    min-width: 8.3333%;
    padding-left: 8px;
    padding-right: 8px
  }
}

@media (min-width: 800px) {
  .sdds-col-md-1 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 8.3333333333%;
    max-width: 8.3333333333%
  }

  .sdds-col-md-1.sdds-no-padding {
    padding: 0
  }

  .sdds-col-md-2 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 16.6666666667%;
    max-width: 16.6666666667%
  }

  .sdds-col-md-2.sdds-no-padding {
    padding: 0
  }

  .sdds-col-md-3 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 25%;
    max-width: 25%
  }

  .sdds-col-md-3.sdds-no-padding {
    padding: 0
  }

  .sdds-col-md-4 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%
  }

  .sdds-col-md-4.sdds-no-padding {
    padding: 0
  }

  .sdds-col-md-5 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 41.6666666667%;
    max-width: 41.6666666667%
  }

  .sdds-col-md-5.sdds-no-padding {
    padding: 0
  }

  .sdds-col-md-6 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 50%;
    max-width: 50%
  }

  .sdds-col-md-6.sdds-no-padding {
    padding: 0
  }

  .sdds-col-md-7 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 58.3333333333%;
    max-width: 58.3333333333%
  }

  .sdds-col-md-7.sdds-no-padding {
    padding: 0
  }

  .sdds-col-md-8 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 66.6666666667%;
    max-width: 66.6666666667%
  }

  .sdds-col-md-8.sdds-no-padding {
    padding: 0
  }

  .sdds-col-md-9 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 75%;
    max-width: 75%
  }

  .sdds-col-md-9.sdds-no-padding {
    padding: 0
  }

  .sdds-col-md-10 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 83.3333333333%;
    max-width: 83.3333333333%
  }

  .sdds-col-md-10.sdds-no-padding {
    padding: 0
  }

  .sdds-col-md-11 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 91.6666666667%;
    max-width: 91.6666666667%
  }

  .sdds-col-md-11.sdds-no-padding {
    padding: 0
  }

  .sdds-col-md-12 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 100%;
    max-width: 100%
  }

  .sdds-col-md-12.sdds-no-padding {
    padding: 0
  }
}

@media (min-width: 800px) {

  .sdds-col,
  .sdds-col-md {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
    min-width: 8.3333%;
    padding-left: 16px;
    padding-right: 16px
  }
}

@media (min-width: 992px) {
  .sdds-col-lg-1 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 8.3333333333%;
    max-width: 8.3333333333%
  }

  .sdds-col-lg-1.sdds-no-padding {
    padding: 0
  }

  .sdds-col-lg-2 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 16.6666666667%;
    max-width: 16.6666666667%
  }

  .sdds-col-lg-2.sdds-no-padding {
    padding: 0
  }

  .sdds-col-lg-3 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 25%;
    max-width: 25%
  }

  .sdds-col-lg-3.sdds-no-padding {
    padding: 0
  }

  .sdds-col-lg-4 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%
  }

  .sdds-col-lg-4.sdds-no-padding {
    padding: 0
  }

  .sdds-col-lg-5 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 41.6666666667%;
    max-width: 41.6666666667%
  }

  .sdds-col-lg-5.sdds-no-padding {
    padding: 0
  }

  .sdds-col-lg-6 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 50%;
    max-width: 50%
  }

  .sdds-col-lg-6.sdds-no-padding {
    padding: 0
  }

  .sdds-col-lg-7 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 58.3333333333%;
    max-width: 58.3333333333%
  }

  .sdds-col-lg-7.sdds-no-padding {
    padding: 0
  }

  .sdds-col-lg-8 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 66.6666666667%;
    max-width: 66.6666666667%
  }

  .sdds-col-lg-8.sdds-no-padding {
    padding: 0
  }

  .sdds-col-lg-9 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 75%;
    max-width: 75%
  }

  .sdds-col-lg-9.sdds-no-padding {
    padding: 0
  }

  .sdds-col-lg-10 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 83.3333333333%;
    max-width: 83.3333333333%
  }

  .sdds-col-lg-10.sdds-no-padding {
    padding: 0
  }

  .sdds-col-lg-11 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 91.6666666667%;
    max-width: 91.6666666667%
  }

  .sdds-col-lg-11.sdds-no-padding {
    padding: 0
  }

  .sdds-col-lg-12 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 100%;
    max-width: 100%
  }

  .sdds-col-lg-12.sdds-no-padding {
    padding: 0
  }
}

@media (min-width: 992px) {

  .sdds-col,
  .sdds-col-lg {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
    min-width: 8.3333%;
    padding-left: 16px;
    padding-right: 16px
  }
}

@media (min-width: 1184px) {
  .sdds-col-xlg-1 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 8.3333333333%;
    max-width: 8.3333333333%
  }

  .sdds-col-xlg-1.sdds-no-padding {
    padding: 0
  }

  .sdds-col-xlg-2 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 16.6666666667%;
    max-width: 16.6666666667%
  }

  .sdds-col-xlg-2.sdds-no-padding {
    padding: 0
  }

  .sdds-col-xlg-3 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 25%;
    max-width: 25%
  }

  .sdds-col-xlg-3.sdds-no-padding {
    padding: 0
  }

  .sdds-col-xlg-4 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%
  }

  .sdds-col-xlg-4.sdds-no-padding {
    padding: 0
  }

  .sdds-col-xlg-5 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 41.6666666667%;
    max-width: 41.6666666667%
  }

  .sdds-col-xlg-5.sdds-no-padding {
    padding: 0
  }

  .sdds-col-xlg-6 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 50%;
    max-width: 50%
  }

  .sdds-col-xlg-6.sdds-no-padding {
    padding: 0
  }

  .sdds-col-xlg-7 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 58.3333333333%;
    max-width: 58.3333333333%
  }

  .sdds-col-xlg-7.sdds-no-padding {
    padding: 0
  }

  .sdds-col-xlg-8 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 66.6666666667%;
    max-width: 66.6666666667%
  }

  .sdds-col-xlg-8.sdds-no-padding {
    padding: 0
  }

  .sdds-col-xlg-9 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 75%;
    max-width: 75%
  }

  .sdds-col-xlg-9.sdds-no-padding {
    padding: 0
  }

  .sdds-col-xlg-10 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 83.3333333333%;
    max-width: 83.3333333333%
  }

  .sdds-col-xlg-10.sdds-no-padding {
    padding: 0
  }

  .sdds-col-xlg-11 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 91.6666666667%;
    max-width: 91.6666666667%
  }

  .sdds-col-xlg-11.sdds-no-padding {
    padding: 0
  }

  .sdds-col-xlg-12 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 100%;
    max-width: 100%
  }

  .sdds-col-xlg-12.sdds-no-padding {
    padding: 0
  }
}

@media (min-width: 1184px) {

  .sdds-col,
  .sdds-col-xlg {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
    min-width: 8.3333%;
    padding-left: 16px;
    padding-right: 16px
  }
}

@media (min-width: 1376px) {
  .sdds-col-xxlg-1 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 8.3333333333%;
    max-width: 8.3333333333%
  }

  .sdds-col-xxlg-1.sdds-no-padding {
    padding: 0
  }

  .sdds-col-xxlg-2 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 16.6666666667%;
    max-width: 16.6666666667%
  }

  .sdds-col-xxlg-2.sdds-no-padding {
    padding: 0
  }

  .sdds-col-xxlg-3 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 25%;
    max-width: 25%
  }

  .sdds-col-xxlg-3.sdds-no-padding {
    padding: 0
  }

  .sdds-col-xxlg-4 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%
  }

  .sdds-col-xxlg-4.sdds-no-padding {
    padding: 0
  }

  .sdds-col-xxlg-5 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 41.6666666667%;
    max-width: 41.6666666667%
  }

  .sdds-col-xxlg-5.sdds-no-padding {
    padding: 0
  }

  .sdds-col-xxlg-6 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 50%;
    max-width: 50%
  }

  .sdds-col-xxlg-6.sdds-no-padding {
    padding: 0
  }

  .sdds-col-xxlg-7 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 58.3333333333%;
    max-width: 58.3333333333%
  }

  .sdds-col-xxlg-7.sdds-no-padding {
    padding: 0
  }

  .sdds-col-xxlg-8 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 66.6666666667%;
    max-width: 66.6666666667%
  }

  .sdds-col-xxlg-8.sdds-no-padding {
    padding: 0
  }

  .sdds-col-xxlg-9 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 75%;
    max-width: 75%
  }

  .sdds-col-xxlg-9.sdds-no-padding {
    padding: 0
  }

  .sdds-col-xxlg-10 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 83.3333333333%;
    max-width: 83.3333333333%
  }

  .sdds-col-xxlg-10.sdds-no-padding {
    padding: 0
  }

  .sdds-col-xxlg-11 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 91.6666666667%;
    max-width: 91.6666666667%
  }

  .sdds-col-xxlg-11.sdds-no-padding {
    padding: 0
  }

  .sdds-col-xxlg-12 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 100%;
    max-width: 100%
  }

  .sdds-col-xxlg-12.sdds-no-padding {
    padding: 0
  }
}

@media (min-width: 1376px) {

  .sdds-col,
  .sdds-col-xxlg {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
    min-width: 8.3333%;
    padding-left: 16px;
    padding-right: 16px
  }
}

@media (min-width: 1584px) {
  .sdds-col-max-1 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 8.3333333333%;
    max-width: 8.3333333333%
  }

  .sdds-col-max-1.sdds-no-padding {
    padding: 0
  }

  .sdds-col-max-2 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 16.6666666667%;
    max-width: 16.6666666667%
  }

  .sdds-col-max-2.sdds-no-padding {
    padding: 0
  }

  .sdds-col-max-3 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 25%;
    max-width: 25%
  }

  .sdds-col-max-3.sdds-no-padding {
    padding: 0
  }

  .sdds-col-max-4 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%
  }

  .sdds-col-max-4.sdds-no-padding {
    padding: 0
  }

  .sdds-col-max-5 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 41.6666666667%;
    max-width: 41.6666666667%
  }

  .sdds-col-max-5.sdds-no-padding {
    padding: 0
  }

  .sdds-col-max-6 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 50%;
    max-width: 50%
  }

  .sdds-col-max-6.sdds-no-padding {
    padding: 0
  }

  .sdds-col-max-7 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 58.3333333333%;
    max-width: 58.3333333333%
  }

  .sdds-col-max-7.sdds-no-padding {
    padding: 0
  }

  .sdds-col-max-8 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 66.6666666667%;
    max-width: 66.6666666667%
  }

  .sdds-col-max-8.sdds-no-padding {
    padding: 0
  }

  .sdds-col-max-9 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 75%;
    max-width: 75%
  }

  .sdds-col-max-9.sdds-no-padding {
    padding: 0
  }

  .sdds-col-max-10 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 83.3333333333%;
    max-width: 83.3333333333%
  }

  .sdds-col-max-10.sdds-no-padding {
    padding: 0
  }

  .sdds-col-max-11 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 91.6666666667%;
    max-width: 91.6666666667%
  }

  .sdds-col-max-11.sdds-no-padding {
    padding: 0
  }

  .sdds-col-max-12 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 100%;
    max-width: 100%
  }

  .sdds-col-max-12.sdds-no-padding {
    padding: 0
  }
}

@media (min-width: 1584px) {

  .sdds-col,
  .sdds-col-max {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
    min-width: 8.3333%;
    padding-left: 16px;
    padding-right: 16px
  }
}

.sdds-no-padding [class|=sdds-col] {
  padding: 0 !important
}

@media (min-width: 0) {
  .sdds-col-xs-1-offset {
    margin-left: 8.3333333333%
  }

  .sdds-col-xs-2-offset {
    margin-left: 16.6666666667%
  }

  .sdds-col-xs-3-offset {
    margin-left: 25%
  }

  .sdds-col-xs-4-offset {
    margin-left: 33.3333333333%
  }

  .sdds-col-xs-5-offset {
    margin-left: 41.6666666667%
  }

  .sdds-col-xs-6-offset {
    margin-left: 50%
  }

  .sdds-col-xs-7-offset {
    margin-left: 58.3333333333%
  }

  .sdds-col-xs-8-offset {
    margin-left: 66.6666666667%
  }

  .sdds-col-xs-9-offset {
    margin-left: 75%
  }

  .sdds-col-xs-10-offset {
    margin-left: 83.3333333333%
  }

  .sdds-col-xs-11-offset {
    margin-left: 91.6666666667%
  }

  .sdds-col-xs-12-offset {
    margin-left: 100%
  }
}

@media (min-width: 608px) {
  .sdds-col-sm-1-offset {
    margin-left: 8.3333333333%
  }

  .sdds-col-sm-2-offset {
    margin-left: 16.6666666667%
  }

  .sdds-col-sm-3-offset {
    margin-left: 25%
  }

  .sdds-col-sm-4-offset {
    margin-left: 33.3333333333%
  }

  .sdds-col-sm-5-offset {
    margin-left: 41.6666666667%
  }

  .sdds-col-sm-6-offset {
    margin-left: 50%
  }

  .sdds-col-sm-7-offset {
    margin-left: 58.3333333333%
  }

  .sdds-col-sm-8-offset {
    margin-left: 66.6666666667%
  }

  .sdds-col-sm-9-offset {
    margin-left: 75%
  }

  .sdds-col-sm-10-offset {
    margin-left: 83.3333333333%
  }

  .sdds-col-sm-11-offset {
    margin-left: 91.6666666667%
  }

  .sdds-col-sm-12-offset {
    margin-left: 100%
  }
}

@media (min-width: 800px) {
  .sdds-col-md-1-offset {
    margin-left: 8.3333333333%
  }

  .sdds-col-md-2-offset {
    margin-left: 16.6666666667%
  }

  .sdds-col-md-3-offset {
    margin-left: 25%
  }

  .sdds-col-md-4-offset {
    margin-left: 33.3333333333%
  }

  .sdds-col-md-5-offset {
    margin-left: 41.6666666667%
  }

  .sdds-col-md-6-offset {
    margin-left: 50%
  }

  .sdds-col-md-7-offset {
    margin-left: 58.3333333333%
  }

  .sdds-col-md-8-offset {
    margin-left: 66.6666666667%
  }

  .sdds-col-md-9-offset {
    margin-left: 75%
  }

  .sdds-col-md-10-offset {
    margin-left: 83.3333333333%
  }

  .sdds-col-md-11-offset {
    margin-left: 91.6666666667%
  }

  .sdds-col-md-12-offset {
    margin-left: 100%
  }
}

@media (min-width: 992px) {
  .sdds-col-lg-1-offset {
    margin-left: 8.3333333333%
  }

  .sdds-col-lg-2-offset {
    margin-left: 16.6666666667%
  }

  .sdds-col-lg-3-offset {
    margin-left: 25%
  }

  .sdds-col-lg-4-offset {
    margin-left: 33.3333333333%
  }

  .sdds-col-lg-5-offset {
    margin-left: 41.6666666667%
  }

  .sdds-col-lg-6-offset {
    margin-left: 50%
  }

  .sdds-col-lg-7-offset {
    margin-left: 58.3333333333%
  }

  .sdds-col-lg-8-offset {
    margin-left: 66.6666666667%
  }

  .sdds-col-lg-9-offset {
    margin-left: 75%
  }

  .sdds-col-lg-10-offset {
    margin-left: 83.3333333333%
  }

  .sdds-col-lg-11-offset {
    margin-left: 91.6666666667%
  }

  .sdds-col-lg-12-offset {
    margin-left: 100%
  }
}

@media (min-width: 1184px) {
  .sdds-col-xlg-1-offset {
    margin-left: 8.3333333333%
  }

  .sdds-col-xlg-2-offset {
    margin-left: 16.6666666667%
  }

  .sdds-col-xlg-3-offset {
    margin-left: 25%
  }

  .sdds-col-xlg-4-offset {
    margin-left: 33.3333333333%
  }

  .sdds-col-xlg-5-offset {
    margin-left: 41.6666666667%
  }

  .sdds-col-xlg-6-offset {
    margin-left: 50%
  }

  .sdds-col-xlg-7-offset {
    margin-left: 58.3333333333%
  }

  .sdds-col-xlg-8-offset {
    margin-left: 66.6666666667%
  }

  .sdds-col-xlg-9-offset {
    margin-left: 75%
  }

  .sdds-col-xlg-10-offset {
    margin-left: 83.3333333333%
  }

  .sdds-col-xlg-11-offset {
    margin-left: 91.6666666667%
  }

  .sdds-col-xlg-12-offset {
    margin-left: 100%
  }
}

@media (min-width: 1376px) {
  .sdds-col-xxlg-1-offset {
    margin-left: 8.3333333333%
  }

  .sdds-col-xxlg-2-offset {
    margin-left: 16.6666666667%
  }

  .sdds-col-xxlg-3-offset {
    margin-left: 25%
  }

  .sdds-col-xxlg-4-offset {
    margin-left: 33.3333333333%
  }

  .sdds-col-xxlg-5-offset {
    margin-left: 41.6666666667%
  }

  .sdds-col-xxlg-6-offset {
    margin-left: 50%
  }

  .sdds-col-xxlg-7-offset {
    margin-left: 58.3333333333%
  }

  .sdds-col-xxlg-8-offset {
    margin-left: 66.6666666667%
  }

  .sdds-col-xxlg-9-offset {
    margin-left: 75%
  }

  .sdds-col-xxlg-10-offset {
    margin-left: 83.3333333333%
  }

  .sdds-col-xxlg-11-offset {
    margin-left: 91.6666666667%
  }

  .sdds-col-xxlg-12-offset {
    margin-left: 100%
  }
}

@media (min-width: 1584px) {
  .sdds-col-max-1-offset {
    margin-left: 8.3333333333%
  }

  .sdds-col-max-2-offset {
    margin-left: 16.6666666667%
  }

  .sdds-col-max-3-offset {
    margin-left: 25%
  }

  .sdds-col-max-4-offset {
    margin-left: 33.3333333333%
  }

  .sdds-col-max-5-offset {
    margin-left: 41.6666666667%
  }

  .sdds-col-max-6-offset {
    margin-left: 50%
  }

  .sdds-col-max-7-offset {
    margin-left: 58.3333333333%
  }

  .sdds-col-max-8-offset {
    margin-left: 66.6666666667%
  }

  .sdds-col-max-9-offset {
    margin-left: 75%
  }

  .sdds-col-max-10-offset {
    margin-left: 83.3333333333%
  }

  .sdds-col-max-11-offset {
    margin-left: 91.6666666667%
  }

  .sdds-col-max-12-offset {
    margin-left: 100%
  }
}

@media (min-width: 0) {
  .sdds-sidebar {
    flex-basis: 288px;
    min-width: 288px;
    display: none
  }

  .sdds-sidebar.sdds-sidebar-collapse {
    flex-basis: 17rem;
    min-width: 17rem
  }

  .sdds-container-push {
    padding-right: 16px;
    padding-left: 16px;
    max-width: 100%
  }

  .sdds-container-push-fluid {
    padding-right: 16px;
    padding-left: 16px
  }
}

@media (min-width: 608px) {
  .sdds-sidebar {
    flex-basis: 288px;
    min-width: 288px;
    display: none
  }

  .sdds-sidebar.sdds-sidebar-collapse {
    flex-basis: 17rem;
    min-width: 17rem
  }

  .sdds-container-push {
    padding-right: 16px;
    padding-left: 16px;
    max-width: 576px
  }

  .sdds-container-push-fluid {
    padding-right: 16px;
    padding-left: 16px
  }
}

@media (min-width: 800px) {
  .sdds-sidebar {
    flex-basis: 288px;
    min-width: 288px;
    display: none
  }

  .sdds-sidebar.sdds-sidebar-collapse {
    flex-basis: 17rem;
    min-width: 17rem
  }

  .sdds-container-push {
    padding-right: 16px;
    padding-left: 16px;
    max-width: 768px
  }

  .sdds-container-push-fluid {
    padding-right: 16px;
    padding-left: 16px
  }
}

@media (min-width: 992px) {
  .sdds-sidebar {
    flex-basis: 288px;
    min-width: 288px;
    display: block
  }

  .sdds-sidebar.sdds-sidebar-collapse {
    flex-basis: 17rem;
    min-width: 17rem
  }

  .sdds-container-push {
    padding-right: 16px;
    padding-left: 16px;
    max-width: 673px
  }

  .sdds-container-push-fluid {
    padding-right: 16px;
    padding-left: 16px
  }
}

@media (min-width: 1184px) {
  .sdds-sidebar {
    flex-basis: 288px;
    min-width: 288px;
    display: block
  }

  .sdds-sidebar.sdds-sidebar-collapse {
    flex-basis: 17rem;
    min-width: 17rem
  }

  .sdds-container-push {
    padding-right: 16px;
    padding-left: 16px;
    max-width: 864px
  }

  .sdds-container-push-fluid {
    padding-right: 16px;
    padding-left: 16px
  }
}

@media (min-width: 1376px) {
  .sdds-sidebar {
    flex-basis: 288px;
    min-width: 288px;
    display: block
  }

  .sdds-sidebar.sdds-sidebar-collapse {
    flex-basis: 17rem;
    min-width: 17rem
  }

  .sdds-container-push {
    padding-right: 16px;
    padding-left: 16px;
    max-width: 1056px
  }

  .sdds-container-push-fluid {
    padding-right: 16px;
    padding-left: 16px
  }
}

@media (min-width: 1584px) {
  .sdds-sidebar {
    flex-basis: 288px;
    min-width: 288px;
    display: block
  }

  .sdds-sidebar.sdds-sidebar-collapse {
    flex-basis: 17rem;
    min-width: 17rem
  }

  .sdds-container-push {
    padding-right: 24px;
    padding-left: 24px;
    max-width: 1248px
  }

  .sdds-container-push-fluid {
    padding-right: 24px;
    padding-left: 24px
  }
}

.sdds-push {
  display: flex
}

@media (min-width: 0) {
  .sdds-push .sdds-col-xs-1 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 8px;
    padding-right: 8px;
    box-sizing: border-box;
    flex-basis: 8.3333333333%;
    max-width: 8.3333333333%
  }

  .sdds-push .sdds-col-xs-1.sdds-no-padding {
    padding: 0
  }

  .sdds-push .sdds-col-xs-2 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 8px;
    padding-right: 8px;
    box-sizing: border-box;
    flex-basis: 16.6666666667%;
    max-width: 16.6666666667%
  }

  .sdds-push .sdds-col-xs-2.sdds-no-padding {
    padding: 0
  }

  .sdds-push .sdds-col-xs-3 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 8px;
    padding-right: 8px;
    box-sizing: border-box;
    flex-basis: 25%;
    max-width: 25%
  }

  .sdds-push .sdds-col-xs-3.sdds-no-padding {
    padding: 0
  }

  .sdds-push .sdds-col-xs-4 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 8px;
    padding-right: 8px;
    box-sizing: border-box;
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%
  }

  .sdds-push .sdds-col-xs-4.sdds-no-padding {
    padding: 0
  }

  .sdds-push .sdds-col-xs-5 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 8px;
    padding-right: 8px;
    box-sizing: border-box;
    flex-basis: 41.6666666667%;
    max-width: 41.6666666667%
  }

  .sdds-push .sdds-col-xs-5.sdds-no-padding {
    padding: 0
  }

  .sdds-push .sdds-col-xs-6 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 8px;
    padding-right: 8px;
    box-sizing: border-box;
    flex-basis: 50%;
    max-width: 50%
  }

  .sdds-push .sdds-col-xs-6.sdds-no-padding {
    padding: 0
  }

  .sdds-push .sdds-col-xs-7 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 8px;
    padding-right: 8px;
    box-sizing: border-box;
    flex-basis: 58.3333333333%;
    max-width: 58.3333333333%
  }

  .sdds-push .sdds-col-xs-7.sdds-no-padding {
    padding: 0
  }

  .sdds-push .sdds-col-xs-8 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 8px;
    padding-right: 8px;
    box-sizing: border-box;
    flex-basis: 66.6666666667%;
    max-width: 66.6666666667%
  }

  .sdds-push .sdds-col-xs-8.sdds-no-padding {
    padding: 0
  }

  .sdds-push .sdds-col-xs-9 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 8px;
    padding-right: 8px;
    box-sizing: border-box;
    flex-basis: 75%;
    max-width: 75%
  }

  .sdds-push .sdds-col-xs-9.sdds-no-padding {
    padding: 0
  }

  .sdds-push .sdds-col-xs-10 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 8px;
    padding-right: 8px;
    box-sizing: border-box;
    flex-basis: 83.3333333333%;
    max-width: 83.3333333333%
  }

  .sdds-push .sdds-col-xs-10.sdds-no-padding {
    padding: 0
  }

  .sdds-push .sdds-col-xs-11 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 8px;
    padding-right: 8px;
    box-sizing: border-box;
    flex-basis: 91.6666666667%;
    max-width: 91.6666666667%
  }

  .sdds-push .sdds-col-xs-11.sdds-no-padding {
    padding: 0
  }

  .sdds-push .sdds-col-xs-12 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 8px;
    padding-right: 8px;
    box-sizing: border-box;
    flex-basis: 100%;
    max-width: 100%
  }

  .sdds-push .sdds-col-xs-12.sdds-no-padding {
    padding: 0
  }
}

@media (min-width: 0) {

  .sdds-push .sdds-col,
  .sdds-push .sdds-col-xs {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
    min-width: 8.3333%;
    padding-left: 8px;
    padding-right: 8px
  }
}

@media (min-width: 608px) {
  .sdds-push .sdds-col-sm-1 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 8px;
    padding-right: 8px;
    box-sizing: border-box;
    flex-basis: 8.3333333333%;
    max-width: 8.3333333333%
  }

  .sdds-push .sdds-col-sm-1.sdds-no-padding {
    padding: 0
  }

  .sdds-push .sdds-col-sm-2 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 8px;
    padding-right: 8px;
    box-sizing: border-box;
    flex-basis: 16.6666666667%;
    max-width: 16.6666666667%
  }

  .sdds-push .sdds-col-sm-2.sdds-no-padding {
    padding: 0
  }

  .sdds-push .sdds-col-sm-3 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 8px;
    padding-right: 8px;
    box-sizing: border-box;
    flex-basis: 25%;
    max-width: 25%
  }

  .sdds-push .sdds-col-sm-3.sdds-no-padding {
    padding: 0
  }

  .sdds-push .sdds-col-sm-4 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 8px;
    padding-right: 8px;
    box-sizing: border-box;
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%
  }

  .sdds-push .sdds-col-sm-4.sdds-no-padding {
    padding: 0
  }

  .sdds-push .sdds-col-sm-5 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 8px;
    padding-right: 8px;
    box-sizing: border-box;
    flex-basis: 41.6666666667%;
    max-width: 41.6666666667%
  }

  .sdds-push .sdds-col-sm-5.sdds-no-padding {
    padding: 0
  }

  .sdds-push .sdds-col-sm-6 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 8px;
    padding-right: 8px;
    box-sizing: border-box;
    flex-basis: 50%;
    max-width: 50%
  }

  .sdds-push .sdds-col-sm-6.sdds-no-padding {
    padding: 0
  }

  .sdds-push .sdds-col-sm-7 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 8px;
    padding-right: 8px;
    box-sizing: border-box;
    flex-basis: 58.3333333333%;
    max-width: 58.3333333333%
  }

  .sdds-push .sdds-col-sm-7.sdds-no-padding {
    padding: 0
  }

  .sdds-push .sdds-col-sm-8 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 8px;
    padding-right: 8px;
    box-sizing: border-box;
    flex-basis: 66.6666666667%;
    max-width: 66.6666666667%
  }

  .sdds-push .sdds-col-sm-8.sdds-no-padding {
    padding: 0
  }

  .sdds-push .sdds-col-sm-9 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 8px;
    padding-right: 8px;
    box-sizing: border-box;
    flex-basis: 75%;
    max-width: 75%
  }

  .sdds-push .sdds-col-sm-9.sdds-no-padding {
    padding: 0
  }

  .sdds-push .sdds-col-sm-10 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 8px;
    padding-right: 8px;
    box-sizing: border-box;
    flex-basis: 83.3333333333%;
    max-width: 83.3333333333%
  }

  .sdds-push .sdds-col-sm-10.sdds-no-padding {
    padding: 0
  }

  .sdds-push .sdds-col-sm-11 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 8px;
    padding-right: 8px;
    box-sizing: border-box;
    flex-basis: 91.6666666667%;
    max-width: 91.6666666667%
  }

  .sdds-push .sdds-col-sm-11.sdds-no-padding {
    padding: 0
  }

  .sdds-push .sdds-col-sm-12 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 8px;
    padding-right: 8px;
    box-sizing: border-box;
    flex-basis: 100%;
    max-width: 100%
  }

  .sdds-push .sdds-col-sm-12.sdds-no-padding {
    padding: 0
  }
}

@media (min-width: 608px) {

  .sdds-push .sdds-col,
  .sdds-push .sdds-col-sm {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
    min-width: 8.3333%;
    padding-left: 8px;
    padding-right: 8px
  }
}

@media (min-width: 800px) {
  .sdds-push .sdds-col-md-1 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 8.3333333333%;
    max-width: 8.3333333333%
  }

  .sdds-push .sdds-col-md-1.sdds-no-padding {
    padding: 0
  }

  .sdds-push .sdds-col-md-2 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 16.6666666667%;
    max-width: 16.6666666667%
  }

  .sdds-push .sdds-col-md-2.sdds-no-padding {
    padding: 0
  }

  .sdds-push .sdds-col-md-3 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 25%;
    max-width: 25%
  }

  .sdds-push .sdds-col-md-3.sdds-no-padding {
    padding: 0
  }

  .sdds-push .sdds-col-md-4 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%
  }

  .sdds-push .sdds-col-md-4.sdds-no-padding {
    padding: 0
  }

  .sdds-push .sdds-col-md-5 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 41.6666666667%;
    max-width: 41.6666666667%
  }

  .sdds-push .sdds-col-md-5.sdds-no-padding {
    padding: 0
  }

  .sdds-push .sdds-col-md-6 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 50%;
    max-width: 50%
  }

  .sdds-push .sdds-col-md-6.sdds-no-padding {
    padding: 0
  }

  .sdds-push .sdds-col-md-7 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 58.3333333333%;
    max-width: 58.3333333333%
  }

  .sdds-push .sdds-col-md-7.sdds-no-padding {
    padding: 0
  }

  .sdds-push .sdds-col-md-8 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 66.6666666667%;
    max-width: 66.6666666667%
  }

  .sdds-push .sdds-col-md-8.sdds-no-padding {
    padding: 0
  }

  .sdds-push .sdds-col-md-9 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 75%;
    max-width: 75%
  }

  .sdds-push .sdds-col-md-9.sdds-no-padding {
    padding: 0
  }

  .sdds-push .sdds-col-md-10 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 83.3333333333%;
    max-width: 83.3333333333%
  }

  .sdds-push .sdds-col-md-10.sdds-no-padding {
    padding: 0
  }

  .sdds-push .sdds-col-md-11 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 91.6666666667%;
    max-width: 91.6666666667%
  }

  .sdds-push .sdds-col-md-11.sdds-no-padding {
    padding: 0
  }

  .sdds-push .sdds-col-md-12 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 100%;
    max-width: 100%
  }

  .sdds-push .sdds-col-md-12.sdds-no-padding {
    padding: 0
  }
}

@media (min-width: 800px) {

  .sdds-push .sdds-col,
  .sdds-push .sdds-col-md {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
    min-width: 8.3333%;
    padding-left: 16px;
    padding-right: 16px
  }
}

@media (min-width: 992px) {
  .sdds-push .sdds-col-lg-1 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 8.3333333333%;
    max-width: 8.3333333333%
  }

  .sdds-push .sdds-col-lg-1.sdds-no-padding {
    padding: 0
  }

  .sdds-push .sdds-col-lg-2 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 16.6666666667%;
    max-width: 16.6666666667%
  }

  .sdds-push .sdds-col-lg-2.sdds-no-padding {
    padding: 0
  }

  .sdds-push .sdds-col-lg-3 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 25%;
    max-width: 25%
  }

  .sdds-push .sdds-col-lg-3.sdds-no-padding {
    padding: 0
  }

  .sdds-push .sdds-col-lg-4 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%
  }

  .sdds-push .sdds-col-lg-4.sdds-no-padding {
    padding: 0
  }

  .sdds-push .sdds-col-lg-5 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 41.6666666667%;
    max-width: 41.6666666667%
  }

  .sdds-push .sdds-col-lg-5.sdds-no-padding {
    padding: 0
  }

  .sdds-push .sdds-col-lg-6 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 50%;
    max-width: 50%
  }

  .sdds-push .sdds-col-lg-6.sdds-no-padding {
    padding: 0
  }

  .sdds-push .sdds-col-lg-7 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 58.3333333333%;
    max-width: 58.3333333333%
  }

  .sdds-push .sdds-col-lg-7.sdds-no-padding {
    padding: 0
  }

  .sdds-push .sdds-col-lg-8 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 66.6666666667%;
    max-width: 66.6666666667%
  }

  .sdds-push .sdds-col-lg-8.sdds-no-padding {
    padding: 0
  }

  .sdds-push .sdds-col-lg-9 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 75%;
    max-width: 75%
  }

  .sdds-push .sdds-col-lg-9.sdds-no-padding {
    padding: 0
  }

  .sdds-push .sdds-col-lg-10 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 83.3333333333%;
    max-width: 83.3333333333%
  }

  .sdds-push .sdds-col-lg-10.sdds-no-padding {
    padding: 0
  }

  .sdds-push .sdds-col-lg-11 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 91.6666666667%;
    max-width: 91.6666666667%
  }

  .sdds-push .sdds-col-lg-11.sdds-no-padding {
    padding: 0
  }

  .sdds-push .sdds-col-lg-12 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 100%;
    max-width: 100%
  }

  .sdds-push .sdds-col-lg-12.sdds-no-padding {
    padding: 0
  }
}

@media (min-width: 992px) {

  .sdds-push .sdds-col,
  .sdds-push .sdds-col-lg {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
    min-width: 8.3333%;
    padding-left: 16px;
    padding-right: 16px
  }
}

@media (min-width: 1184px) {
  .sdds-push .sdds-col-xlg-1 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 8.3333333333%;
    max-width: 8.3333333333%
  }

  .sdds-push .sdds-col-xlg-1.sdds-no-padding {
    padding: 0
  }

  .sdds-push .sdds-col-xlg-2 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 16.6666666667%;
    max-width: 16.6666666667%
  }

  .sdds-push .sdds-col-xlg-2.sdds-no-padding {
    padding: 0
  }

  .sdds-push .sdds-col-xlg-3 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 25%;
    max-width: 25%
  }

  .sdds-push .sdds-col-xlg-3.sdds-no-padding {
    padding: 0
  }

  .sdds-push .sdds-col-xlg-4 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%
  }

  .sdds-push .sdds-col-xlg-4.sdds-no-padding {
    padding: 0
  }

  .sdds-push .sdds-col-xlg-5 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 41.6666666667%;
    max-width: 41.6666666667%
  }

  .sdds-push .sdds-col-xlg-5.sdds-no-padding {
    padding: 0
  }

  .sdds-push .sdds-col-xlg-6 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 50%;
    max-width: 50%
  }

  .sdds-push .sdds-col-xlg-6.sdds-no-padding {
    padding: 0
  }

  .sdds-push .sdds-col-xlg-7 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 58.3333333333%;
    max-width: 58.3333333333%
  }

  .sdds-push .sdds-col-xlg-7.sdds-no-padding {
    padding: 0
  }

  .sdds-push .sdds-col-xlg-8 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 66.6666666667%;
    max-width: 66.6666666667%
  }

  .sdds-push .sdds-col-xlg-8.sdds-no-padding {
    padding: 0
  }

  .sdds-push .sdds-col-xlg-9 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 75%;
    max-width: 75%
  }

  .sdds-push .sdds-col-xlg-9.sdds-no-padding {
    padding: 0
  }

  .sdds-push .sdds-col-xlg-10 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 83.3333333333%;
    max-width: 83.3333333333%
  }

  .sdds-push .sdds-col-xlg-10.sdds-no-padding {
    padding: 0
  }

  .sdds-push .sdds-col-xlg-11 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 91.6666666667%;
    max-width: 91.6666666667%
  }

  .sdds-push .sdds-col-xlg-11.sdds-no-padding {
    padding: 0
  }

  .sdds-push .sdds-col-xlg-12 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 100%;
    max-width: 100%
  }

  .sdds-push .sdds-col-xlg-12.sdds-no-padding {
    padding: 0
  }
}

@media (min-width: 1184px) {

  .sdds-push .sdds-col,
  .sdds-push .sdds-col-xlg {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
    min-width: 8.3333%;
    padding-left: 16px;
    padding-right: 16px
  }
}

@media (min-width: 1376px) {
  .sdds-push .sdds-col-xxlg-1 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 8.3333333333%;
    max-width: 8.3333333333%
  }

  .sdds-push .sdds-col-xxlg-1.sdds-no-padding {
    padding: 0
  }

  .sdds-push .sdds-col-xxlg-2 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 16.6666666667%;
    max-width: 16.6666666667%
  }

  .sdds-push .sdds-col-xxlg-2.sdds-no-padding {
    padding: 0
  }

  .sdds-push .sdds-col-xxlg-3 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 25%;
    max-width: 25%
  }

  .sdds-push .sdds-col-xxlg-3.sdds-no-padding {
    padding: 0
  }

  .sdds-push .sdds-col-xxlg-4 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%
  }

  .sdds-push .sdds-col-xxlg-4.sdds-no-padding {
    padding: 0
  }

  .sdds-push .sdds-col-xxlg-5 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 41.6666666667%;
    max-width: 41.6666666667%
  }

  .sdds-push .sdds-col-xxlg-5.sdds-no-padding {
    padding: 0
  }

  .sdds-push .sdds-col-xxlg-6 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 50%;
    max-width: 50%
  }

  .sdds-push .sdds-col-xxlg-6.sdds-no-padding {
    padding: 0
  }

  .sdds-push .sdds-col-xxlg-7 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 58.3333333333%;
    max-width: 58.3333333333%
  }

  .sdds-push .sdds-col-xxlg-7.sdds-no-padding {
    padding: 0
  }

  .sdds-push .sdds-col-xxlg-8 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 66.6666666667%;
    max-width: 66.6666666667%
  }

  .sdds-push .sdds-col-xxlg-8.sdds-no-padding {
    padding: 0
  }

  .sdds-push .sdds-col-xxlg-9 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 75%;
    max-width: 75%
  }

  .sdds-push .sdds-col-xxlg-9.sdds-no-padding {
    padding: 0
  }

  .sdds-push .sdds-col-xxlg-10 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 83.3333333333%;
    max-width: 83.3333333333%
  }

  .sdds-push .sdds-col-xxlg-10.sdds-no-padding {
    padding: 0
  }

  .sdds-push .sdds-col-xxlg-11 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 91.6666666667%;
    max-width: 91.6666666667%
  }

  .sdds-push .sdds-col-xxlg-11.sdds-no-padding {
    padding: 0
  }

  .sdds-push .sdds-col-xxlg-12 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 100%;
    max-width: 100%
  }

  .sdds-push .sdds-col-xxlg-12.sdds-no-padding {
    padding: 0
  }
}

@media (min-width: 1376px) {

  .sdds-push .sdds-col,
  .sdds-push .sdds-col-xxlg {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
    min-width: 8.3333%;
    padding-left: 16px;
    padding-right: 16px
  }
}

@media (min-width: 1584px) {
  .sdds-push .sdds-col-max-1 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 8.3333333333%;
    max-width: 8.3333333333%
  }

  .sdds-push .sdds-col-max-1.sdds-no-padding {
    padding: 0
  }

  .sdds-push .sdds-col-max-2 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 16.6666666667%;
    max-width: 16.6666666667%
  }

  .sdds-push .sdds-col-max-2.sdds-no-padding {
    padding: 0
  }

  .sdds-push .sdds-col-max-3 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 25%;
    max-width: 25%
  }

  .sdds-push .sdds-col-max-3.sdds-no-padding {
    padding: 0
  }

  .sdds-push .sdds-col-max-4 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%
  }

  .sdds-push .sdds-col-max-4.sdds-no-padding {
    padding: 0
  }

  .sdds-push .sdds-col-max-5 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 41.6666666667%;
    max-width: 41.6666666667%
  }

  .sdds-push .sdds-col-max-5.sdds-no-padding {
    padding: 0
  }

  .sdds-push .sdds-col-max-6 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 50%;
    max-width: 50%
  }

  .sdds-push .sdds-col-max-6.sdds-no-padding {
    padding: 0
  }

  .sdds-push .sdds-col-max-7 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 58.3333333333%;
    max-width: 58.3333333333%
  }

  .sdds-push .sdds-col-max-7.sdds-no-padding {
    padding: 0
  }

  .sdds-push .sdds-col-max-8 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 66.6666666667%;
    max-width: 66.6666666667%
  }

  .sdds-push .sdds-col-max-8.sdds-no-padding {
    padding: 0
  }

  .sdds-push .sdds-col-max-9 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 75%;
    max-width: 75%
  }

  .sdds-push .sdds-col-max-9.sdds-no-padding {
    padding: 0
  }

  .sdds-push .sdds-col-max-10 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 83.3333333333%;
    max-width: 83.3333333333%
  }

  .sdds-push .sdds-col-max-10.sdds-no-padding {
    padding: 0
  }

  .sdds-push .sdds-col-max-11 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 91.6666666667%;
    max-width: 91.6666666667%
  }

  .sdds-push .sdds-col-max-11.sdds-no-padding {
    padding: 0
  }

  .sdds-push .sdds-col-max-12 {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    flex-basis: 100%;
    max-width: 100%
  }

  .sdds-push .sdds-col-max-12.sdds-no-padding {
    padding: 0
  }
}

@media (min-width: 1584px) {

  .sdds-push .sdds-col,
  .sdds-push .sdds-col-max {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
    min-width: 8.3333%;
    padding-left: 16px;
    padding-right: 16px
  }
}

.sdds-push .sdds-no-padding [class|=sdds-col] {
  padding: 0 !important
}

@media (min-width: 992px) {
  .sdds-push .sdds-container {
    margin: 0
  }
}

.sdds-content-push {
  flex-grow: 1
}

.sdds-u-sticky {
  position: sticky !important
}

.sdds-u-static {
  position: static !important
}

.sdds-u-relative {
  position: relative !important
}

.sdds-u-absolute {
  position: absolute !important
}

.sdds-u-fixed {
  position: fixed !important
}

.sdds-u-top0 {
  top: 0 !important
}

.sdds-u-right0 {
  right: 0 !important
}

.sdds-u-bottom0 {
  bottom: 0 !important
}

.sdds-u-left0 {
  left: 0 !important
}

.sdds-u-p0 {
  padding: 0 !important
}

.sdds-u-pt0 {
  padding-top: 0 !important
}

.sdds-u-pr0 {
  padding-right: 0 !important
}

.sdds-u-pb0 {
  padding-bottom: 0 !important
}

.sdds-u-pl0 {
  padding-left: 0 !important
}

.sdds-u-p1 {
  padding: var(--sdds-spacing-layout-8) !important
}

.sdds-u-pt1 {
  padding-top: var(--sdds-spacing-layout-8) !important
}

.sdds-u-pr1 {
  padding-right: var(--sdds-spacing-layout-8) !important
}

.sdds-u-pb1 {
  padding-bottom: var(--sdds-spacing-layout-8) !important
}

.sdds-u-pl1 {
  padding-left: var(--sdds-spacing-layout-8) !important
}

.sdds-u-p2 {
  padding: var(--sdds-spacing-layout-16) !important
}

.sdds-u-pt2 {
  padding-top: var(--sdds-spacing-layout-16) !important
}

.sdds-u-pr2 {
  padding-right: var(--sdds-spacing-layout-16) !important
}

.sdds-u-pb2 {
  padding-bottom: var(--sdds-spacing-layout-16) !important
}

.sdds-u-pl2 {
  padding-left: var(--sdds-spacing-layout-16) !important
}

.sdds-u-p3 {
  padding: var(--sdds-spacing-layout-24) !important
}

.sdds-u-pt3 {
  padding-top: var(--sdds-spacing-layout-24) !important
}

.sdds-u-pr3 {
  padding-right: var(--sdds-spacing-layout-24) !important
}

.sdds-u-pb3 {
  padding-bottom: var(--sdds-spacing-layout-24) !important
}

.sdds-u-pl3 {
  padding-left: var(--sdds-spacing-layout-24) !important
}

.sdds-u-m0 {
  margin: 0 !important
}

.sdds-u-mt0 {
  margin-top: 0 !important
}

.sdds-u-mr0 {
  margin-right: 0 !important
}

.sdds-u-mb0 {
  margin-bottom: 0 !important
}

.sdds-u-ml0 {
  margin-left: 0 !important
}

.sdds-u-m1 {
  margin: var(--sdds-spacing-layout-8) !important
}

.sdds-u-mt1 {
  margin-top: var(--sdds-spacing-layout-8) !important
}

.sdds-u-mr1 {
  margin-right: var(--sdds-spacing-layout-8) !important
}

.sdds-u-mb1 {
  margin-bottom: var(--sdds-spacing-layout-8) !important
}

.sdds-u-ml1 {
  margin-left: var(--sdds-spacing-layout-8) !important
}

.sdds-u-m2 {
  margin: var(--sdds-spacing-layout-16) !important
}

.sdds-u-mt2 {
  margin-top: var(--sdds-spacing-layout-16) !important
}

.sdds-u-mr2 {
  margin-right: var(--sdds-spacing-layout-16) !important
}

.sdds-u-mb2 {
  margin-bottom: var(--sdds-spacing-layout-16) !important
}

.sdds-u-ml2 {
  margin-left: var(--sdds-spacing-layout-16) !important
}

.sdds-u-m3 {
  margin: var(--sdds-spacing-layout-24) !important
}

.sdds-u-mt3 {
  margin-top: var(--sdds-spacing-layout-24) !important
}

.sdds-u-mr3 {
  margin-right: var(--sdds-spacing-layout-24) !important
}

.sdds-u-mb3 {
  margin-bottom: var(--sdds-spacing-layout-24) !important
}

.sdds-u-ml3 {
  margin-left: var(--sdds-spacing-layout-24) !important
}

.sdds-u-m-auto {
  margin: auto !important
}

.sdds-u-mt-auto {
  margin-top: auto !important
}

.sdds-u-mr-auto {
  margin-right: auto !important
}

.sdds-u-ml-auto {
  margin-left: auto !important
}

.sdds-u-mb-auto {
  margin-bottom: auto !important
}

.sdds-u-w-auto {
  width: auto !important
}

.sdds-u-w-100 {
  width: 100% !important
}

.sdds-u-w-75 {
  width: 75% !important
}

.sdds-u-w-50 {
  width: 50% !important
}

.sdds-u-w-25 {
  width: 25% !important
}

.sdds-u-h-auto {
  height: auto !important
}

.sdds-u-h-100 {
  height: 100% !important
}

.sdds-u-h-75 {
  height: 75% !important
}

.sdds-u-h-50 {
  height: 50% !important
}

.sdds-u-h-25 {
  height: 25% !important
}

.sdds-u-flex {
  display: flex !important
}

.sdds-u-inline-flex {
  display: inline-flex
}

.sdds-u-block {
  display: block !important
}

.sdds-u-inline-block {
  display: inline-block !important
}

.sdds-u-grid {
  display: grid !important
}

.sdds-u-display-none {
  display: none !important
}

.sdds-u-z10 {
  z-index: 10 !important
}

.sdds-u-z100 {
  z-index: 100 !important
}

.sdds-u-z500 {
  z-index: 500 !important
}

.sdds-u-z1000 {
  z-index: 1000 !important
}

.sdds-u-opacity-100 {
  opacity: 1 !important
}

.sdds-u-opacity-75 {
  opacity: 0.75 !important
}

.sdds-u-opacity-50 {
  opacity: 0.5 !important
}

.sdds-u-opacity-25 {
  opacity: 0.25 !important
}

.sdds-u-visibility-hidden {
  visibility: hidden !important
}

.sdds-u-visibility-visible {
  visibility: visible !important
}

.sdds-u-textalign-start {
  text-align: start !important
}

.sdds-u-textalign-center {
  text-align: center !important
}

.sdds-u-textalign-end {
  text-align: end !important
}

.sdds-u-float-right {
  float: right !important
}

.sdds-u-float-left {
  float: left !important
}

.sdds-u-float-none {
  float: none !important
}

.sdds-u-overflow-auto {
  overflow: auto !important
}

.sdds-u-overflow-scroll {
  overflow: scroll !important
}

.sdds-u-overflow-hidden {
  overflow: hidden !important
}

.sdds-u-overflow-auto {
  overflow: visible !important
}

:root,
.sdds-mode-light {
  --sdds-banner-text-color: var(--sdds-grey-958);
  --sdds-banner-x-color: var(--sdds-black);
  --sdds-banner-background-default: var(--sdds-grey-50);
  --sdds-banner-prefix-default-color: var(--sdds-grey-700);
  --sdds-banner-background-info: var(--sdds-grey-300);
  --sdds-banner-prefix-info-color: var(--sdds-blue-400);
  --sdds-banner-background-error: var(--sdds-red-100);
  --sdds-banner-prefix-error-color: var(--sdds-red-500)
}

.sdds-mode-dark {
  --sdds-banner-text-color: var(--sdds-grey-50);
  --sdds-banner-x-color: var(--sdds-white);
  --sdds-banner-background-default: var(--sdds-grey-900);
  --sdds-banner-prefix-default-color: var(--sdds-grey-400);
  --sdds-banner-background-info: var(--sdds-blue-700);
  --sdds-banner-prefix-info-color: var(--sdds-information);
  --sdds-banner-background-error: var(--sdds-red-900);
  --sdds-banner-prefix-error-color: var(--sdds-negative)
}

:host,
:root {
  --sdds-banner-background-default: var(--sdds-grey-50);
  --sdds-banner-background-info: var(--sdds-grey-300);
  --sdds-banner-background-error: var(--sdds-red-100);
  --sdds-banner-prefix-default-color: var(--sdds-grey-700);
  --sdds-banner-prefix-info-color: var(--sdds-blue-400);
  --sdds-banner-prefix-error-color: var(--sdds-red-500)
}

.sdds-banner {
  box-sizing: border-box;
  background-color: var(--sdds-banner-background-default);
  padding: var(--sdds-spacing-element-16);
  display: flex
}

.sdds-banner * {
  box-sizing: border-box
}

.sdds-banner.sdds-banner-info {
  background-color: var(--sdds-banner-background-info)
}

.sdds-banner.sdds-banner-error {
  background-color: var(--sdds-banner-background-error)
}

.sdds-banner-prefix {
  display: inline-flex;
  flex-basis: 16px;
  height: 16px;
  width: 100%;
  margin-right: var(--sdds-spacing-element-12);
  font-size: 16px;
  color: var(--sdds-banner-prefix-default-color)
}

.sdds-banner-info .sdds-banner-prefix {
  color: var(--sdds-banner-prefix-info-color)
}

.sdds-banner-error .sdds-banner-prefix {
  color: var(--sdds-banner-prefix-error-color)
}

.sdds-banner-body {
  flex-grow: 1;
  padding-right: 24px
}

button.sdds-banner-close {
  appearance: none;
  padding: 0;
  border: none;
  background-color: transparent
}

.sdds-banner-close {
  position: relative;
  flex-basis: 16px;
  height: 16px;
  width: 16px;
  color: var(--sdds-banner-x-color);
  cursor: pointer;
  flex-shrink: 0
}

.sdds-banner-header {
  font: var(--sdds-headline-06);
  letter-spacing: var(--sdds-headline-06-ls);
  color: var(--sdds-banner-text-color);
  margin: 0
}

.sdds-banner-header+.sdds-banner-link,
.sdds-banner-header+.sdds-banner-subheader {
  margin-top: 4px
}

.sdds-banner-subheader {
  font: var(--sdds-detail-02);
  letter-spacing: var(--sdds-detail-02-ls);
  color: var(--sdds-banner-text-color)
}

.sdds-banner-subheader+.sdds-banner-link {
  margin-top: var(--sdds-spacing-element-16)
}

.sdds-banner-link {
  display: block;
  font: var(--sdds-detail-03);
  letter-spacing: var(--sdds-detail-03-ls)
}

:root,
.sdds-mode-light {
  --sdds-block-color: var(--sdds-grey-985);
  --sdds-block-background-primary: var(--sdds-grey-50);
  --sdds-block-background-nested-primary: var(--sdds-white);
  --sdds-block-background-secondary: var(--sdds-white);
  --sdds-block-background-nested-secondary: var(--sdds-grey-50);
  --sdds-block-background: var(--sdds-block-background-primary);
  --sdds-block-background-nested: var(--sdds-block-background-nested-primary)
}

:root .sdds-mode-variant-primary,
.sdds-mode-light .sdds-mode-variant-primary {
  --sdds-block-background: var(--sdds-block-background-primary);
  --sdds-block-background-nested: var(--sdds-block-background-nested-primary)
}

:root .sdds-mode-variant-secondary,
.sdds-mode-light .sdds-mode-variant-secondary {
  --sdds-block-background: var(--sdds-block-background-secondary);
  --sdds-block-background-nested: var(--sdds-block-background-nested-secondary)
}

.sdds-mode-dark {
  --sdds-block-color: var(--sdds-grey-50);
  --sdds-block-background-primary: var(--sdds-grey-900);
  --sdds-block-background-nested-primary: var(--sdds-grey-868);
  --sdds-block-background-secondary: var(--sdds-grey-868);
  --sdds-block-background-nested-secondary: var(--sdds-grey-900);
  --sdds-block-background: var(--sdds-block-background-primary);
  --sdds-block-background-nested: var(--sdds-block-background-nested-primary)
}

.sdds-mode-dark .sdds-mode-variant-primary {
  --sdds-block-background: var(--sdds-block-background-primary);
  --sdds-block-background-nested: var(--sdds-block-background-nested-primary)
}

.sdds-mode-dark .sdds-mode-variant-secondary {
  --sdds-block-background: var(--sdds-block-background-secondary);
  --sdds-block-background-nested: var(--sdds-block-background-nested-secondary)
}

.sdds-block {
  box-sizing: border-box;
  color: var(--sdds-block-color);
  border-radius: 4px;
  padding: 16px;
  font: var(--sdds-body-01);
  letter-spacing: var(--sdds-body-01-ls);
  background-color: var(--sdds-block-background)
}

.sdds-block * {
  box-sizing: border-box
}

.sdds-block .sdds-block {
  background-color: var(--sdds-block-background-nested)
}

.sdds-block .sdds-block .sdds-block {
  background-color: var(--sdds-block-background)
}

:root,
.sdds-mode-light {
  --sdds-breadcrumb-color: var(--sdds-grey-900);
  --sdds-breadcrumb-color-hover: var(--sdds-grey-700);
  --sdds-breadcrumb-color-focus: var(--sdds-grey-700);
  --sdds-breadcrumb-color-disabled: var(--sdds-grey-500);
  --sdds-breadcrumb-separator-color: var(--sdds-grey-500)
}

.sdds-mode-dark {
  --sdds-breadcrumb-color: var(--sdds-grey-300);
  --sdds-breadcrumb-color-hover: var(--sdds-grey-500);
  --sdds-breadcrumb-color-focus: var(--sdds-grey-500);
  --sdds-breadcrumb-color-disabled: var(--sdds-grey-700);
  --sdds-breadcrumb-separator-color: var(--sdds-grey-700)
}

.sdds-breadcrumb {
  box-sizing: border-box;
  font: var(--sdds-detail-02);
  letter-spacing: var(--sdds-detail-02-ls);
  display: flex;
  flex-wrap: wrap
}

.sdds-breadcrumb * {
  box-sizing: border-box
}

.sdds-breadcrumb .sdds-breadcrumb-item {
  color: var(--sdds-breadcrumb-color)
}

.sdds-breadcrumb .sdds-breadcrumb-item::after {
  content: "";
  background-color: var(--sdds-breadcrumb-separator-color);
  mask-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='4' height='8' viewBox='0 0 4 8' fill='currentColor'><path d='M2.548 4.178L0.602 0.985999H1.82L3.78 4.178L1.82 7.37H0.602L2.548 4.178Z' fill='currentColor'/></svg>");
  margin-right: 4px;
  margin-left: 4px;
  display: inline-block;
  width: 4px;
  height: 8px
}

.sdds-breadcrumb .sdds-breadcrumb-item>a {
  text-decoration: none;
  color: inherit
}

.sdds-breadcrumb .sdds-breadcrumb-item:hover {
  color: var(--sdds-breadcrumb-color-hover)
}

.sdds-breadcrumb .sdds-breadcrumb-item:hover>a {
  text-decoration: underline
}

.sdds-breadcrumb .sdds-breadcrumb-item:focus {
  color: var(--sdds-breadcrumb-color-focus);
  outline: 2px solid var(--sdds-blue-400);
  outline-offset: -2px
}

.sdds-breadcrumb .sdds-breadcrumb-item:disabled,
.sdds-breadcrumb .sdds-breadcrumb-item.disabled,
.sdds-breadcrumb .sdds-breadcrumb-item.sdds-breadcrumb-item-current,
.sdds-breadcrumb .sdds-breadcrumb-item [aria-current=page] {
  color: var(--sdds-breadcrumb-color-disabled)
}

.sdds-breadcrumb .sdds-breadcrumb-item:disabled a,
.sdds-breadcrumb .sdds-breadcrumb-item.disabled a,
.sdds-breadcrumb .sdds-breadcrumb-item.sdds-breadcrumb-item-current a,
.sdds-breadcrumb .sdds-breadcrumb-item [aria-current=page] a {
  cursor: default
}

.sdds-breadcrumb .sdds-breadcrumb-item:disabled:hover>a,
.sdds-breadcrumb .sdds-breadcrumb-item.disabled:hover>a,
.sdds-breadcrumb .sdds-breadcrumb-item.sdds-breadcrumb-item-current:hover>a,
.sdds-breadcrumb .sdds-breadcrumb-item [aria-current=page]:hover>a {
  color: var(--sdds-breadcrumb-color-disabled);
  text-decoration: none
}

.sdds-breadcrumb .sdds-breadcrumb-item:last-child::after {
  display: none
}

:root,
.sdds-mode-light {
  --sdds-btn-primary-background: var(--sdds-blue-600);
  --sdds-btn-primary-color: var(--sdds-white);
  --sdds-btn-primary-border-color: transparent;
  --sdds-btn-primary-background-hover: var(--sdds-blue-500);
  --sdds-btn-primary-color-hover: var(--sdds-white);
  --sdds-btn-primary-border-color-hover: transparent;
  --sdds-btn-primary-background-active: var(--sdds-blue-700);
  --sdds-btn-primary-color-active: var(--sdds-white);
  --sdds-btn-primary-border-color-active: var(--sdds-blue-700);
  --sdds-btn-primary-background-focus: var(--sdds-blue-400);
  --sdds-btn-primary-color-focus: var(--sdds-white);
  --sdds-btn-primary-border-color-focus: var(--sdds-blue-600);
  --sdds-btn-primary-outline-color-focus: var(--sdds-blue-600);
  --sdds-btn-primary-background-disabled-primary: var(--sdds-grey-50);
  --sdds-btn-primary-background-disabled-secondary: var(--sdds-white);
  --sdds-btn-primary-background-disabled: var(--sdds-btn-primary-background-disabled-primary);
  --sdds-btn-primary-color-disabled: var(--sdds-grey-400);
  --sdds-btn-primary-border-color-disabled: transparent;
  --sdds-btn-icon-primary-fill: var(--sdds-grey-50);
  --sdds-btn-icon-primary-color: var(--sdds-grey-50);
  --sdds-btn-secondary-background: transparent;
  --sdds-btn-secondary-color: var(--sdds-black);
  --sdds-btn-secondary-border-color: rgb(0 0 0 / 48%);
  --sdds-btn-secondary-background-hover: var(--sdds-blue-500);
  --sdds-btn-secondary-color-hover: var(--sdds-white);
  --sdds-btn-secondary-border-color-hover: var(--sdds-blue-500);
  --sdds-btn-secondary-background-active: transparent;
  --sdds-btn-secondary-color-active: var(--sdds-black);
  --sdds-btn-secondary-border-color-active: rgb(0 0 0 / 87%);
  --sdds-btn-secondary-background-focus: transparent;
  --sdds-btn-secondary-color-focus: var(--sdds-black);
  --sdds-btn-secondary-border-color-focus: var(--sdds-blue-400);
  --sdds-btn-secondary-outline-color: rgb(0 0 0 / 38%);
  --sdds-btn-secondary-outline-color-focus: var(--sdds-blue-400);
  --sdds-btn-secondary-background-disabled: transparent;
  --sdds-btn-secondary-color-disabled: rgb(0 0 0 / 38%);
  --sdds-btn-secondary-border-color-disabled: rgb(0 0 0 / 38%);
  --sdds-btn-icon-secondary-color-focus: var(--sdds-black);
  --sdds-btn-icon-secondary-fill-focus: var(--sdds-black);
  --sdds-btn-icon-secondary-fill: var(--sdds-black);
  --sdds-btn-icon-secondary-color: var(--sdds-black);
  --sdds-btn-icon-secondary-fill-hover: var(--sdds-grey-50);
  --sdds-btn-icon-secondary-color-hover: var(--sdds-grey-50);
  --sdds-btn-ghost-background: transparent;
  --sdds-btn-ghost-color: var(--sdds-black);
  --sdds-btn-ghost-border-color: transparent;
  --sdds-btn-ghost-background-hover: transparent;
  --sdds-btn-ghost-color-hover: var(--sdds-black);
  --sdds-btn-ghost-border-color-hover: rgb(0 0 0 / 60%);
  --sdds-btn-ghost-outline-color-hover: rgb(0 0 0 / 60%);
  --sdds-btn-ghost-background-active: transparent;
  --sdds-btn-ghost-color-active: var(--sdds-black);
  --sdds-btn-ghost-border-color-active: rgb(0 0 0 / 87%);
  --sdds-btn-ghost-background-focus: transparent;
  --sdds-btn-ghost-color-focus: var(--sdds-black);
  --sdds-btn-ghost-border-color-focus: var(--sdds-blue-300);
  --sdds-btn-ghost-outline-color-focus: var(--sdds-blue-300);
  --sdds-btn-ghost-background-disabled: transparent;
  --sdds-btn-ghost-color-disabled: rgb(0 0 0 / 38%);
  --sdds-btn-ghost-border-color-disabled: transparent;
  --sdds-btn-icon-ghost-fill: var(--sdds-black);
  --sdds-btn-icon-ghost-color: var(--sdds-black);
  --sdds-btn-danger-background: var(--sdds-negative);
  --sdds-btn-danger-color: var(--sdds-white);
  --sdds-btn-danger-border-color: transparent;
  --sdds-btn-danger-background-hover: var(--sdds-red-600);
  --sdds-btn-danger-color-hover: var(--sdds-white);
  --sdds-btn-danger-border-color-hover: transparent;
  --sdds-btn-danger-background-active: var(--sdds-red-700);
  --sdds-btn-danger-color-active: var(--sdds-white);
  --sdds-btn-danger-border-color-active: var(--sdds-red-700);
  --sdds-btn-danger-background-focus: var(--sdds-red-400);
  --sdds-btn-danger-color-focus: var(--sdds-white);
  --sdds-btn-danger-border-color-focus: var(--sdds-red-400);
  --sdds-btn-danger-outline-color-focus: var(--sdds-red-700);
  --sdds-btn-danger-background-disabled-primary: var(--sdds-grey-50);
  --sdds-btn-danger-background-disabled-secondary: var(--sdds-white);
  --sdds-btn-danger-background-disabled: var(--sdds-btn-danger-background-disabled-primary);
  --sdds-btn-danger-color-disabled: rgb(0 0 0 / 38%);
  --sdds-btn-danger-border-color-disabled: transparent;
  --sdds-btn-icon-danger-fill: var(--sdds-white);
  --sdds-btn-icon-danger-color: var(--sdds-white)
}

:root .sdds-mode-variant-primary,
.sdds-mode-light .sdds-mode-variant-primary {
  --sdds-btn-danger-background-disabled: var(--sdds-btn-danger-background-disabled-primary);
  --sdds-btn-primary-background-disabled: var(--sdds-btn-primary-background-disabled-primary)
}

:root .sdds-mode-variant-secondary,
.sdds-mode-light .sdds-mode-variant-secondary {
  --sdds-btn-danger-background-disabled: var(--sdds-btn-danger-background-disabled-secondary);
  --sdds-btn-primary-background-disabled: var(--sdds-btn-primary-background-disabled-secondary)
}

.sdds-mode-dark {
  --sdds-btn-primary-background: var(--sdds-blue-400);
  --sdds-btn-primary-background-hover: var(--sdds-blue-500);
  --sdds-btn-primary-background-active: var(--sdds-blue-300);
  --sdds-btn-primary-background-focus: var(--sdds-blue-600);
  --sdds-btn-primary-border-color-focus: var(--sdds-blue-400);
  --sdds-btn-primary-outline-color-focus: var(--sdds-blue-400);
  --sdds-btn-primary-background-disabled-primary: var(--sdds-grey-900);
  --sdds-btn-primary-background-disabled-secondary: var(--sdds-grey-868);
  --sdds-btn-primary-background-disabled: var(--sdds-btn-primary-background-disabled-primary);
  --sdds-btn-primary-color-disabled: var(--sdds-grey-600);
  --sdds-btn-icon-primary-fill: var(--sdds-grey-50);
  --sdds-btn-icon-primary-color: var(--sdds-grey-50);
  --sdds-btn-secondary-background: transparent;
  --sdds-btn-secondary-color: var(--sdds-white);
  --sdds-btn-secondary-border-color: var(--sdds-white);
  --sdds-btn-secondary-background-hover: var(--sdds-blue-500);
  --sdds-btn-secondary-color-hover: var(--sdds-white);
  --sdds-btn-secondary-border-color-hover: var(--sdds-blue-500);
  --sdds-btn-secondary-background-active: transparent;
  --sdds-btn-secondary-color-active: var(--sdds-white);
  --sdds-btn-secondary-border-color-active: var(--sdds-white);
  --sdds-btn-secondary-background-focus: transparent;
  --sdds-btn-secondary-color-focus: var(--sdds-white);
  --sdds-btn-secondary-border-color-focus: var(--sdds-blue-400);
  --sdds-btn-secondary-outline-color-focus: var(--sdds-blue-400);
  --sdds-btn-secondary-outline-color: var(--sdds-white);
  --sdds-btn-secondary-background-disabled: transparent;
  --sdds-btn-secondary-color-disabled: rgb(255 255 255 / 38%);
  --sdds-btn-secondary-border-color-disabled: rgb(255 255 255 / 38%);
  --sdds-btn-icon-secondary-color-focus: var(--sdds-white);
  --sdds-btn-icon-secondary-fill-focus: var(--sdds-white);
  --sdds-btn-icon-secondary-fill: var(--sdds-grey-50);
  --sdds-btn-icon-secondary-color: var(--sdds-grey-50);
  --sdds-btn-ghost-background: transparent;
  --sdds-btn-ghost-color: var(--sdds-white);
  --sdds-btn-ghost-border-color: transparent;
  --sdds-btn-ghost-background-hover: transparent;
  --sdds-btn-ghost-color-hover: var(--sdds-white);
  --sdds-btn-ghost-border-color-hover: rgb(255 255 255 / 41%);
  --sdds-btn-ghost-outline-color-hover: rgb(255 255 255 / 41%);
  --sdds-btn-ghost-background-active: transparent;
  --sdds-btn-ghost-color-active: var(--sdds-white);
  --sdds-btn-ghost-border-color-active: rgb(255 255 255 / 87%);
  --sdds-btn-ghost-background-focus: transparent;
  --sdds-btn-ghost-color-focus: var(--sdds-white);
  --sdds-btn-ghost-border-color-focus: var(--sdds-blue-300);
  --sdds-btn-ghost-outline-color-focus: var(--sdds-blue-300);
  --sdds-btn-ghost-background-disabled: transparent;
  --sdds-btn-ghost-color-disabled: rgb(255 255 255/ 38%);
  --sdds-btn-ghost-border-color-disabled: transparent;
  --sdds-btn-icon-ghost-fill: var(--sdds-grey-50);
  --sdds-btn-icon-ghost-color: var(--sdds-grey-50);
  --sdds-btn-danger-background: var(--sdds-negative);
  --sdds-btn-danger-color: var(--sdds-white);
  --sdds-btn-danger-border-color: transparent;
  --sdds-btn-danger-background-hover: var(--sdds-red-600);
  --sdds-btn-danger-color-hover: var(--sdds-white);
  --sdds-btn-danger-border-color-hover: transparent;
  --sdds-btn-danger-background-active: var(--sdds-red-700);
  --sdds-btn-danger-color-active: var(--sdds-white);
  --sdds-btn-danger-border-color-active: transparent;
  --sdds-btn-danger-background-focus: var(--sdds-red-400);
  --sdds-btn-danger-color-focus: var(--sdds-white);
  --sdds-btn-danger-border-color-focus: var(--sdds-red-400);
  --sdds-btn-danger-outline-color-focus: var(--sdds-red-700);
  --sdds-btn-danger-background-disabled-primary: var(--sdds-grey-900);
  --sdds-btn-danger-background-disabled-secondary: var(--sdds-grey-868);
  --sdds-btn-danger-background-disabled: var(--sdds-btn-danger-background-disabled-primary);
  --sdds-btn-danger-color-disabled: rgb(255 255 255 / 38%);
  --sdds-btn-danger-border-color-disabled: transparent;
  --sdds-btn-icon-danger-fill: var(--sdds-white);
  --sdds-btn-icon-danger-color: var(--sdds-white)
}

.sdds-mode-dark .sdds-mode-variant-primary {
  --sdds-btn-danger-background-disabled: var(--sdds-btn-danger-background-disabled-primary);
  --sdds-btn-primary-background-disabled: var(--sdds-btn-primary-background-disabled-primary)
}

.sdds-mode-dark .sdds-mode-variant-secondary {
  --sdds-btn-danger-background-disabled: var(--sdds-btn-danger-background-disabled-secondary);
  --sdds-btn-primary-background-disabled: var(--sdds-btn-primary-background-disabled-secondary)
}

.sdds-on-white-bg {
  --sdds-btn-primary-background-disabled: var(--sdds-btn-primary-background-secondary-disabled);
  --sdds-btn-danger-background-disabled: var(--sdds-btn-danger-background-secondary-disabled)
}

.sdds-btn-lg:not(.sdds-btn-only-icon) i.sdds-btn-icon {
  position: relative
}

.sdds-btn-lg:not(.sdds-btn-only-icon) i.sdds-btn-icon::before {
  font-size: 20px;
  line-height: 1;
  position: absolute;
  right: 0
}

.sdds-btn-lg.sdds-btn-only-icon i.sdds-btn-icon::before {
  font-size: 20px;
  line-height: 1.25
}

.sdds-btn-md:not(.sdds-btn-only-icon) i.sdds-btn-icon {
  position: relative
}

.sdds-btn-md:not(.sdds-btn-only-icon) i.sdds-btn-icon::before {
  font-size: 20px;
  line-height: 1;
  position: absolute;
  right: 0
}

.sdds-btn-md.sdds-btn-only-icon i.sdds-btn-icon::before {
  font-size: 20px;
  line-height: 1.25
}

.sdds-btn-sm:not(.sdds-btn-only-icon) i.sdds-btn-icon {
  position: relative
}

.sdds-btn-sm:not(.sdds-btn-only-icon) i.sdds-btn-icon::before {
  font-size: 16px;
  line-height: 1;
  position: absolute;
  right: 0
}

.sdds-btn-sm.sdds-btn-only-icon i.sdds-btn-icon::before {
  font-size: 16px;
  line-height: 1.25
}

.sdds-btn.sdds-btn-primary .sdds-btn-icon {
  fill: var(--sdds-btn-primary-icon-fill);
  color: var(--sdds-btn-primary-icon-color);
  font-size: 16px
}

.sdds-btn {
  box-sizing: border-box;
  font: var(--sdds-detail-02);
  letter-spacing: var(--sdds-detail-02-ls);
  display: flex;
  align-items: center;
  border-radius: 4px;
  border: none;
  box-shadow: none;
  position: relative;
  text-decoration: none;
  cursor: pointer;
  height: 56px;
  padding: var(--sdds-spacing-element-20);
  border: 1px solid
}

.sdds-btn * {
  box-sizing: border-box
}

.sdds-btn:disabled,
.sdds-btn.disabled {
  cursor: unset
}

.sdds-btn:focus {
  outline: none
}

.sdds-btn:focus-visible {
  outline: 2px solid var(--sdds-blue-400);
  outline-offset: -2px
}

.sdds-btn span.sdds-btn-text+.sdds-btn-icon {
  margin-left: var(--sdds-spacing-element-16);
  width: 20px;
  height: 20px;
  line-height: 0
}

.sdds-btn.sdds-btn-xs {
  padding: var(--sdds-spacing-element-4) var(--sdds-spacing-element-8);
  height: 24px;
  font-size: 12px;
  border-radius: 2px
}

.sdds-btn.sdds-btn-xs:focus-visible {
  outline: 2px solid var(--sdds-blue-400);
  outline-offset: -2px
}

.sdds-btn.sdds-btn-sm {
  padding: var(--sdds-spacing-element-12);
  height: 40px
}

.sdds-btn.sdds-btn-sm.sdds-btn-only-icon {
  padding: 11px
}

.sdds-btn.sdds-btn-sm:focus-visible {
  outline: 2px solid var(--sdds-blue-400);
  outline-offset: -2px
}

.sdds-btn.sdds-btn-sm span.sdds-btn-text+.sdds-btn-icon {
  margin: unset;
  margin-left: var(--sdds-spacing-element-24);
  width: 16px;
  height: 16px
}

.sdds-btn.sdds-btn-sm.sdds-btn-fullbleed span.sdds-btn-text+.sdds-btn-icon {
  margin: unset;
  margin-left: var(--sdds-spacing-element-12)
}

.sdds-btn.sdds-btn-md {
  padding: var(--sdds-spacing-element-16);
  height: 48px
}

.sdds-btn.sdds-btn-md.sdds-btn-only-icon {
  padding: 13px
}

.sdds-btn.sdds-btn-md:focus-visible {
  outline: 2px solid var(--sdds-blue-400);
  outline-offset: -2px
}

.sdds-btn.sdds-btn-md span.sdds-btn-text+.sdds-btn-icon {
  margin: unset;
  margin-left: var(--sdds-spacing-element-20)
}

.sdds-btn.sdds-btn-md.sdds-btn-fullbleed span.sdds-btn-text+.sdds-btn-icon {
  margin-left: var(--sdds-spacing-element-16)
}

.sdds-btn.sdds-btn-lg {
  padding: var(--sdds-spacing-element-20);
  height: 56px
}

.sdds-btn.sdds-btn-lg.sdds-btn-only-icon {
  padding: 17px
}

.sdds-btn.sdds-btn-lg:focus-visible {
  outline: 2px solid var(--sdds-blue-400);
  outline-offset: -2px
}

.sdds-btn.sdds-btn-lg span.sdds-btn-text+.sdds-btn-icon {
  margin: unset;
  margin-left: 28px
}

.sdds-btn.sdds-btn-lg.sdds-btn-fullbleed span.sdds-btn-text+.sdds-btn-icon {
  margin-left: var(--sdds-spacing-element-16)
}

.sdds-btn.sdds-btn-fullbleed {
  width: inherit;
  display: flex;
  justify-content: center
}

.sdds-btn.sdds-btn-fullbleed .sdds-btn-icon,
.sdds-btn.sdds-btn-fullbleed.sdds-btn-icon {
  margin-left: var(--sdds-spacing-element-8)
}

.sdds-btn span.sdds-btn-text {
  align-self: baseline
}

.sdds-btn.sdds-btn-primary {
  background: var(--sdds-btn-primary-background);
  border-color: var(--sdds-btn-primary-border-color);
  color: var(--sdds-btn-primary-color);
  outline-color: var(--sdds-btn-primary-outline-color)
}

.sdds-btn.sdds-btn-primary .sdds-btn-icon {
  fill: var(--sdds-btn-icon-primary-fill);
  color: var(--sdds-btn-icon-primary-fill);
  fill: var(--sdds-btn-icon-primary-color);
  color: var(--sdds-btn-icon-primary-color);
  font-size: 16px
}

.sdds-btn.sdds-btn-primary:hover {
  background: var(--sdds-btn-primary-background-hover);
  border-color: var(--sdds-btn-primary-border-color-hover);
  color: var(--sdds-btn-primary-color-hover);
  outline-color: var(--sdds-btn-primary-outline-color-hover)
}

.sdds-btn.sdds-btn-primary:hover .sdds-btn-icon {
  fill: var(--sdds-btn-icon-primary-fill-hover);
  color: var(--sdds-btn-icon-primary-fill-hover);
  fill: var(--sdds-btn-icon-primary-color-hover);
  color: var(--sdds-btn-icon-primary-color-hover)
}

.sdds-btn.sdds-btn-primary:focus-visible {
  background: var(--sdds-btn-primary-background-focus);
  border-color: var(--sdds-btn-primary-border-color-focus);
  color: var(--sdds-btn-primary-color-focus);
  outline-color: var(--sdds-btn-primary-outline-color-focus)
}

.sdds-btn.sdds-btn-primary:focus-visible .sdds-btn-icon {
  fill: var(--sdds-btn-icon-primary-fill-focus);
  color: var(--sdds-btn-icon-primary-fill-focus);
  fill: var(--sdds-btn-icon-primary-color-focus);
  color: var(--sdds-btn-icon-primary-color-focus)
}

.sdds-btn.sdds-btn-primary:active,
.sdds-btn.sdds-btn-primary.active {
  background: var(--sdds-btn-primary-background-active);
  border-color: var(--sdds-btn-primary-border-color-active);
  color: var(--sdds-btn-primary-color-active);
  outline-color: var(--sdds-btn-primary-outline-color-active)
}

.sdds-btn.sdds-btn-primary.disabled,
.sdds-btn.sdds-btn-primary:disabled {
  cursor: not-allowed;
  background: var(--sdds-btn-primary-background-disabled);
  cursor: not-allowed;
  border-color: var(--sdds-btn-primary-border-color-disabled);
  cursor: not-allowed;
  color: var(--sdds-btn-primary-color-disabled);
  cursor: not-allowed;
  outline-color: var(--sdds-btn-primary-outline-color-disabled)
}

.sdds-btn.sdds-btn-primary.disabled .sdds-btn-icon,
.sdds-btn.sdds-btn-primary.disabled.sdds-btn-icon,
.sdds-btn.sdds-btn-primary:disabled .sdds-btn-icon,
.sdds-btn.sdds-btn-primary:disabled.sdds-btn-icon {
  color: var(--sdds-grey-300)
}

.sdds-btn.sdds-btn-secondary {
  background: var(--sdds-btn-secondary-background);
  border-color: var(--sdds-btn-secondary-border-color);
  color: var(--sdds-btn-secondary-color);
  outline-color: var(--sdds-btn-secondary-outline-color)
}

.sdds-btn.sdds-btn-secondary .sdds-btn-icon {
  fill: var(--sdds-btn-icon-secondary-fill);
  color: var(--sdds-btn-icon-secondary-fill);
  fill: var(--sdds-btn-icon-secondary-color);
  color: var(--sdds-btn-icon-secondary-color);
  font-size: 16px
}

.sdds-btn.sdds-btn-secondary:hover {
  background: var(--sdds-btn-secondary-background-hover);
  border-color: var(--sdds-btn-secondary-border-color-hover);
  color: var(--sdds-btn-secondary-color-hover);
  outline-color: var(--sdds-btn-secondary-outline-color-hover)
}

.sdds-btn.sdds-btn-secondary:hover .sdds-btn-icon {
  fill: var(--sdds-btn-icon-secondary-fill-hover);
  color: var(--sdds-btn-icon-secondary-fill-hover);
  fill: var(--sdds-btn-icon-secondary-color-hover);
  color: var(--sdds-btn-icon-secondary-color-hover)
}

.sdds-btn.sdds-btn-secondary:focus-visible {
  background: var(--sdds-btn-secondary-background-focus);
  border-color: var(--sdds-btn-secondary-border-color-focus);
  color: var(--sdds-btn-secondary-color-focus);
  outline-color: var(--sdds-btn-secondary-outline-color-focus)
}

.sdds-btn.sdds-btn-secondary:focus-visible .sdds-btn-icon {
  fill: var(--sdds-btn-icon-secondary-fill-focus);
  color: var(--sdds-btn-icon-secondary-fill-focus);
  fill: var(--sdds-btn-icon-secondary-color-focus);
  color: var(--sdds-btn-icon-secondary-color-focus)
}

.sdds-btn.sdds-btn-secondary:active,
.sdds-btn.sdds-btn-secondary.active {
  background: var(--sdds-btn-secondary-background-active);
  border-color: var(--sdds-btn-secondary-border-color-active);
  color: var(--sdds-btn-secondary-color-active);
  outline-color: var(--sdds-btn-secondary-outline-color-active)
}

.sdds-btn.sdds-btn-secondary.disabled,
.sdds-btn.sdds-btn-secondary:disabled {
  cursor: not-allowed;
  background: var(--sdds-btn-secondary-background-disabled);
  cursor: not-allowed;
  border-color: var(--sdds-btn-secondary-border-color-disabled);
  cursor: not-allowed;
  color: var(--sdds-btn-secondary-color-disabled);
  cursor: not-allowed;
  outline-color: var(--sdds-btn-secondary-outline-color-disabled)
}

.sdds-btn.sdds-btn-secondary.disabled .sdds-btn-icon,
.sdds-btn.sdds-btn-secondary.disabled.sdds-btn-icon,
.sdds-btn.sdds-btn-secondary:disabled .sdds-btn-icon,
.sdds-btn.sdds-btn-secondary:disabled.sdds-btn-icon {
  color: rgba(0, 0, 0, 0.38)
}

.sdds-btn.sdds-btn-ghost {
  background: var(--sdds-btn-ghost-background);
  border-color: var(--sdds-btn-ghost-border-color);
  color: var(--sdds-btn-ghost-color);
  outline-color: var(--sdds-btn-ghost-outline-color)
}

.sdds-btn.sdds-btn-ghost .sdds-btn-icon {
  fill: var(--sdds-btn-icon-ghost-fill);
  color: var(--sdds-btn-icon-ghost-fill);
  fill: var(--sdds-btn-icon-ghost-color);
  color: var(--sdds-btn-icon-ghost-color);
  font-size: 16px
}

.sdds-btn.sdds-btn-ghost:hover {
  background: var(--sdds-btn-ghost-background-hover);
  border-color: var(--sdds-btn-ghost-border-color-hover);
  color: var(--sdds-btn-ghost-color-hover);
  outline-color: var(--sdds-btn-ghost-outline-color-hover)
}

.sdds-btn.sdds-btn-ghost:hover .sdds-btn-icon {
  fill: var(--sdds-btn-icon-ghost-fill-hover);
  color: var(--sdds-btn-icon-ghost-fill-hover);
  fill: var(--sdds-btn-icon-ghost-color-hover);
  color: var(--sdds-btn-icon-ghost-color-hover)
}

.sdds-btn.sdds-btn-ghost:focus-visible {
  background: var(--sdds-btn-ghost-background-focus);
  border-color: var(--sdds-btn-ghost-border-color-focus);
  color: var(--sdds-btn-ghost-color-focus);
  outline-color: var(--sdds-btn-ghost-outline-color-focus)
}

.sdds-btn.sdds-btn-ghost:focus-visible .sdds-btn-icon {
  fill: var(--sdds-btn-icon-ghost-fill-focus);
  color: var(--sdds-btn-icon-ghost-fill-focus);
  fill: var(--sdds-btn-icon-ghost-color-focus);
  color: var(--sdds-btn-icon-ghost-color-focus)
}

.sdds-btn.sdds-btn-ghost:active,
.sdds-btn.sdds-btn-ghost.active {
  background: var(--sdds-btn-ghost-background-active);
  border-color: var(--sdds-btn-ghost-border-color-active);
  color: var(--sdds-btn-ghost-color-active);
  outline-color: var(--sdds-btn-ghost-outline-color-active)
}

.sdds-btn.sdds-btn-ghost.disabled,
.sdds-btn.sdds-btn-ghost:disabled {
  cursor: not-allowed;
  background: var(--sdds-btn-ghost-background-disabled);
  cursor: not-allowed;
  border-color: var(--sdds-btn-ghost-border-color-disabled);
  cursor: not-allowed;
  color: var(--sdds-btn-ghost-color-disabled);
  cursor: not-allowed;
  outline-color: var(--sdds-btn-ghost-outline-color-disabled)
}

.sdds-btn.sdds-btn-ghost.disabled .sdds-btn-icon,
.sdds-btn.sdds-btn-ghost.disabled.sdds-btn-icon,
.sdds-btn.sdds-btn-ghost:disabled .sdds-btn-icon,
.sdds-btn.sdds-btn-ghost:disabled.sdds-btn-icon {
  color: rgba(0, 0, 0, 0.38)
}

.sdds-btn.sdds-btn-danger {
  background: var(--sdds-btn-danger-background);
  border-color: var(--sdds-btn-danger-border-color);
  color: var(--sdds-btn-danger-color);
  outline-color: var(--sdds-btn-danger-outline-color)
}

.sdds-btn.sdds-btn-danger .sdds-btn-icon {
  fill: var(--sdds-btn-icon-danger-fill);
  color: var(--sdds-btn-icon-danger-fill);
  fill: var(--sdds-btn-icon-danger-color);
  color: var(--sdds-btn-icon-danger-color);
  font-size: 16px
}

.sdds-btn.sdds-btn-danger:hover {
  background: var(--sdds-btn-danger-background-hover);
  border-color: var(--sdds-btn-danger-border-color-hover);
  color: var(--sdds-btn-danger-color-hover);
  outline-color: var(--sdds-btn-danger-outline-color-hover)
}

.sdds-btn.sdds-btn-danger:hover .sdds-btn-icon {
  fill: var(--sdds-btn-icon-danger-fill-hover);
  color: var(--sdds-btn-icon-danger-fill-hover);
  fill: var(--sdds-btn-icon-danger-color-hover);
  color: var(--sdds-btn-icon-danger-color-hover)
}

.sdds-btn.sdds-btn-danger:focus-visible {
  background: var(--sdds-btn-danger-background-focus);
  border-color: var(--sdds-btn-danger-border-color-focus);
  color: var(--sdds-btn-danger-color-focus);
  outline-color: var(--sdds-btn-danger-outline-color-focus)
}

.sdds-btn.sdds-btn-danger:focus-visible .sdds-btn-icon {
  fill: var(--sdds-btn-icon-danger-fill-focus);
  color: var(--sdds-btn-icon-danger-fill-focus);
  fill: var(--sdds-btn-icon-danger-color-focus);
  color: var(--sdds-btn-icon-danger-color-focus)
}

.sdds-btn.sdds-btn-danger:active,
.sdds-btn.sdds-btn-danger.active {
  background: var(--sdds-btn-danger-background-active);
  border-color: var(--sdds-btn-danger-border-color-active);
  color: var(--sdds-btn-danger-color-active);
  outline-color: var(--sdds-btn-danger-outline-color-active)
}

.sdds-btn.sdds-btn-danger.disabled,
.sdds-btn.sdds-btn-danger:disabled {
  cursor: not-allowed;
  background: var(--sdds-btn-danger-background-disabled);
  cursor: not-allowed;
  border-color: var(--sdds-btn-danger-border-color-disabled);
  cursor: not-allowed;
  color: var(--sdds-btn-danger-color-disabled);
  cursor: not-allowed;
  outline-color: var(--sdds-btn-danger-outline-color-disabled)
}

.sdds-btn.sdds-btn-danger.disabled .sdds-btn-icon,
.sdds-btn.sdds-btn-danger.disabled.sdds-btn-icon,
.sdds-btn.sdds-btn-danger:disabled .sdds-btn-icon,
.sdds-btn.sdds-btn-danger:disabled.sdds-btn-icon {
  color: var(--sdds-grey-300)
}

i.sdds-btn-icon[slot=icon] {
  line-height: 1
}

:host(sdds-button) {
  display: flex;
  align-items: center
}

:host(sdds-button) ::slotted([slot=icon]) {
  width: var(--sdds-spacing-element-20);
  height: var(--sdds-spacing-element-20)
}

:host(sdds-button) .sdds-btn-sm ::slotted([slot=icon]) {
  width: var(--sdds-spacing-element-16);
  height: var(--sdds-spacing-element-16)
}

:host(sdds-button[fullbleed]) {
  width: 100%;
  justify-content: center
}

:host(sdds-button[only-icon]) .sdds-btn-sm {
  padding: 11px
}

:host(sdds-button[only-icon]) .sdds-btn-md {
  padding: 13px
}

:host(sdds-button[only-icon]) .sdds-btn-lg {
  padding: 17px
}

:host(sdds-button:not([only-icon])) {
  display: flex;
  align-items: center
}

:host(sdds-button:not([only-icon])) .sdds-btn-sm ::slotted([slot=icon]) {
  margin-left: var(--sdds-spacing-element-24);
  width: var(--sdds-spacing-element-16);
  height: var(--sdds-spacing-element-16)
}

:host(sdds-button:not([only-icon])) .sdds-btn-sm.sdds-btn-fullbleed ::slotted([slot=icon]) {
  margin-left: var(--sdds-spacing-element-12)
}

:host(sdds-button:not([only-icon])) .sdds-btn-md ::slotted([slot=icon]) {
  margin-left: var(--sdds-spacing-element-20);
  width: var(--sdds-spacing-element-20);
  height: var(--sdds-spacing-element-20)
}

:host(sdds-button:not([only-icon])) .sdds-btn-md.sdds-btn-fullbleed ::slotted([slot=icon]) {
  margin-left: var(--sdds-spacing-element-16)
}

:host(sdds-button:not([only-icon])) .sdds-btn-lg ::slotted([slot=icon]) {
  margin-left: 28px;
  width: var(--sdds-spacing-element-20);
  height: var(--sdds-spacing-element-20)
}

:host(sdds-button:not([only-icon])) .sdds-btn-lg.sdds-btn-fullbleed ::slotted([slot=icon]) {
  margin-left: var(--sdds-spacing-element-16)
}

:root,
.sdds-mode-light {
  --sdds-card-background: var(--sdds-white);
  --sdds-card-box-primary: 0 3px 3px rgb(0 0 0 / 15%), 0 -1px 1px rgb(0 0 0 / 1%);
  --sdds-card-box-secondary: 0 3px 3px rgb(0 0 0 / 10%), 0 -1px 1px rgb(0 0 0 / 5%);
  --sdds-card-box: var(--sdds-card-box-primary);
  --sdds-card-box-hover: 0 -1px 1px rgb(0 0 0 / 5%), 0 3px 3px rgb(0 0 0 / 20%);
  --sdds-card-box-pressed: 0 -1px 1px rgb(0 0 0 / 5%), 0 1px 3px rgb(0 0 0 / 20%);
  --sdds-card-headline: var(--sdds-grey-958);
  --sdds-card-sub-headline: var(--sdds-grey-600);
  --sdds-card-body-color: var(--sdds-grey-700);
  --sdds-card-divider: var(--sdds-grey-300)
}

:root .sdds-mode-variant-primary,
.sdds-mode-light .sdds-mode-variant-primary {
  --sdds-card-box: var(--sdds-card-box-primary)
}

:root .sdds-mode-variant-secondary,
.sdds-mode-light .sdds-mode-variant-secondary {
  --sdds-card-box: var(--sdds-card-box-secondary)
}

.sdds-mode-dark {
  --sdds-card-background-primary: var(--sdds-grey-900);
  --sdds-card-background-secondary: var(--sdds-grey-868);
  --sdds-card-background: var(--sdds-card-background-primary);
  --sdds-card-box-primary: 0 3px 3px rgb(0 0 0 / 15%), 0 -1px 1px rgb(0 0 0 / 1%);
  --sdds-card-box-secondary: 0 3px 3px rgb(0 0 0 / 10%), 0 -1px 1px rgb(0 0 0 / 5%);
  --sdds-card-box: var(--sdds-card-box-primary);
  --sdds-card-box-hover: 0 -1px 1px rgb(0 0 0 / 5%), 0 3px 3px rgb(0 0 0 / 20%);
  --sdds-card-box-pressed: 0 -1px 1px rgb(0 0 0 / 5%), 0 1px 3px rgb(0 0 0 / 20%);
  --sdds-card-headline: var(--sdds-white);
  --sdds-card-sub-headline: var(--sdds-grey-400);
  --sdds-card-body-color: var(--sdds-white);
  --sdds-card-divider: var(--sdds-grey-700)
}

.sdds-mode-dark .sdds-mode-variant-primary {
  --sdds-card-box: var(--sdds-card-box-primary);
  --sdds-card-background: var(--sdds-card-background-primary)
}

.sdds-mode-dark .sdds-mode-variant-secondary {
  --sdds-card-box: var(--sdds-card-box-secondary);
  --sdds-card-background: var(--sdds-card-background-secondary)
}

.sdds-card {
  box-sizing: border-box;
  background-color: var(--sdds-card-background);
  border-radius: 4px;
  box-shadow: var(--sdds-card-box)
}

.sdds-card * {
  box-sizing: border-box
}

.sdds-card.sdds-clickable {
  cursor: pointer
}

.sdds-card.sdds-clickable:hover {
  box-shadow: var(--sdds-card-box-hover)
}

.sdds-card.sdds-clickable:active {
  box-shadow: var(--sdds-card-box-pressed)
}

.sdds-card.sdds-card-img-left {
  display: flex
}

.sdds-card.sdds-card-img-left .sdds-card-img {
  height: auto;
  width: auto;
  border-radius: 4px 0 0 4px
}

.sdds-card .sdds-card-header,
.sdds-card .sdds-card-header-avatar {
  padding: var(--sdds-spacing-element-16)
}

.sdds-card .sdds-card-header+.sdds-card-body,
.sdds-card .sdds-card-header-avatar+.sdds-card-body {
  padding-top: 0
}

.sdds-card .sdds-card-header+.sdds-card-img,
.sdds-card .sdds-card-header-avatar+.sdds-card-img {
  border-radius: 0
}

.sdds-card .sdds-card-header-avatar {
  display: flex
}

.sdds-card .sdds-card-header-avatar .sdds-card-headlines,
.sdds-card .sdds-card-header-avatar .sdds-card-avatar {
  vertical-align: top
}

.sdds-card .sdds-card-header-avatar .sdds-card-avatar {
  margin-right: 16px;
  overflow: hidden;
  height: 36px;
  width: 36px;
  border-radius: 50%
}

.sdds-card .sdds-card-header-avatar .sdds-card-avatar img,
.sdds-card .sdds-card-header-avatar .sdds-card-avatar svg {
  height: 100%;
  width: 100%
}

.sdds-card .sdds-card-headline,
.sdds-card .sdds-card-sub-headline {
  margin: 0;
  font: var(--sdds-headline-06);
  letter-spacing: var(--sdds-headline-06-ls);
  line-height: 16px
}

.sdds-card .sdds-card-headline {
  color: var(--sdds-card-headline);
  margin-bottom: var(--sdds-spacing-element-4)
}

.sdds-card .sdds-card-sub-headline {
  color: var(--sdds-card-sub-headline)
}

.sdds-card .sdds-divider-border-top {
  margin: 0 var(--sdds-spacing-element-16)
}

.sdds-card .sdds-card-img {
  height: 100%;
  width: 100%;
  border-radius: 4px 4px 0 0
}

.sdds-card .sdds-card-body {
  font: var(--sdds-detail-03);
  color: var(--sdds-card-body-color);
  letter-spacing: var(--sdds-detail-03-ls);
  padding: var(--sdds-spacing-element-16)
}

.sdds-card .sdds-card-footer {
  padding: var(--sdds-spacing-element-16)
}

.sdds-card .sdds-card-footer a {
  padding-right: var(--sdds-spacing-element-16)
}

:root,
.sdds-mode-light {
  --sdds-checkbox-color: var(--sdds-grey-900);
  --sdds-checkbox-interaction-01: var(--sdds-blue-800);
  --sdds-checkbox-interaction-02: var(--sdds-white);
  --sdds-checkbox-background-hover: var(--sdds-blue-800);
  --sdds-checkbox-background-focus: var(--sdds-blue-800);
  --sdds-checkbox-background-opacity-hover: 0.12;
  --sdds-checkbox-background-opacity-focus: 0.24;
  --sdds-checkbox-disabled: var(--sdds-grey-400);
  --sdds-checkbox-background-img: url("data:image/svg+xml;utf8,<svg width='8' height='6' viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg'> <path d='M1 2.33333L3.4 5C4.80589 3.4379 5.59411 2.5621 7 1' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round'/></svg>");
  --sdds-checkbox-background-img-disabled: url("data:image/svg+xml;utf8,<svg width='8' height='6' viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg'> <path d='M1 2.33333L3.4 5C4.80589 3.4379 5.59411 2.5621 7 1' stroke='%23b0b7c4' stroke-linecap='round' stroke-linejoin='round'/></svg>");
  --sdds-checkbox-border-color-disabled-after: var(--sdds-grey-500);
  --sdds-checkbox-color-disabled-after: var(--sdds-grey-500)
}

:root .sdds-mode-dark,
.sdds-mode-light .sdds-mode-dark {
  --sdds-checkbox-color: var(--sdds-white);
  --sdds-checkbox-interaction-01: var(--sdds-white);
  --sdds-checkbox-interaction-02: var(--sdds-grey-958);
  --sdds-checkbox-background-hover: var(--sdds-grey-600);
  --sdds-checkbox-background-focus: var(--sdds-grey-600);
  --sdds-checkbox-background-opacity-hover: 0.48;
  --sdds-checkbox-background-opacity-focus: 0.72;
  --sdds-checkbox-disabled: white;
  --sdds-checkbox-background-img: url("data:image/svg+xml;utf8,<svg width='8' height='6' viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg'> <path d='M1 2.33333L3.4 5C4.80589 3.4379 5.59411 2.5621 7 1' stroke='%230D0F13' stroke-linecap='round' stroke-linejoin='round'/></svg>");
  --sdds-checkbox-background-img-disabled: url("data:image/svg+xml;utf8,<svg width='8' height='6' viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg'> <path d='M1 2.33333L3.4 5C4.80589 3.4379 5.59411 2.5621 7 1' stroke='%2356657A' stroke-linecap='round' stroke-linejoin='round'/></svg>");
  --sdds-checkbox-border-color-disabled-after: var(--sdds-grey-700);
  --sdds-checkbox-color-disabled-after: var(--sdds-grey-700)
}

.sdds-checkbox-item {
  box-sizing: border-box;
  font: var(--sdds-detail-02);
  letter-spacing: var(--sdds-detail-02-ls);
  color: var(--sdds-checkbox-text);
  display: flex;
  align-items: center;
  margin-left: -4px
}

.sdds-checkbox-item * {
  box-sizing: border-box
}

.sdds-checkbox-item .sdds-form-input[type=checkbox] {
  appearance: none;
  outline: none;
  margin: 0;
  border: 0;
  border-radius: 2px;
  width: 24px;
  height: 24px;
  position: relative;
  cursor: pointer
}

.sdds-checkbox-item .sdds-form-input[type=checkbox]+label {
  color: var(--sdds-checkbox-color);
  padding-left: var(--sdds-spacing-element-4);
  padding-top: var(--sdds-spacing-element-4);
  padding-bottom: var(--sdds-spacing-element-4);
  cursor: pointer
}

.sdds-checkbox-item .sdds-form-input[type=checkbox]::before,
.sdds-checkbox-item .sdds-form-input[type=checkbox]::after {
  content: "";
  position: absolute;
  box-sizing: border-box
}

.sdds-checkbox-item .sdds-form-input[type=checkbox]::before {
  width: 24px;
  height: 24px;
  left: 0;
  top: 0;
  border-radius: 4px
}

.sdds-checkbox-item .sdds-form-input[type=checkbox]::after {
  border: 1px solid var(--sdds-checkbox-interaction-01);
  background-color: var(--sdds-checkbox-interaction-02);
  width: 16px;
  height: 16px;
  left: 4px;
  top: 4px;
  border-radius: 2px
}

.sdds-checkbox-item .sdds-form-input[type=checkbox]:hover::before {
  background-color: var(--sdds-checkbox-background-hover);
  opacity: var(--sdds-checkbox-background-opacity-hover)
}

.sdds-checkbox-item .sdds-form-input[type=checkbox]:focus::before {
  background-color: var(--sdds-checkbox-background-focus);
  opacity: var(--sdds-checkbox-background-opacity-focus);
  transition: opacity 0.2s ease-in-out
}

.sdds-checkbox-item .sdds-form-input[type=checkbox]:disabled,
.sdds-checkbox-item .sdds-form-input[type=checkbox].disabled {
  cursor: not-allowed
}

.sdds-checkbox-item .sdds-form-input[type=checkbox]:disabled::after,
.sdds-checkbox-item .sdds-form-input[type=checkbox].disabled::after {
  border-color: var(--sdds-checkbox-border-color-disabled-after)
}

.sdds-checkbox-item .sdds-form-input[type=checkbox]:disabled+label,
.sdds-checkbox-item .sdds-form-input[type=checkbox].disabled+label {
  color: var(--sdds-grey-600);
  cursor: not-allowed
}

.sdds-checkbox-item .sdds-form-input[type=checkbox]:disabled:hover::before,
.sdds-checkbox-item .sdds-form-input[type=checkbox].disabled:hover::before {
  display: none
}

.sdds-form-input[type=checkbox]:checked::after {
  background-image: var(--sdds-checkbox-background-img);
  background-color: var(--sdds-checkbox-interaction-01);
  background-repeat: no-repeat;
  background-position: center
}

.sdds-form-input[type=checkbox]:checked:disabled:hover::before,
.sdds-form-input[type=checkbox]:checked:disabled::before,
.sdds-form-input[type=checkbox]:checked.disabled:hover::before,
.sdds-form-input[type=checkbox]:checked.disabled::before {
  display: block;
  width: 16px;
  height: 16px;
  left: 4px;
  top: 4px;
  border: 1px solid #b0b7c4
}

.sdds-form-input[type=checkbox]:checked:disabled::after,
.sdds-form-input[type=checkbox]:checked.disabled::after {
  background-image: var(--sdds-checkbox-background-img-disabled);
  background-color: var(--sdds-checkbox-interaction-02);
  color: var(--sdds-checkbox-color-disabled-after)
}

:root,
.sdds-mode-light {
  --sdds-chips-color: var(--sdds-grey-958);
  --sdds-chips-color-active: var(--sdds-white);
  --sdds-chips-background: var(--sdds-grey-200);
  --sdds-chips-background-active: var(--sdds-blue-500);
  --sdds-chips-background-active-hover: var(--sdds-blue-600);
  --sdds-chips-background-hover: var(--sdds-grey-400);
  --sdds-chips-icon-fill: var(--sdds-grey-958);
  --sdds-chips-icon-fill-active: var(--sdds-white)
}

.sdds-mode-dark {
  --sdds-chips-color: var(--sdds-white);
  --sdds-chips-color-active: var(--sdds-white);
  --sdds-chips-background: var(--sdds-grey-800);
  --sdds-chips-background-hover: var(--sdds-grey-600);
  --sdds-chips-background-active: var(--sdds-blue-500);
  --sdds-chips-background-active-hover: var(--sdds-blue-300);
  --sdds-chips-icon-fill: var(--sdds-white);
  --sdds-chips-icon-fill-active: var(--sdds-white)
}

.sdds-chip,
button.sdds-chip {
  all: unset;
  box-sizing: border-box;
  color: var(--sdds-chips-color);
  background-color: var(--sdds-chips-background);
  display: inline-flex;
  padding: 8px 16px;
  border-radius: 100px;
  margin-right: 8px;
  margin-bottom: 8px;
  cursor: pointer;
  position: relative
}

.sdds-chip *,
button.sdds-chip * {
  box-sizing: border-box
}

.sdds-chip-text,
button.sdds-chip-text {
  font: var(--sdds-detail-02);
  letter-spacing: var(--sdds-detail-02-ls)
}

.sdds-chip:hover,
button.sdds-chip:hover {
  background-color: var(--sdds-chips-background-hover)
}

.sdds-chip:focus,
button.sdds-chip:focus {
  outline: 2px solid var(--sdds-blue-400);
  outline-offset: -2px
}

.sdds-chip-icon,
button.sdds-chip-icon {
  height: 16px;
  width: 16px;
  position: absolute;
  font-size: 16px
}

.sdds-chip-icon path,
button.sdds-chip-icon path {
  fill: var(--sdds-chips-icon-fill)
}

.sdds-chip-active,
button.sdds-chip-active {
  color: var(--sdds-chips-color-active);
  background-color: var(--sdds-chips-background-active)
}

.sdds-chip-active .sdds-chip-icon path,
button.sdds-chip-active .sdds-chip-icon path {
  fill: var(--sdds-chips-icon-fill-active)
}

.sdds-chip-active:hover,
button.sdds-chip-active:hover {
  background-color: var(--sdds-chips-background-active-hover)
}

.sdds-chip-sm,
.sdds-chip-small,
button.sdds-chip-sm,
button.sdds-chip-small {
  padding: 4px 16px
}

.sdds-chip-icon-left,
button.sdds-chip-icon-left {
  padding-left: 32px
}

.sdds-chip-icon-left .sdds-chip-icon,
button.sdds-chip-icon-left .sdds-chip-icon {
  left: 8px
}

.sdds-chip-icon-left .sdds-chip-icon sdds-icon,
button.sdds-chip-icon-left .sdds-chip-icon sdds-icon {
  display: flex;
  align-items: center
}

.sdds-chip-icon-right,
button.sdds-chip-icon-right {
  padding-right: 32px;
  flex-flow: row-reverse
}

.sdds-chip-icon-right .sdds-chip-icon,
button.sdds-chip-icon-right .sdds-chip-icon {
  right: 8px
}

.sdds-chip-icon-right .sdds-chip-icon sdds-icon,
button.sdds-chip-icon-right .sdds-chip-icon sdds-icon {
  display: flex;
  align-items: center
}

.sdds-table {
  box-sizing: border-box;
  border-collapse: collapse;
  display: table
}

.sdds-table * {
  box-sizing: border-box
}

.sdds-table>caption {
  font: var(--sdds-headline-07);
  letter-spacing: var(--sdds-headline-07-ls);
  text-align: left;
  height: 64px;
  display: table-caption;
  position: relative;
  background-color: var(--sdds-data-table-toolbar-background);
  color: var(--sdds-data-table-color);
  padding: var(--sdds-spacing-element-16);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px
}

.sdds-table>thead {
  display: table-header-group
}

.sdds-table>thead>tr>th:first-of-type {
  border-top-left-radius: 4px
}

.sdds-table>thead>tr>th:last-of-type {
  border-top-right-radius: 4px
}

.sdds-table>caption+thead>tr>th:first-of-type {
  border-top-left-radius: 0
}

.sdds-table>caption+thead>tr>th:last-of-type {
  border-top-right-radius: 0
}

.sdds-table>thead>tr>th {
  font: var(--sdds-headline-07);
  letter-spacing: var(--sdds-headline-07-ls);
  display: table-cell;
  text-align: left;
  color: var(--sdds-data-table-color);
  background-color: var(--sdds-data-table-header-background);
  border-bottom: 1px solid var(--sdds-data-table-divider);
  padding: var(--sdds-spacing-element-16);
  height: 48px;
  min-width: 192px;
  box-sizing: border-box;
  overflow: hidden;
  transition: background-color 200ms ease
}

.sdds-table>thead>tr>th:first-of-type {
  border-top-left-radius: 4px
}

.sdds-table>thead>tr>th:last-of-type {
  border-top-right-radius: 4px
}

.sdds-table>tbody {
  display: table-row-group
}

.sdds-table>tbody>tr>td {
  font: var(--sdds-detail-02);
  letter-spacing: var(--sdds-detail-02-ls);
  display: table-cell;
  box-sizing: border-box;
  color: var(--sdds-data-table-color);
  padding: var(--sdds-spacing-element-16);
  min-width: 192px;
  vertical-align: top;
  background-color: transparent;
  transition: background-color 200ms ease
}

.sdds-table>tbody>tr {
  display: table-row;
  border-bottom: 1px solid var(--sdds-data-table-divider);
  background-color: var(--sdds-data-table-body-row-background);
  transition: background-color 200ms ease
}

.sdds-table>tbody>tr:hover {
  background-color: var(--sdds-data-table-body-row-background-hover)
}

.sdds-table.sdds-table--compact>caption {
  height: 56px
}

.sdds-table.sdds-table--compact>thead>tr>th,
.sdds-table.sdds-table--compact>tbody>tr>td {
  padding: var(--sdds-spacing-element-8) var(--sdds-spacing-element-16)
}

.sdds-table.sdds-table--compact>thead>tr>th {
  height: 32px
}

.sdds-table.sdds-table--divider>thead>tr>th,
.sdds-table.sdds-table--divider>tbody>tr>td {
  border-right: 1px solid var(--sdds-data-table-divider)
}

.sdds-table.sdds-table--divider>thead>tr>th:last-child,
.sdds-table.sdds-table--divider>tbody>tr>td:last-child {
  border-right: none
}

.sdds-table.sdds-table--no-min-width>thead>tr>th,
.sdds-table.sdds-table--no-min-width>tbody>tr>td {
  min-width: unset
}

.sdds-table.sdds-table--responsive {
  width: 100%
}

.sdds-table__actionbar-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  min-width: 40px;
  border: none;
  background-color: transparent;
  color: var(--sdds-data-table-color);
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 250ms ease;
  margin-left: 0
}

.sdds-table__actionbar-btn:focus {
  outline: 2px solid var(--sdds-blue-400);
  outline-offset: -2px
}

.sdds-table__actionbar-btn:hover {
  background-color: var(--sdds-data-table-footer-btn-hover)
}

.sdds-table__actionbar-btn-icon {
  height: 20px;
  width: auto
}

:root,
.sdds-mode-light {
  --sdds-divider-background: var(--sdds-grey-300)
}

.sdds-mode-dark {
  --sdds-divider-background: var(--sdds-grey-700)
}

.sdds-theme-colored {
  --sdds-divider-background: var(--sdds-blue-700)
}

.sdds-divider {
  background-color: var(--sdds-divider-background);
  height: 1px;
  box-sizing: border-box
}

.sdds-divider-vertical {
  background-color: var(--sdds-divider-background);
  height: 100%;
  width: 1px;
  box-sizing: border-box
}

.sdds-divider-border-top {
  border-top: 1px solid var(--sdds-divider-background);
  box-sizing: border-box
}

.sdds-divider-border-right {
  border-right: 1px solid var(--sdds-divider-background);
  box-sizing: border-box
}

.sdds-divider-border-bottom {
  border-bottom: 1px solid var(--sdds-divider-background);
  box-sizing: border-box
}

.sdds-divider-border-left {
  border-left: 1px solid var(--sdds-divider-background);
  box-sizing: border-box
}

:root,
.sdds-mode-light {
  --sdds-footer-top-background-primary: var(--sdds-grey-50);
  --sdds-footer-top-background-secondary: var(--sdds-white);
  --sdds-footer-top-background: var(--sdds-footer-top-background-primary);
  --sdds-footer-top-divider: var(--sdds-grey-300);
  --sdds-footer-top-links: var(--sdds-blue-800);
  --sdds-footer-top-links-background-hover: var(--sdds-grey-300);
  --sdds-footer-top-button-active: var(--sdds-grey-300);
  --sdds-footer-top-links-opacity: 0.87;
  --sdds-footer-top-links-opacity-hover: 0.38;
  --sdds-footer-top-title-opacity-mobile: 0.87;
  --sdds-footer-top-title-opacity: 0.6;
  --sdds-footer-main-background: var(--sdds-blue-800);
  --sdds-footer-main-links: var(--sdds-white);
  --sdds-footer-main-divider: var(--sdds-blue-600);
  --sdds-footer-main-links-opacity: 0.87;
  --sdds-footer-main-copyright: rgb(255 255 255 / 60%)
}

:root .sdds-mode-variant-primary,
.sdds-mode-light .sdds-mode-variant-primary {
  --sdds-footer-top-background: var(--sdds-footer-top-background-primary)
}

:root .sdds-mode-variant-secondary,
.sdds-mode-light .sdds-mode-variant-secondary {
  --sdds-footer-top-background: var(--sdds-footer-top-background-secondary)
}

.sdds-mode-dark {
  --sdds-footer-top-background-primary: var(--sdds-grey-868);
  --sdds-footer-top-background: var(--sdds-footer-top-background-primary);
  --sdds-footer-top-divider: var(--sdds-grey-800);
  --sdds-footer-top-links: var(--sdds-grey-50);
  --sdds-footer-top-links-background-hover: var(--sdds-grey-700);
  --sdds-footer-top-button-active: var(--sdds-grey-700);
  --sdds-footer-top-links-opacity: 0.87;
  --sdds-footer-top-links-opacity-hover: 0.38;
  --sdds-footer-top-title-opacity-mobile: 0.87;
  --sdds-footer-top-title-opacity: 0.6;
  --sdds-footer-main-background: var(--sdds-blue-900);
  --sdds-footer-main-links: var(--sdds-white);
  --sdds-footer-main-divider: var(--sdds-blue-600);
  --sdds-footer-main-links-opacity: 0.87;
  --sdds-footer-main-copyright: rgb(255 255 255 / 60%)
}

.sdds-mode-dark .sdds-mode-variant-primary,
.sdds-mode-dark .sdds-mode-variant-secondary {
  --sdds-footer-top-background: var(--sdds-footer-top-background-primary)
}

.sdds-footer {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden
}

.sdds-footer * {
  box-sizing: border-box
}

.sdds-footer-main-links,
.sdds-footer-social-links {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column
}

.sdds-footer-main-links li,
.sdds-footer-social-links li {
  list-style: none;
  padding: 0;
  margin: 0;
  display: inline-block
}

.sdds-footer-main-links li a,
.sdds-footer-social-links li a {
  margin-right: var(--sdds-spacing-element-24);
  text-decoration: none
}

.sdds-footer-main-links li a:focus,
.sdds-footer-social-links li a:focus {
  outline: 2px solid var(--sdds-blue-400);
  outline-offset: -2px
}

.sdds-footer-top {
  background-color: var(--sdds-footer-top-background)
}

.sdds-footer-top .sdds-footer-title {
  font: var(--sdds-headline-07);
  letter-spacing: var(--sdds-headline-07-ls);
  position: relative;
  color: var(--sdds-footer-top-links);
  padding: 20px 0;
  opacity: var(--sdds-footer-top-title-opacity-mobile);
  display: flex;
  cursor: pointer
}

.sdds-footer-top .sdds-footer-title::after {
  content: "";
  position: absolute;
  width: calc(100% + 120px);
  height: 100%;
  z-index: -1;
  border-bottom: 1px solid var(--sdds-footer-top-divider);
  box-sizing: border-box;
  bottom: 0;
  left: -48px
}

@media all and (min-width: 992px) {
  .sdds-footer-top .sdds-footer-title {
    padding: 0;
    margin-bottom: var(--sdds-spacing-element-8)
  }

  .sdds-footer-top .sdds-footer-title::after {
    display: none
  }
}

.sdds-footer-top .sdds-footer-title span:first-child {
  flex-grow: 1
}

.sdds-footer-top .sdds-footer-title .sdds-footer-top-icon {
  transition: transform 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transform: rotate(0deg)
}

.sdds-footer-top .sdds-footer-title.opened .sdds-footer-top-icon {
  transform: rotate(180deg)
}

.sdds-footer-top .sdds-footer-main-links {
  display: none
}

.sdds-footer-top .sdds-footer-main-links li a {
  padding: 20px;
  opacity: var(--sdds-footer-top-links-opacity);
  font-weight: normal;
  display: block;
  position: relative;
  color: var(--sdds-footer-top-links)
}

.sdds-footer-top .sdds-footer-main-links li a::after {
  content: "";
  position: absolute;
  width: calc(100% + 120px);
  height: 100%;
  z-index: -1;
  border-bottom: 1px solid var(--sdds-footer-top-divider);
  box-sizing: border-box;
  bottom: 0;
  left: -48px
}

@media all and (min-width: 992px) {
  .sdds-footer-top .sdds-footer-main-links li a {
    padding: 0;
    margin-bottom: var(--sdds-spacing-element-8)
  }

  .sdds-footer-top .sdds-footer-main-links li a::after {
    display: none
  }
}

.sdds-footer-top .sdds-footer-main-links li a:hover,
.sdds-footer-top .sdds-footer-main-links li a.hover {
  text-decoration: underline;
  opacity: var(--sdds-footer-top-links-opacity-hover)
}

.sdds-footer-top .sdds-footer-main-links.opened {
  display: flex
}

.sdds-footer-main {
  background-color: var(--sdds-footer-main-background);
  padding: 24px 24px 32px;
  display: flex;
  width: 100%;
  align-items: stretch;
  flex-direction: column
}

.sdds-footer-main .sdds-footer-main-links li a,
.sdds-footer-main .sdds-footer-social-links li a {
  font-weight: bold;
  color: var(--sdds-footer-main-links);
  opacity: var(--sdds-main-links-opacity)
}

.sdds-footer-main .sdds-footer-main-links {
  margin-bottom: var(--sdds-spacing-element-24)
}

.sdds-footer-main .sdds-footer-main-links li a {
  margin-bottom: var(--sdds-spacing-element-8);
  display: inline-block
}

.sdds-footer-main .sdds-footer-main-links li a:hover,
.sdds-footer-main .sdds-footer-main-links li a.hover {
  text-decoration: underline
}

.sdds-footer-main .sdds-footer-social-links {
  margin-top: var(--sdds-spacing-element-24);
  margin-bottom: var(--sdds-spacing-element-24);
  flex-direction: row
}

.sdds-footer-main .sdds-footer-social-links li a {
  margin-left: 0;
  margin-right: var(--sdds-spacing-element-24)
}

.sdds-footer-main .sdds-footer-social-links li a:hover,
.sdds-footer-main .sdds-footer-social-links li a.hover {
  text-decoration: none
}

.sdds-footer-main .sdds-footer-main-brand {
  border-top: 1px solid var(--sdds-footer-main-divider);
  padding-top: 24px;
  padding-bottom: 116px;
  width: 100%;
  background-image: var(--sdds-background-image-scania-wordmark-white-png);
  background-image: var(--sdds-background-image-scania-wordmark-white-svg);
  background-repeat: no-repeat;
  background-size: 117px;
  background-position: right bottom
}

.sdds-footer-main .sdds-footer-main-brand p {
  font: var(--sdds-detail-02);
  letter-spacing: var(--sdds-detail-02-ls);
  color: var(--sdds-footer-main-copyright);
  margin: 0;
  padding: 0
}

@media all and (min-width: 800px) {
  .sdds-footer-main {
    padding: 24px 32px 32px
  }
}

@media all and (min-width: 992px) {
  .sdds-footer-top {
    padding-top: 40px;
    padding-bottom: 120px
  }

  .sdds-footer-top .sdds-footer-title {
    opacity: var(--sdds-footer-top-title-opacity);
    cursor: default
  }

  .sdds-footer-top .sdds-footer-title .sdds-footer-top-icon {
    display: none
  }

  .sdds-footer-top .sdds-footer-main-links {
    display: flex
  }

  .sdds-footer-top .sdds-footer-main-links li a {
    font-weight: bold
  }

  .sdds-footer-main {
    padding: 40px 32px 0;
    flex-direction: row;
    flex-wrap: wrap
  }

  .sdds-footer-main .sdds-footer-main-links {
    flex-direction: row;
    flex-grow: 1;
    margin-bottom: var(--sdds-spacing-element-40)
  }

  .sdds-footer-main .sdds-footer-main-links li a {
    margin-bottom: 0
  }

  .sdds-footer-main .sdds-footer-social-links {
    margin-top: 0;
    text-align: right
  }

  .sdds-footer-main .sdds-footer-social-links li a {
    margin-right: 0;
    margin-left: var(--sdds-spacing-element-24)
  }

  .sdds-footer-main .sdds-footer-main-brand {
    padding-top: 40px;
    padding-bottom: 40px;
    background-position: right center
  }
}

:root,
.sdds-mode-light {
  --sdds-header-overlay: var(--sdds-black);
  --sdds-header-app-name-color: var(--sdds-white);
  --sdds-header-background: var(--sdds-blue-800);
  --sdds-header-mob-menu-color: var(--sdds-white);
  --sdds-header-mob-menu-open-color: var(--sdds-grey-958);
  --sdds-header-mob-menu-open-background: var(--sdds-white);
  --sdds-header-mob-menu-open-border: var(--sdds-grey-300);
  --sdds-nav-item-background: var(--sdds-white);
  --sdds-nav-item-background-active: var(--sdds-grey-100);
  --sdds-nav-item-background-hover: var(--sdds-grey-100);
  --sdds-nav-item-border-color: var(--sdds-blue-700);
  --sdds-nav-item-border-color-active: var(--sdds-blue-400);
  --sdds-nav-item-core-background-hover: var(--sdds-grey-100);
  --sdds-nav-item-core-color-active: var(--sdds-grey-800);
  --sdds-header-nav-item-color: var(--sdds-white);
  --sdds-header-nav-item-background-active: var(--sdds-blue-700);
  --sdds-nav-dropdown-item-background: var(--sdds-white);
  --sdds-nav-dropdown-item-border-color: var(--sdds-grey-300);
  --sdds-header-nav-item-dropdown-opened-background: var(--sdds-white);
  --sdds-header-nav-item-dropdown-opened-color: var(--sdds-grey-958);
  --sdds-header--basic-element-background: var(--sdds-blue-800);
  --sdds-header--basic-element-background-hover: var(--sdds-blue-700);
  --sdds-header-searchbar-input-background: var(--sdds-white);
  --sdds-header-searchbar-input-color: var(--sdds-black);
  --sdds-header-searchbar-input-placeholder-color: var(--sdds-grey-958);
  --sdds-header-searchbar-input-expanded-after-border-bottom: var(--sdds-grey-300);
  --sdds-header-searchbar-border-color: var(--sdds-blue-700);
  --sdds-header-searchbar-btn-active: var(--sdds-white);
  --sdds-header-searchbar-x-btn-background: var(--sdds-white);
  --sdds-header-searchbar-x-btn-background-hover: var(--sdds-grey-100);
  --sdds-header-searchbar-btn-svg-fill: var(--sdds-grey-958);
  --sdds-header-searchbar-result-item-background: var(--sdds-white);
  --sdds-header-searchbar-result-item-background-hover: var(--sdds-grey-100);
  --sdds-header-searchbar-result-item-background-active: var(--sdds-blue-400);
  --sdds-header-searchbar-result-item-background-visited: var(--sdds-grey-400);
  --sdds-header-searchbar-result-item-anchor-background-hover: var(--sdds-grey-100);
  --sdds-header-searchbar-result-item-top-border-color: var(--sdds-grey-300);
  --sdds-header-searchbar-result-item-top-background: var(--sdds-white);
  --sdds-header-searchbar-result-item-category-title-background: var(--sdds-white);
  --sdds-header-searchbar-result-x-btn: var(--sdds-white);
  --sdds-header-searchbar-result-item-core-color: var(--sdds-black);
  --sdds-header-searchbar-result-item-core-background: var(--sdds-white);
  --sdds-header-searchbar-result-category-border-color: var(--sdds-grey-300);
  --sdds-header-searchbar-result-category-background: var(--sdds-white);
  --sdds-header-searchbar-all-result-item-color: var(--sdds-black);
  --sdds-header-searchbar-all-result-item-background-hover: var(--sdds-grey-100);
  --sdds-header-searchbar-all-result-item-background-active: var(--sdds-blue-400);
  --sdds-header-searchbar-all-result-item-background-visited: var(--sdds-grey-400);
  --sdds-header-avatar-item-background: var(--sdds-white);
  --sdds-header-avatar-item-background-hover: var(--sdds-grey-100);
  --sdds-header-avatar-item-border-color: var(--sdds-grey-300);
  --sdds-header-avatar-subtitle-color: var(--sdds-grey-500);
  --sdds-header-avatar-item-color: var(--sdds-grey-700);
  --sdds-header-avatar-item-btn-background: var(--sdds-white);
  --sdds-header-app-launcher-menu-background: var(--sdds-white);
  --sdds-header-app-launcher-btn-background: var(--sdds-white);
  --sdds-header-app-launcher-btn-color: var(--sdds-white);
  --sdds-header-app-launcher-open-btn-color: var(--sdds-black);
  --sdds-header-app-launcher-item-border-color: var(--sdds-grey-300);
  --sdds-header-app-launcher-category-title-background: var(--sdds-white);
  --sdds-header-app-launcher-category-title-color: var(--sdds-grey-500);
  --sdds-header-app-launcher-item-background: var(--sdds-white);
  --sdds-header-app-launcher-item-background-hover: var(--sdds-grey-100);
  --sdds-header-app-launcher-item-color: var(--sdds-grey-700);
  --sdds-header-mobile-nav-center-background: var(--sdds-white);
  --sdds-header-mobile-nav-center-item-border-color: var(--sdds-grey-300);
  --sdds-header-mobile-nav-center-item-border-color-active-after: var(--sdds-blue-400);
  --sdds-header-mobile-nav-center-dropdown-item--last-border-color: var(--sdds-grey-300);
  --sdds-header-mobile-nav-toolbar-border-color: var(--sdds-grey-300);
  --sdds-header-mobile-searchbar-container-border-color: var(--sdds-grey-300);
  --sdds-header-mobile-searchbar-input-background: var(--sdds-white);
  --sdds-header-mobile-searchbar-input-color: var(--sdds-black);
  --sdds-header-mobile-searchbar-input-placeholder-color: var(--sdds-grey-800);
  --sdds-header-mobile-searchbar-input-expanded-before-background: var(--sdds-white);
  --sdds-header-mobile-searchbar-btn-background: var(--sdds-white);
  --sdds-header-mobile-searchbar-btn-background-hover: var(--sdds-grey-100);
  --sdds-header-mobile-searchbar-open-btn-background: var(--sdds-white);
  --sdds-header-mobile-searchbar-open-btn-background-hover: var(--sdds-grey-100);
  --sdds-header-mobile-searchbar-btn-svg-fill: var(--sdds-grey-958);
  --sdds-header-mobile-searchbar-container-color: var(--sdds-grey-958);
  --sdds-header-mobile-avatar--item-btn-background: var(--sdds-white);
  --sdds-header-mobile-avatar--item-btn-background-hover: var(--sdds-grey-100);
  --sdds-header-mobile-avatar--item-btn-color: var(--sdds-grey-958)
}

.sdds-mode-dark {
  --sdds-header-background: var(--sdds-blue-900);
  --sdds-header-mob-menu-open-color: var(--sdds-white);
  --sdds-header-mob-menu-open-background: var(--sdds-blue-800);
  --sdds-header-mob-menu-open-border: var(--sdds-blue-700);
  --sdds-nav-item-background: var(--sdds-grey-800);
  --sdds-nav-item-background-hover: var(--sdds-grey-700);
  --sdds-nav-item-background-active: var(--sdds-grey-700);
  --sdds-nav-item-border-color: var(--sdds-blue-700);
  --sdds-nav-item-border-color-active: var(--sdds-blue-300);
  --sdds-nav-item-core-background-hover: var(--sdds-grey-700);
  --sdds-nav-item-core-color-active: var(--sdds-grey-50);
  --sdds-header-nav-item-color: var(--sdds-white);
  --sdds-header-nav-item-background-active: var(--sdds-blue-800);
  --sdds-nav-dropdown-item-background: var(--sdds-grey-800);
  --sdds-nav-dropdown-item-border-color: var(--sdds-grey-700);
  --sdds-header-nav-item-dropdown-opened-background: var(--sdds-grey-800);
  --sdds-header-nav-item-dropdown-opened-color: var(--sdds-white);
  --sdds-header-searchbar-input-background: var(--sdds-grey-958);
  --sdds-header-searchbar-input-color: var(--sdds-white);
  --sdds-header-searchbar-input-placeholder-color: var(--sdds-grey-400);
  --sdds-header-searchbar-input-expanded-after-border-bottom: var(--sdds-grey-700);
  --sdds-header-searchbar-border-color: var(--sdds-blue-700);
  --sdds-header-searchbar-x-btn-background: var(--sdds-grey-958);
  --sdds-header-searchbar-x-btn-background-hover: var(--sdds-grey-868);
  --sdds-header-searchbar-btn-svg-fill: var(--sdds-white);
  --sdds-header-searchbar-result-item-background: var(--sdds-grey-958);
  --sdds-header-searchbar-result-item-background-hover: var(--sdds-grey-868);
  --sdds-header-searchbar-result-item-background-active: var(--sdds-grey-800);
  --sdds-header-searchbar-result-item-background-visited: var(--sdds-grey-600);
  --sdds-header-searchbar-result-item-anchor-background-hover: var(--sdds-grey-868);
  --sdds-header-searchbar-result-item-top-border-color: var(--sdds-grey-700);
  --sdds-header-searchbar-result-item-top-background: var(--sdds-grey-958);
  --sdds-header-searchbar-result-item-category-title-background: var(--sdds-grey-958);
  --sdds-header-searchbar-result-x-btn: var(--sdds-white);
  --sdds-header-searchbar-result-item-core-color: var(--sdds-white);
  --sdds-header-searchbar-result-item-core-background: var(--sdds-grey-958);
  --sdds-header-searchbar-result-category-border-color: var(--sdds-grey-700);
  --sdds-header-searchbar-result-category-background: var(--sdds-grey-958);
  --sdds-header-searchbar-all-result-item-color: var(--sdds-white);
  --sdds-header-searchbar-all-result-item-background-hover: var(--sdds-grey-868);
  --sdds-header-searchbar-all-result-item-background-active: var(--sdds-grey-868);
  --sdds-header-searchbar-all-result-item-background-visited: var(--sdds-grey-600);
  --sdds-header-avatar-item-background: var(--sdds-grey-800);
  --sdds-header-avatar-item-border-color: var(--sdds-grey-700);
  --sdds-header-avatar-subtitle-color: var(--sdds-grey-500);
  --sdds-header-avatar-title-color: var(--sdds-grey-50);
  --sdds-header-avatar-item-background-hover: var(--sdds-grey-700);
  --sdds-header-avatar-item-color: var(--sdds-grey-50);
  --sdds-header-avatar-item-btn-background: var(--sdds-grey-800);
  --sdds-header-app-launcher-menu-background: var(--sdds-grey-800);
  --sdds-header-app-launcher-btn-background: var(--sdds-grey-800);
  --sdds-header-app-launcher-btn-color: var(--sdds-white);
  --sdds-header-app-launcher-open-btn-color: var(--sdds-white);
  --sdds-header-app-launcher-item-border-color: var(--sdds-grey-700);
  --sdds-header-app-launcher-category-title-background: var(--sdds-grey-800);
  --sdds-header-app-launcher-category-title-color: var(--sdds-grey-500);
  --sdds-header-app-launcher-item-background: var(--sdds-grey-800);
  --sdds-header-app-launcher-item-background-hover: var(--sdds-grey-700);
  --sdds-header-app-launcher-item-color: var(--sdds-grey-50);
  --sdds-header-mobile-nav-center-background: var(--sdds-grey-958);
  --sdds-header-mobile-nav-center-item-border-color: var(--sdds-grey-868);
  --sdds-header-mobile-nav-center-item-border-color-active-after: var(--sdds-blue-400);
  --sdds-header-mobile-nav-center-dropdown-item--last-border-color: var(--sdds-grey-868);
  --sdds-header-mobile-nav-toolbar-border-color: var(--sdds-grey-868);
  --sdds-header-mobile-searchbar-container-border-color: var(--sdds-grey-868);
  --sdds-header-mobile-searchbar-input-background: var(--sdds-grey-958);
  --sdds-header-mobile-searchbar-input-color: var(--sdds-white);
  --sdds-header-mobile-searchbar-input-placeholder-color: var(--sdds-grey-400);
  --sdds-header-mobile-searchbar-input-expanded-before-background: var(--sdds-black);
  --sdds-header-mobile-searchbar-btn-background: var(--sdds-grey-958);
  --sdds-header-mobile-searchbar-btn-background-hover: var(--sdds-grey-868);
  --sdds-header-mobile-searchbar-open-btn-background: var(--sdds-grey-958);
  --sdds-header-mobile-searchbar-open-btn-background-hover: var(--sdds-grey-868);
  --sdds-header-mobile-searchbar-btn-svg-fill: var(--sdds-white);
  --sdds-header-mobile-searchbar-container-color: var(--sdds-white);
  --sdds-header-mobile-avatar--item-btn-background: var(--sdds-grey-958);
  --sdds-header-mobile-avatar--item-btn-background-hover: var(--sdds-grey-868);
  --sdds-header-mobile-avatar--item-btn-color: var(--sdds-white)
}

html,
:root {
  --sdds-nav-dropdown-menu-box: 0 3px 3px rgb(0 0 0 / 15%), 0 -1px 1px rgb(0 0 0 / 1%);
  --sdds-nav-dropdown-item-border-radius: 0 0 4px 4px;
  --sdds-font-family-headline: "Scania Sans Headline", arial, helvetica, sans-serif
}

.sdds-nav,
.sdds-nav * {
  padding: 0;
  margin: 0;
  box-sizing: border-box
}

.sdds-nav {
  background-color: var(--sdds-header-background);
  height: 64px;
  width: 100%;
  z-index: 300;
  position: relative;
  display: flex;
  justify-content: space-between
}

.sdds-nav .sdds-nav__overlay {
  content: "";
  width: 100%;
  height: 100%;
  position: fixed;
  top: 64px;
  background: var(--sdds-header-overlay);
  opacity: 0;
  transition: opacity 0.1s ease-out;
  visibility: hidden
}

.sdds-nav.sdds-nav__mob-menu--opened .sdds-nav__overlay,
.sdds-nav.sdds-nav__app-launcher--opened .sdds-nav__overlay,
.sdds-nav.sdds-nav__app-searchbar--opened .sdds-nav__overlay {
  opacity: 0.4;
  visibility: visible
}

.sdds-nav .sdds-nav__inline-menu,
.sdds-nav .sdds-nav__toolbar-menu,
.sdds-nav .sdds-nav__searchbar {
  display: flex
}

.sdds-nav .sdds-nav__left,
.sdds-nav .sdds-nav__center,
.sdds-nav .sdds-nav__right {
  display: flex
}

.sdds-nav .sdds-nav__center {
  width: 100%;
  justify-content: space-between
}

.sdds-nav #sdds-nav__mob-menu-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  transform: rotate(0deg);
  transition: transform 500ms ease-in-out;
  cursor: pointer
}

.sdds-nav #sdds-nav__mob-menu-icon .sdds-nav__mob-menu-icon-line {
  display: block;
  position: absolute;
  height: 2px;
  width: 16px;
  background-color: currentcolor;
  border-radius: 2px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: transform 250ms ease-in-out;
  transform-origin: left center
}

.sdds-nav #sdds-nav__mob-menu-icon #sdds-nav__mob-menu-icon-line-1 {
  top: 0
}

.sdds-nav #sdds-nav__mob-menu-icon #sdds-nav__mob-menu-icon-line-2 {
  top: 6px
}

.sdds-nav #sdds-nav__mob-menu-icon #sdds-nav__mob-menu-icon-line-3 {
  top: 12px
}

.sdds-nav.sdds-nav__mob-menu--opened #sdds-nav__mob-menu-icon #sdds-nav__mob-menu-icon-line-1 {
  transform: rotate(45deg);
  top: 1px;
  left: 2px
}

.sdds-nav.sdds-nav__mob-menu--opened #sdds-nav__mob-menu-icon #sdds-nav__mob-menu-icon-line-2 {
  width: 0;
  opacity: 0
}

.sdds-nav.sdds-nav__mob-menu--opened #sdds-nav__mob-menu-icon #sdds-nav__mob-menu-icon-line-3 {
  transform: rotate(-45deg);
  top: 12px;
  left: 2px
}

.sdds-nav .sdds-nav__app-name {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--sdds-font-family-headline);
  font-weight: normal;
  color: var(--sdds-header-app-name-color);
  padding: 0 24px;
  white-space: nowrap
}

.sdds-nav .sdds-nav__app-logo {
  display: flex;
  cursor: pointer;
  background-color: var(--sdds-header-background);
  transition: background-color 150ms ease;
  display: block;
  cursor: pointer;
  background-image: var(--sdds-background-image-scania-symbol-svg), var(--sdds-background-image-scania-symbol-png);
  background-size: 30px auto;
  width: 64px;
  background-position: center;
  background-repeat: no-repeat
}

.sdds-nav .sdds-nav__app-logo:hover {
  background-color: var(--sdds-header-background-hover)
}

.sdds-nav .sdds-nav__app-logo:focus {
  outline: 2px solid var(--sdds-blue-400);
  outline-offset: -2px
}

.sdds-nav .sdds-nav__inline-menu,
.sdds-nav .sdds-nav__toolbar-menu {
  list-style-type: none
}

.sdds-nav .sdds-nav__item {
  position: relative;
  border-right: 1px solid var(--sdds-nav-item-border-color);
  border-top: none;
  border-bottom: none;
  border-left: none
}

.sdds-nav .sdds-nav__item:first-child {
  border-left: 1px solid var(--sdds-nav-item-border-color)
}

.sdds-nav .sdds-nav__item--active::after {
  content: "";
  border-bottom: 4px solid var(--sdds-nav-item-border-color-after);
  width: calc(100% + 2px);
  display: block;
  margin-left: -1px;
  bottom: 4px;
  position: relative
}

.sdds-nav .sdds-nav__mob-menu-btn {
  display: flex;
  cursor: pointer;
  background-color: var(--sdds-header-background);
  transition: background-color 150ms ease;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-right: 1px solid var(--sdds-nav-item-border-color);
  color: var(--sdds-header-mob-menu-color);
  display: none;
  width: 64px
}

.sdds-nav .sdds-nav__mob-menu-btn:hover {
  background-color: var(--sdds-header-background-hover)
}

.sdds-nav .sdds-nav__mob-menu-btn:focus {
  outline: 2px solid var(--sdds-blue-400);
  outline-offset: -2px
}

.sdds-nav.sdds-nav__mob-menu--opened .sdds-nav__mob-menu-btn {
  color: var(--sdds-header-mob-menu-open-color);
  background-color: var(--sdds-header-mob-menu-open-background);
  position: absolute;
  height: 64px;
  width: 64px;
  left: 209px;
  border-bottom: 1px solid var(--sdds-header-mob-menu-open-border)
}

@media all and (max-width: 608px) {
  .sdds-nav.sdds-nav__mob-menu--opened .sdds-nav__mob-menu-btn {
    left: auto;
    right: 0;
    border-right: none;
    z-index: 1
  }
}

.sdds-nav.sdds-nav__mob-menu--opened .sdds-nav__mob-menu-btn::before {
  content: " ";
  width: 209px;
  height: 64px;
  background-color: var(--sdds-header-mob-menu-open-background);
  position: absolute;
  display: block;
  left: 0;
  transform: translateX(-100%);
  pointer-events: none;
  border-bottom: 1px solid var(--sdds-header-mob-menu-open-border)
}

@media all and (max-width: 608px) {
  .sdds-nav.sdds-nav__mob-menu--opened .sdds-nav__mob-menu-btn::before {
    width: calc(100vw - 64px)
  }
}

.sdds-nav .sdds-nav__item-core,
.sdds-nav .sdds-nav__item-core:visited,
.sdds-nav .sdds-nav__item-core:active {
  display: flex;
  cursor: pointer;
  background-color: var(--sdds-header-background);
  transition: background-color 150ms ease;
  display: flex;
  justify-content: center;
  align-items: center;
  font: var(--sdds-headline-07);
  letter-spacing: var(--sdds-headline-07-ls);
  text-decoration: none;
  white-space: nowrap;
  color: var(--sdds-header-nav-item-color);
  padding: 0 24px;
  height: 100%;
  border: none
}

.sdds-nav .sdds-nav__item-core:hover,
.sdds-nav .sdds-nav__item-core:visited:hover,
.sdds-nav .sdds-nav__item-core:active:hover {
  background-color: var(--sdds-header-background-hover)
}

.sdds-nav .sdds-nav__item-core:focus,
.sdds-nav .sdds-nav__item-core:visited:focus,
.sdds-nav .sdds-nav__item-core:active:focus {
  outline: 2px solid var(--sdds-blue-400);
  outline-offset: -2px
}

.sdds-nav .sdds-nav__item-core:hover,
.sdds-nav .sdds-nav__item-core:visited:hover,
.sdds-nav .sdds-nav__item-core:active:hover {
  text-decoration: none
}

.sdds-nav .sdds-nav__item--active .sdds-nav__item-core {
  background-color: var(--sdds-header-nav-item-background-active)
}

.sdds-nav .sdds-nav__dropdown-icon {
  margin-left: var(--sdds-spacing-element-12);
  display: flex
}

.sdds-nav .sdds-nav__dropdown-icon .sdds-nav__dropdown-icon-svg {
  transition: transform 200ms ease-out;
  transform: rotate(0);
  width: 16px;
  height: auto
}

.sdds-nav .sdds-nav__dropdown--opened .sdds-nav__item-core {
  background-color: var(--sdds-header-nav-item-dropdown-opened-background);
  color: var(--sdds-header-nav-item-dropdown-opened-color)
}

.sdds-nav .sdds-nav__dropdown--opened .sdds-nav__item-core .sdds-nav__dropdown-icon .sdds-nav__dropdown-icon-svg {
  transform: rotate(180deg)
}

.sdds-nav .sdds-nav__dropdown--opened .sdds-nav__dropdown-menu {
  transform: scaleY(1);
  opacity: 1
}

.sdds-nav .sdds-nav__dropdown-menu {
  position: absolute;
  list-style-type: none;
  opacity: 0;
  transform: scaleY(0);
  transform-origin: top;
  transition: transform 150ms ease;
  box-shadow: var(--sdds-nav-dropdown-menu-box);
  border-radius: var(--sdds-nav-dropdown-item-border-radius)
}

.sdds-nav .sdds-nav__dropdown-item {
  width: 190px;
  height: 48px;
  background-color: var(--sdds-nav-dropdown-item-background);
  border-bottom: 1px solid var(--sdds-nav-dropdown-item-border-color);
  transition: background-color 150ms ease
}

.sdds-nav .sdds-nav__dropdown-item:hover {
  background-color: var(--sdds-nav-item-background-hover)
}

.sdds-nav .sdds-nav__dropdown-item:last-of-type,
.sdds-nav .sdds-nav__dropdown-item a {
  border-radius: var(--sdds-nav-dropdown-item-border-radius)
}

.sdds-nav .sdds-nav__dropdown-item--active {
  border-left: 4px solid var(--sdds-nav-item-border-color-active);
  background: var(--sdds-nav-item-background-active)
}

.sdds-nav .sdds-nav__dropdown-item--active .sdds-nav__dropdown-item-core {
  padding: 0 24px 0 20px;
  background-color: var(--sdds-nav-item-core-background-hover)
}

.sdds-nav .sdds-nav__dropdown-item-core,
.sdds-nav .sdds-nav__dropdown-item-core:visited,
.sdds-nav .sdds-nav__dropdown-item-core:active {
  font: var(--sdds-detail-02);
  letter-spacing: var(--sdds-detail-02-ls);
  background-color: inherit;
  height: 100%;
  width: 100%;
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 0 24px;
  color: var(--sdds-nav-item-core-color-active);
  border: none;
  cursor: pointer
}

.sdds-nav .sdds-nav__toolbar-menu .sdds-nav__item:last-child {
  border-right: none
}

.sdds-nav .sdds-nav__app-launcher-btn {
  display: flex;
  cursor: pointer;
  background-color: var(--sdds-header-background);
  transition: background-color 150ms ease;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 64px;
  border: none;
  color: var(--sdds-header-app-launcher-btn-color);
  transition: color 150ms ease
}

.sdds-nav .sdds-nav__app-launcher-btn:hover {
  background-color: var(--sdds-header-background-hover)
}

.sdds-nav .sdds-nav__app-launcher-btn:focus {
  outline: 2px solid var(--sdds-blue-400);
  outline-offset: -2px
}

.sdds-nav .sdds-nav__app-launcher-btn:hover {
  text-decoration: none
}

.sdds-nav .sdds-nav__app-launcher-btn .sdds-nav__app-launcher-btn-svg {
  width: 20px;
  height: auto;
  transition: fill 150ms ease
}

.sdds-nav .sdds-nav__searchbar-input-expanded {
  display: none
}

.sdds-nav .sdds-nav__searchbar-input {
  font: var(--sdds-detail-02);
  letter-spacing: var(--sdds-detail-02-ls);
  color: var(--sdds-header-searchbar-input-color);
  align-items: center;
  justify-content: flex-end;
  display: flex;
  position: relative;
  margin: 4px 2px;
  width: calc(100% - 64px);
  transition: transform 150ms ease;
  transform-origin: top;
  transform: scaleY(0);
  padding: 24px 14px 24px 23px;
  outline: none
}

.sdds-nav .sdds-nav__app-searchbar-container {
  width: 100%;
  display: flex;
  justify-content: flex-end
}

.sdds-nav .sdds-nav__app-searchbar {
  display: flex;
  width: 64px;
  border-left: 1px solid var(--sdds-header-searchbar-border-color);
  border-right: none
}

.sdds-nav .sdds-nav__app-searchbar-btn,
.sdds-nav .sdds-nav__app-searchbar-btn:active {
  display: flex;
  cursor: pointer;
  background-color: var(--sdds-header-background);
  transition: background-color 150ms ease;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 64px;
  border: none;
  color: var(--sdds-header-searchbar-btn-active);
  transition: color 150ms ease
}

.sdds-nav .sdds-nav__app-searchbar-btn:hover,
.sdds-nav .sdds-nav__app-searchbar-btn:active:hover {
  background-color: var(--sdds-header-background-hover)
}

.sdds-nav .sdds-nav__app-searchbar-btn:focus,
.sdds-nav .sdds-nav__app-searchbar-btn:active:focus {
  outline: 2px solid var(--sdds-blue-400);
  outline-offset: -2px
}

.sdds-nav .sdds-nav__app-searchbar-btn:hover,
.sdds-nav .sdds-nav__app-searchbar-btn:active:hover {
  text-decoration: none
}

.sdds-nav .sdds-nav__app-searchbar-btn .sdds-nav__app-searchbar-btn-svg,
.sdds-nav .sdds-nav__app-searchbar-btn:active .sdds-nav__app-searchbar-btn-svg {
  width: 20px;
  height: auto;
  transition: fill 150ms ease
}

.sdds-nav .sdds-nav__searchbar-menu {
  list-style-type: none;
  position: absolute;
  width: 100%;
  transition: transform 150ms ease;
  transform-origin: top;
  transform: scaleY(0)
}

.sdds-nav .sdds-nav__searchbar-results--category {
  background-color: var(--sdds-header-searchbar-result-category-background);
  border-bottom: 1px solid var(--sdds-header-searchbar-result-category-border-color);
  padding-bottom: 12px;
  height: 100%;
  cursor: pointer;
  transition: background-color 150ms ease
}

.sdds-nav .sdds-nav__searchbar-results--category:focus {
  outline: 2px solid var(--sdds-blue-400);
  outline-offset: -2px
}

.sdds-nav .sdds-nav__searchbar-results--category:last-child {
  border: none
}

.sdds-nav .sdds-nav__searchbar-results-item {
  height: 40px;
  padding: 12px;
  list-style: none;
  background-color: var(--sdds-header-searchbar-result-item-background);
  display: flex;
  align-items: center;
  justify-content: space-between
}

.sdds-nav .sdds-nav__searchbar-results-item:hover {
  background-color: var(--sdds-header-searchbar-result-item-background-hover)
}

.sdds-nav .sdds-nav__searchbar-results-item:active {
  border: 1px solid var(--sdds-header-searchbar-result-item-background-active)
}

.sdds-nav .sdds-nav__searchbar-results-item:visited {
  background-color: var(--sdds-header-searchbar-result-item-background-visited)
}

.sdds-nav .sdds-nav__searchbar-results-item:hover button,
.sdds-nav .sdds-nav__searchbar-results-item:hover a {
  background-color: var(--sdds-header-searchbar-result-item-anchor-background-hover);
  cursor: pointer
}

.sdds-nav .sdds-nav__searchbar-item--top {
  border-bottom: 1px solid var(--sdds-header-searchbar-result-item-top-border-color);
  background-color: var(--sdds-header-searchbar-result-item-top-background)
}

.sdds-nav .sdds-nav__searchbar-results-category-title {
  color: var(--sdds-header-searchbar-all-result-item-color);
  font: var(--sdds-detail-04);
  letter-spacing: var(--sdds-detail-04-ls);
  padding: 24px 12px 12px 24px;
  display: flex;
  align-items: center;
  width: 100%;
  background-color: var(--sdds-header-searchbar-result-item-category-title-background)
}

.sdds-nav .sdds-nav__app-searchbar-results-mgl-svg {
  margin-right: 24px;
  transform: translateY(4px)
}

.sdds-nav .sdds-nav__app-searchbar-results-x-btn {
  background-color: transparent;
  color: var(--sdds-header-searchbar-result-x-btn);
  border: none;
  margin: auto 14px
}

.sdds-nav .sdds-nav__searchbar-all-results {
  font: var(--sdds-detail-02);
  letter-spacing: var(--sdds-detail-02-ls);
  color: var(--sdds-header-searchbar-all-result-item-color);
  width: 100%;
  padding: 24px;
  top: 12px;
  left: 56px;
  height: auto;
  cursor: pointer
}

.sdds-nav .sdds-nav__searchbar-all-results:hover {
  background-color: var(--sdds-header-searchbar-all-result-item-background-hover)
}

.sdds-nav .sdds-nav__searchbar-all-results:active {
  border: 1px solid var(--sdds-header-searchbar-all-result-item-background-active)
}

.sdds-nav .sdds-nav__searchbar-all-results:visited {
  background-color: var(--sdds-header-searchbar-all-result-item-background-visited)
}

.sdds-nav .sdds-nav__searchbar-all-results .sdds-nav__app-searchbar-results-mgl-svg {
  vertical-align: unset
}

.sdds-nav .sdds-nav__searchbar-results-item-core {
  font: var(--sdds-detail-02);
  letter-spacing: var(--sdds-detail-02-ls);
  color: var(--sdds-header-searchbar-result-item-core-color);
  text-decoration: none;
  width: 100%;
  padding-left: 40px;
  background-color: var(--sdds-header-searchbar-result-item-core-background);
  cursor: pointer
}

.sdds-nav .sdds-nav__searchbar-results-item-core:hover {
  text-decoration: none
}

.sdds-nav.sdds-nav__searchbar--opened .sdds-nav__overlay {
  opacity: 0.4;
  visibility: visible
}

.sdds-nav.sdds-nav__searchbar--opened .sdds-nav__app-searchbar-mgl-btn {
  display: none
}

.sdds-nav.sdds-nav__searchbar--opened .sdds-nav__app-searchbar-x-btn {
  background-color: var(--sdds-header-searchbar-x-btn-background);
  width: 64px;
  position: relative
}

.sdds-nav.sdds-nav__searchbar--opened .sdds-nav__app-searchbar-x-btn:hover {
  background-color: var(--sdds-header-searchbar-x-btn-background-hover)
}

.sdds-nav.sdds-nav__searchbar--opened .sdds-nav__app-searchbar-x-btn .sdds-nav__app-searchbar-btn-svg {
  color: var(--sdds-header-searchbar-btn-svg-fill)
}

.sdds-nav.sdds-nav__searchbar--opened .sdds-nav__app-searchbar-x-btn .sdds-nav__app-searchbar-btn-svg path {
  fill: var(--sdds-header-searchbar-btn-svg-fill)
}

.sdds-nav.sdds-nav__searchbar--opened .sdds-nav__app-searchbar {
  width: 100%;
  border: none
}

.sdds-nav.sdds-nav__searchbar--opened .sdds-nav__searchbar-input {
  display: flex;
  background-color: var(--sdds-header-searchbar-input-background);
  transform: scaleY(1);
  margin: 0;
  border: none
}

.sdds-nav.sdds-nav__searchbar--opened .sdds-nav__searchbar-input::placeholder {
  color: var(--sdds-header-searchbar-input-placeholder-color)
}

.sdds-nav.sdds-nav__searchbar--opened .sdds-nav__searchbar-input-expanded {
  display: flex;
  width: 100%;
  position: relative
}

.sdds-nav.sdds-nav__searchbar--opened .sdds-nav__searchbar-input-expanded::after {
  content: " ";
  border-bottom: 1px solid var(--sdds-header-searchbar-input-expanded-after-border-bottom);
  position: absolute;
  display: block;
  left: 0;
  right: 0;
  bottom: 0
}

.sdds-nav.sdds-nav__searchbar--opened .sdds-nav__searchbar-menu {
  transform: scaleY(1);
  top: 100%
}

.sdds-nav .sdds-nav__avatar-btn,
.sdds-nav .sdds-nav__avatar-btn:active {
  display: flex;
  cursor: pointer;
  background-color: var(--sdds-header-background);
  transition: background-color 150ms ease;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 64px;
  border: none
}

.sdds-nav .sdds-nav__avatar-btn:hover,
.sdds-nav .sdds-nav__avatar-btn:active:hover {
  background-color: var(--sdds-header-background-hover)
}

.sdds-nav .sdds-nav__avatar-btn:focus,
.sdds-nav .sdds-nav__avatar-btn:active:focus {
  outline: 2px solid var(--sdds-blue-400);
  outline-offset: -2px
}

.sdds-nav .sdds-nav__avatar-btn .sdds-nav__avatar-img,
.sdds-nav .sdds-nav__avatar-btn:active .sdds-nav__avatar-img {
  width: 34px;
  height: 34px;
  border-radius: 50%
}

.sdds-nav .sdds-nav__avatar-menu {
  list-style-type: none;
  position: absolute;
  right: 0;
  transition: transform 150ms ease;
  transform-origin: top;
  transform: scaleY(0);
  opacity: 0
}

.sdds-nav .sdds-nav__avatar-item {
  width: 320px;
  height: 64px;
  background-color: var(--sdds-header-avatar-item-background);
  border-bottom: 1px solid var(--sdds-header-avatar-item-border-color);
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15)
}

.sdds-nav .sdds-nav__avatar-item:last-child {
  border-radius: var(--sdds-nav-dropdown-item-border-radius)
}

.sdds-nav .sdds-nav__avatar-item:last-child .sdds-nav__avatar-item-core {
  border-radius: var(--sdds-nav-dropdown-item-border-radius)
}

.sdds-nav .sdds-nav__avatar-item--large {
  height: 84px
}

.sdds-nav .sdds-nav__avatar-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 0 16px
}

.sdds-nav .sdds-nav__avatar-info--mobile {
  display: none
}

.sdds-nav .sdds-nav__avatar-title,
.sdds-nav .sdds-nav__avatar-subtitle {
  font: var(--sdds-headline-07);
  letter-spacing: var(--sdds-headline-07-ls);
  width: 100%
}

.sdds-nav .sdds-nav__avatar-title {
  color: var(--sdds-header-avatar-title-color)
}

.sdds-nav .sdds-nav__avatar-subtitle {
  padding-top: 4px;
  color: var(--sdds-header-avatar-subtitle-color)
}

.sdds-nav .sdds-nav__avatar-item-core,
.sdds-nav .sdds-nav__avatar-item-core:visited,
.sdds-nav .sdds-nav__avatar-item-core:active {
  font: var(--sdds-detail-02);
  letter-spacing: var(--sdds-detail-02-ls);
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 0 16px;
  height: 100%;
  width: 100%;
  background-color: var(--sdds-header-avatar-item-background);
  color: var(--sdds-header-avatar-item-color);
  transition: background-color 150ms ease;
  border: none;
  cursor: pointer
}

.sdds-nav .sdds-nav__avatar-item-core:hover,
.sdds-nav .sdds-nav__avatar-item-core:visited:hover,
.sdds-nav .sdds-nav__avatar-item-core:active:hover {
  text-decoration: none;
  background-color: var(--sdds-header-avatar-item-background-hover)
}

.sdds-nav.sdds-nav__avatar--opened .sdds-nav__avatar-btn {
  background-color: var(--sdds-header-avatar-item-btn-background)
}

.sdds-nav.sdds-nav__avatar--opened .sdds-nav__avatar-menu {
  transform: scaleY(1);
  opacity: 1
}

.sdds-nav .sdds-nav__app-launcher-menu {
  overflow-y: auto;
  list-style-type: none;
  background-color: var(--sdds-header-app-launcher-menu-background);
  position: absolute;
  right: 0;
  height: calc(100vh - 64px);
  padding-bottom: 32px;
  transform: scaleY(0);
  opacity: 0;
  transform-origin: top;
  transition: transform 150ms ease
}

.sdds-nav .sdds-nav__app-launcher-menu::-webkit-scrollbar {
  width: 4px;
  background-color: inherit
}

.sdds-nav .sdds-nav__app-launcher-menu::-webkit-scrollbar-thumb {
  background-color: var(--sdds-grey-300)
}

.sdds-nav .sdds-nav__app-launcher-menu ::-webkit-scrollbar-button {
  height: 0;
  width: 0
}

.sdds-nav.sdds-nav__app-launcher--opened .sdds-nav__app-launcher-btn {
  background-color: var(--sdds-header-app-launcher-btn-background);
  color: var(--sdds-header-app-launcher-open-btn-color)
}

.sdds-nav.sdds-nav__app-launcher--opened .sdds-nav__app-launcher-menu {
  transform: scaleY(1);
  opacity: 1
}

.sdds-nav .sdds-nav__app-launcher-item {
  width: 320px;
  height: 64px;
  border-bottom: 1px solid var(--sdds-header-app-launcher-item-border-color)
}

.sdds-nav .sdds-nav__app-launcher-item--category {
  height: 48px
}

.sdds-nav .sdds-nav__app-launcher-category-title {
  font: var(--sdds-detail-06);
  letter-spacing: var(--sdds-detail-06-ls);
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 0 16px;
  background-color: var(--sdds-header-app-launcher-category-title-background);
  color: var(--sdds-header-app-launcher-category-title-color)
}

.sdds-nav .sdds-nav__app-launcher-item-core,
.sdds-nav .sdds-nav__app-launcher-item-core:visited,
.sdds-nav .sdds-nav__app-launcher-item-core:active {
  font: var(--sdds-detail-02);
  letter-spacing: var(--sdds-detail-02-ls);
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 0 16px;
  height: 100%;
  width: 100%;
  background-color: var(--sdds-header-app-launcher-item-background);
  color: var(--sdds-header-app-launcher-item-color);
  transition: background-color 150ms ease;
  border: none;
  cursor: pointer
}

.sdds-nav .sdds-nav__app-launcher-item-core:hover,
.sdds-nav .sdds-nav__app-launcher-item-core:visited:hover,
.sdds-nav .sdds-nav__app-launcher-item-core:active:hover {
  text-decoration: none;
  background-color: var(--sdds-header-app-launcher-item-background-hover)
}

@media all and (max-width: 992px) {
  .sdds-nav {
    justify-content: space-between
  }

  .sdds-nav .sdds-nav__app-searchbar-container {
    position: absolute;
    top: 0;
    height: 68px;
    border-bottom: 1px solid var(--sdds-header-mobile-searchbar-container-border-color)
  }

  .sdds-nav .sdds-nav__app-searchbar {
    width: 100%
  }

  .sdds-nav .sdds-nav__searchbar-item--top span {
    display: none
  }

  .sdds-nav .sdds-nav__searchbar-results-item-core {
    padding-left: 28px
  }

  .sdds-nav .sdds-nav__app-searchbar-results-mgl-svg {
    margin-right: 16px;
    transform: translateY(4px);
    margin-left: 17px
  }

  .sdds-nav .sdds-nav__searchbar-results-item {
    height: auto;
    align-items: flex-start
  }

  .sdds-nav .sdds-nav__searchbar-results-item .sdds-nav__app-searchbar-results-x-btn {
    margin-top: 0
  }

  .sdds-nav .sdds-nav__app-searchbar-btn {
    width: 100%
  }

  .sdds-nav .sdds-nav__app-searchbar-btn::before {
    font: var(--sdds-headline-07);
    letter-spacing: var(--sdds-headline-07-ls);
    content: "Search";
    position: absolute;
    top: 50%;
    left: 24px;
    transform: translateY(-50%);
    color: var(--sdds-header-mobile-searchbar-container-color)
  }

  .sdds-nav .sdds-nav__app-searchbar-btn .sdds-nav__app-searchbar-btn-svg {
    display: none
  }

  .sdds-nav .sdds-nav__app-searchbar-btn:active {
    width: 100%
  }

  .sdds-nav.sdds-nav__searchbar--opened .sdds-nav__app-searchbar-btn::before {
    display: none
  }

  .sdds-nav.sdds-nav__searchbar--opened .sdds-nav__app-searchbar-btn .sdds-nav__app-searchbar-btn-svg {
    display: block
  }

  .sdds-nav.sdds-nav__searchbar--opened .sdds-nav__app-searchbar-container {
    height: 65px
  }

  .sdds-nav.sdds-nav__searchbar--opened .sdds-nav__center {
    margin-top: -64px;
    height: 100vh
  }

  .sdds-nav.sdds-nav__searchbar--opened .sdds-nav__searchbar-input-expanded {
    position: relative;
    z-index: 1
  }

  .sdds-nav.sdds-nav__searchbar--opened .sdds-nav__searchbar-input-expanded::before {
    content: " ";
    height: 500px;
    background-color: var(--sdds-header-mobile-searchbar-input-expanded-before-background);
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    min-height: 100vh
  }

  .sdds-nav.sdds-nav__searchbar--opened .sdds-nav__searchbar-input {
    background-color: var(--sdds-header-mobile-searchbar-input-background);
    color: var(--sdds-header-mobile-searchbar-input-color)
  }

  .sdds-nav.sdds-nav__searchbar--opened .sdds-nav__searchbar-input::placeholder {
    color: var(--sdds-header-mobile-searchbar-input-placeholder-color)
  }

  .sdds-nav.sdds-nav__searchbar--opened .sdds-nav__app-searchbar-btn {
    background-color: var(--sdds-header-mobile-searchbar-open-btn-background)
  }

  .sdds-nav.sdds-nav__searchbar--opened .sdds-nav__app-searchbar-btn:active {
    width: 64px
  }

  .sdds-nav.sdds-nav__searchbar--opened .sdds-nav__app-searchbar-btn:hover {
    background-color: var(--sdds-header-mobile-searchbar-open-btn-background-hover)
  }

  .sdds-nav.sdds-nav__searchbar--opened .sdds-nav__app-searchbar-btn .sdds-nav__app-searchbar-btn-svg path {
    fill: var(--sdds-header-mobile-searchbar-btn-svg-fill)
  }

  .sdds-nav .sdds-nav__app-searchbar-btn:hover {
    background-color: var(--sdds-header-mobile-searchbar-btn-background-hover)
  }

  .sdds-nav .sdds-nav__app-searchbar {
    border-left: none
  }

  .sdds-nav .sdds-nav__mob-menu-btn {
    display: flex
  }

  .sdds-nav .sdds-nav__app-searchbar-btn {
    background-color: var(--sdds-header-mobile-searchbar-btn-background);
    border-left: none
  }

  .sdds-nav .sdds-nav__center {
    overflow-y: auto;
    position: absolute;
    top: 64px;
    width: 272px;
    height: calc(100vh - 64px);
    flex-direction: column;
    background-color: var(--sdds-header-mobile-nav-center-background);
    transform: scaleY(0);
    transform-origin: top;
    transition: transform 250ms ease
  }

  .sdds-nav .sdds-nav__center::-webkit-scrollbar {
    width: 4px;
    background-color: inherit
  }

  .sdds-nav .sdds-nav__center::-webkit-scrollbar-thumb {
    background-color: var(--sdds-grey-300)
  }

  .sdds-nav .sdds-nav__center ::-webkit-scrollbar-button {
    height: 0;
    width: 0
  }

  .sdds-nav .sdds-nav__center.sdds-nav__center-withsearch .sdds-nav__inline-menu {
    margin-top: 68px
  }

  .sdds-nav .sdds-nav__center .sdds-nav__inline-menu,
  .sdds-nav .sdds-nav__center .sdds-nav__toolbar-menu {
    flex-direction: column
  }

  .sdds-nav .sdds-nav__center .sdds-nav__item,
  .sdds-nav .sdds-nav__center .sdds-nav__item-core,
  .sdds-nav .sdds-nav__center .sdds-nav__avatar-btn {
    width: 100%;
    justify-content: flex-start
  }

  .sdds-nav .sdds-nav__center .sdds-nav__avatar-btn {
    border-bottom: 1px solid var(--sdds-header-mobile-nav-center-item-border-color)
  }

  .sdds-nav .sdds-nav__center .sdds-nav__avatar-item {
    border-bottom: 1px solid var(--sdds-header-mobile-nav-center-item-border-color)
  }

  .sdds-nav .sdds-nav__center .sdds-nav__avatar-item .sdds-nav__avatar-item-core {
    background-color: var(--sdds-header-mobile-nav-center-background)
  }

  .sdds-nav .sdds-nav__center .sdds-nav__avatar-item .sdds-nav__avatar-item-core:hover {
    background-color: var(--sdds-header-mobile-avatar--item-btn-background-hover)
  }

  .sdds-nav .sdds-nav__center .sdds-nav__item {
    border-left: none;
    border-right: none;
    height: 68px
  }

  .sdds-nav .sdds-nav__center .sdds-nav__item-core {
    border-bottom: 1px solid var(--sdds-header-mobile-nav-center-item-border-color)
  }

  .sdds-nav .sdds-nav__center .sdds-nav__toolbar-menu {
    margin-top: -1px;
    border-top: 1px solid var(--sdds-header-mobile-nav-toolbar-border-color)
  }

  .sdds-nav .sdds-nav__center .sdds-nav__item--active::after {
    content: "";
    border-bottom: none;
    border-right: 4px solid var(--sdds-header-mobile-nav-center-item-border-color-active-after);
    width: 0;
    height: 100%;
    display: block;
    margin-left: 0;
    top: 0;
    left: 0;
    position: absolute
  }

  .sdds-nav .sdds-nav__center .sdds-nav__item-core,
  .sdds-nav .sdds-nav__center .sdds-nav__avatar-btn {
    background-color: var(--sdds-header-mobile-avatar--item-btn-background);
    color: var(--sdds-header-mobile-avatar--iten-btn-border-color)
  }

  .sdds-nav .sdds-nav__center .sdds-nav__item-core:hover,
  .sdds-nav .sdds-nav__center .sdds-nav__avatar-btn:hover {
    background-color: var(--sdds-header-mobile-avatar--item-btn-background-hover)
  }

  .sdds-nav .sdds-nav__center .sdds-nav__dropdown .sdds-nav__item-core {
    height: 68px
  }

  .sdds-nav .sdds-nav__center .sdds-nav__dropdown .sdds-nav__dropdown-menu {
    display: block;
    position: relative
  }

  .sdds-nav .sdds-nav__center .sdds-nav__dropdown .sdds-nav__dropdown-menu .sdds-nav__dropdown-item {
    box-shadow: none;
    border-bottom: none;
    width: 100%
  }

  .sdds-nav .sdds-nav__center .sdds-nav__dropdown .sdds-nav__dropdown-menu .sdds-nav__dropdown-item:last-of-type {
    border-radius: 0;
    border-bottom: 1px solid var(--sdds-header-mobile-nav-center-dropdown-item--last-border-color)
  }

  .sdds-nav .sdds-nav__center .sdds-nav__dropdown .sdds-nav__dropdown-icon {
    margin: auto 0 auto auto
  }

  .sdds-nav .sdds-nav__center .sdds-nav__dropdown--opened {
    height: fit-content
  }

  .sdds-nav .sdds-nav__center .sdds-nav__dropdown--opened .sdds-nav__item-core {
    border-bottom: none
  }

  .sdds-nav .sdds-nav__center .sdds-nav__avatar {
    height: auto
  }

  .sdds-nav .sdds-nav__center .sdds-nav__avatar .sdds-nav__avatar-menu,
  .sdds-nav .sdds-nav__center .sdds-nav__avatar .sdds-nav__avatar-item {
    width: 100%
  }

  .sdds-nav .sdds-nav__center .sdds-nav__avatar .sdds-nav__avatar-menu {
    height: auto;
    position: relative;
    transform: scaleY(1);
    opacity: 1
  }

  .sdds-nav .sdds-nav__center .sdds-nav__avatar .sdds-nav__avatar-btn {
    height: 68px;
    pointer-events: none
  }

  .sdds-nav .sdds-nav__center .sdds-nav__avatar .sdds-nav__avatar-item {
    box-shadow: none;
    display: flex
  }

  .sdds-nav .sdds-nav__center .sdds-nav__avatar .sdds-nav__avatar-item:last-child {
    border-radius: 0
  }

  .sdds-nav .sdds-nav__center .sdds-nav__avatar .sdds-nav__avatar-item:last-child .sdds-nav__avatar-item-core {
    border-radius: 0
  }

  .sdds-nav .sdds-nav__center .sdds-nav__avatar .sdds-nav__avatar-item--large {
    display: none
  }

  .sdds-nav .sdds-nav__center .sdds-nav__avatar .sdds-nav__avatar-info--mobile {
    display: flex;
    text-align: left
  }

  .sdds-nav .sdds-nav__center .sdds-nav__avatar .sdds-nav__avatar-btn {
    padding: 0 16px
  }

  .sdds-nav.sdds-nav__mob-menu--opened .sdds-nav__center {
    transform: scaleY(1);
    justify-content: space-between
  }

  .sdds-nav .sdds-nav__app-launcher {
    border-right: none
  }

  .sdds-nav .sdds-nav__app-logo {
    display: none
  }
}

@media all and (max-width: 608px) {
  .sdds-nav .sdds-nav__center {
    width: 100%
  }
}

:root,
.sdds-mode-light {
  --sdds-link: var(--sdds-blue-500);
  --sdds-link-hover: var(--sdds-blue-400);
  --sdds-link-focus: var(--sdds-blue-400);
  --sdds-link-visited: var(--sdds-grey-900);
  --sdds-link-disabled: var(--sdds-grey-400)
}

.sdds-mode-dark {
  --sdds-link: var(--sdds-blue-300);
  --sdds-link-hover: var(--sdds-blue-400);
  --sdds-link-focus: var(--sdds-blue-400);
  --sdds-link-visited: var(--sdds-blue-400);
  --sdds-link-disabled: var(--sdds-grey-800)
}

.sdds-link {
  box-sizing: border-box;
  cursor: pointer;
  outline: none
}

.sdds-link * {
  box-sizing: border-box
}

.sdds-link,
.sdds-link.active,
.sdds-link:active {
  color: var(--sdds-link);
  text-decoration: underline;
  text-decoration-color: var(--sdds-link)
}

.sdds-link:hover {
  color: var(--sdds-link-hover);
  text-decoration: underline;
  text-decoration-color: var(--sdds-link-hover)
}

.sdds-link:visited {
  color: var(--sdds-link-visited);
  text-decoration-color: var(--sdds-link-visited)
}

.sdds-link:focus {
  color: var(--sdds-link-focus);
  text-decoration: none;
  outline: 2px solid var(--sdds-link-focus);
  outline-offset: -2px
}

.sdds-link:disabled,
.sdds-link.disabled {
  color: var(--sdds-link-disabled);
  text-decoration-color: var(--sdds-link-disabled);
  pointer-events: none
}

.sdds-link--no-underline {
  text-decoration: none
}

.sdds-link--no-underline:hover {
  text-decoration: none
}

html,
:root {
  --sdds-message-color: var(--sdds-grey-958);
  --sdds-message-background-primary: var(--sdds-grey-50);
  --sdds-message-background-secondary: var(--sdds-white);
  --sdds-message-background: var(--sdds-message-background-primary);
  --sdds-message-type-error-background: var(--sdds-red-50)
}

html .sdds-mode-light,
:root .sdds-mode-light {
  --sdds-message-color: var(--sdds-grey-958);
  --sdds-message-background-primary: var(--sdds-grey-50);
  --sdds-message-background-secondary: var(--sdds-white);
  --sdds-message-background: var(--sdds-message-background-primary);
  --sdds-message-type-error-background: var(--sdds-red-50)
}

html .sdds-mode-light .sdds-mode-variant-primary,
:root .sdds-mode-light .sdds-mode-variant-primary {
  --sdds-message-background: var(--sdds-message-background-primary)
}

html .sdds-mode-light .sdds-mode-variant-secondary,
:root .sdds-mode-light .sdds-mode-variant-secondary {
  --sdds-message-background: var(--sdds-message-background-secondary)
}

html .sdds-mode-dark,
:root .sdds-mode-dark {
  --sdds-message-color: var(--sdds-grey-50);
  --sdds-message-background-primary: var(--sdds-grey-900);
  --sdds-message-background-secondary: var(--sdds-grey-868);
  --sdds-message-background: var(--sdds-message-background-primary);
  --sdds-message-type-error-background: rgb(255 35 64 / 24%)
}

html .sdds-mode-variant-primary,
:root .sdds-mode-variant-primary {
  --sdds-message-background: var(--sdds-message-background-primary)
}

html .sdds-mode-variant-secondary,
:root .sdds-mode-variant-secondary {
  --sdds-message-background: var(--sdds-message-background-secondary)
}

.sdds-message {
  box-sizing: border-box;
  display: block;
  padding: 16px;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  color: var(--sdds-grey-958);
  background-color: var(--sdds-message-background);
}

.sdds-message * {
  box-sizing: border-box
}

.sdds-message-single {
  font: var(--sdds-headline-07);
  letter-spacing: var(--sdds-headline-07-ls);
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  max-width: 486px
}

.sdds-message-extended {
  font: var(--sdds-detail-02);
  letter-spacing: var(--sdds-detail-02-ls);
  margin-top: 4px !important;
  margin-bottom: 0 !important;
  max-width: 486px
}

.sdds-message::before {
  content: " ";
  display: block;
  position: absolute;
  width: 4px;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: var(--sdds-information)
}

.sdds-message-icon {
  height: 20px;
  width: 20px;
  font-size: 20px;
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%)
}

.sdds-message-icon path {
  fill: var(--sdds-information)
}

.sdds-message-icon-active {
  padding: 18px
}

.sdds-message-icon-active .sdds-message-single,
.sdds-message-icon-active .sdds-message-extended {
  padding-left: 36px
}

.sdds-message-extended-active .sdds-message-icon {
  top: 16px;
  left: 20px;
  transform: translateY(0%)
}

.sdds-message-minimal {
  background-color: transparent !important
}

.sdds-message-minimal::before {
  display: none
}

.sdds-message-minimal .sdds-message-single {
  font: var(--sdds-detail-02);
  letter-spacing: var(--sdds-detail-02-ls);
  font-weight: normal;
  padding-left: 8px
}

.sdds-message-minimal .sdds-message-icon {
  left: 0;
  top: 50%;
  transform: translateY(-50%)
}

.sdds-message-type-informative {
  color: var(--sdds-message-color);
  background-color: var(--sdds-message-background)
}

.sdds-message-type-informative::before {
  background-color: var(--sdds-information)
}

.sdds-message-type-informative .sdds-message-icon {
  display: none
}

.sdds-message-type-informative .sdds-message-icon-informative {
  display: flex;
  color: var(--sdds-information)
}

.sdds-message-type-informative .sdds-message-icon path {
  fill: var(--sdds-information)
}

.sdds-message-type-error {
  color: var(--sdds-message-color);
  background-color: var(--sdds-message-type-error-background)
}

.sdds-message-type-error::before {
  background-color: var(--sdds-negative)
}

.sdds-message-type-error .sdds-message-icon {
  display: none
}

.sdds-message-type-error .sdds-message-icon-error {
  display: flex;
  color: var(--sdds-negative)
}

.sdds-message-type-error .sdds-message-icon path {
  fill: var(--sdds-negative)
}

.sdds-message-type-warning {
  color: var(--sdds-message-color);
  background-color: var(--sdds-message-background)
}

.sdds-message-type-warning::before {
  background-color: var(--sdds-warning)
}

.sdds-message-type-warning .sdds-message-icon {
  display: none
}

.sdds-message-type-warning .sdds-message-icon-warning {
  display: flex;
  color: var(--sdds-warning)
}

.sdds-message-type-warning .sdds-message-icon path {
  fill: var(--sdds-warning)
}

.sdds-message-type-success {
  color: var(--sdds-message-color);
  background-color: var(--sdds-message-background)
}

.sdds-message-type-success::before {
  background-color: var(--sdds-positive)
}

.sdds-message-type-success .sdds-message-icon {
  display: none
}

.sdds-message-type-success .sdds-message-icon-success {
  display: flex;
  color: var(--sdds-positive)
}

.sdds-message-type-success .sdds-message-icon path {
  fill: var(--sdds-positive)
}

:root,
.sdds-mode-light {
  --sdds-radio-button-color: var(--sdds-grey-958);
  --sdds-radio-button-interaction-01: var(--sdds-blue-800);
  --sdds-radio-button-interaction-02: var(--sdds-white);
  --sdds-radio-button-background-hover: var(--sdds-blue-800);
  --sdds-radio-button-background-focus: var(--sdds-blue-800);
  --sdds-radio-button-disabled: var(--sdds-grey-600);
  --sdds-radio-button-border-color-disabled-after: var(--sdds-grey-700);
  --sdds-radio-button-color-disabled-after: var(--sdds-grey-700);
  --sdds-radio-button-color-disabled-before: var(--sdds-grey-700)
}

.sdds-mode-dark {
  --sdds-radio-button-color: var(--sdds-white);
  --sdds-radio-button-interaction-01: var(--sdds-white);
  --sdds-radio-button-interaction-02: var(--sdds-grey-958);
  --sdds-radio-button-background-hover: var(--sdds-white);
  --sdds-radio-button-background-focus: var(--sdds-white);
  --sdds-radio-button-disabled: var(--sdds-grey-600);
  --sdds-radio-button-border-color-disabled-after: var(--sdds-grey-700);
  --sdds-radio-button-color-disabled-after: var(--sdds-grey-700)
}

.sdds-radio-button-group {
  box-sizing: border-box;
  font: var(--sdds-detail-02);
  letter-spacing: var(--sdds-detail-02-ls);
  color: var(--sdds-radio-button-color)
}

.sdds-radio-button-group * {
  box-sizing: border-box
}

.sdds-radio-button-group .sdds-radio-item {
  display: flex;
  align-items: center;
  margin-left: -4px
}

.sdds-radio-button-group .sdds-form-input[type=radio]:checked,
.sdds-radio-button-group .sdds-form-input[type=radio]:not(:checked) {
  appearance: none;
  outline: none;
  margin: 0;
  border: 0;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  position: relative;
  cursor: pointer
}

.sdds-radio-button-group .sdds-form-input[type=radio]:checked+label,
.sdds-radio-button-group .sdds-form-input[type=radio]:not(:checked)+label {
  padding-left: var(--sdds-spacing-element-4);
  padding-top: var(--sdds-spacing-element-4);
  padding-bottom: var(--sdds-spacing-element-4);
  cursor: pointer
}

.sdds-radio-button-group .sdds-form-input[type=radio]:checked::before,
.sdds-radio-button-group .sdds-form-input[type=radio]:checked::after,
.sdds-radio-button-group .sdds-form-input[type=radio]:not(:checked)::before,
.sdds-radio-button-group .sdds-form-input[type=radio]:not(:checked)::after {
  content: "";
  position: absolute;
  border-radius: 50%;
  box-sizing: border-box
}

.sdds-radio-button-group .sdds-form-input[type=radio]:checked::before,
.sdds-radio-button-group .sdds-form-input[type=radio]:not(:checked)::before {
  width: 24px;
  height: 24px;
  left: 0;
  top: 0
}

.sdds-radio-button-group .sdds-form-input[type=radio]:checked::after,
.sdds-radio-button-group .sdds-form-input[type=radio]:not(:checked)::after {
  border: 1px solid var(--sdds-radio-button-interaction-01);
  background-color: var(--sdds-radio-button-interaction-02);
  width: 16px;
  height: 16px;
  left: 4px;
  top: 4px
}

.sdds-radio-button-group .sdds-form-input[type=radio]:checked:hover::before,
.sdds-radio-button-group .sdds-form-input[type=radio]:not(:checked):hover::before {
  background-color: var(--sdds-radio-button-background-hover);
  opacity: 0.12
}

.sdds-radio-button-group .sdds-form-input[type=radio]:checked:focus::before,
.sdds-radio-button-group .sdds-form-input[type=radio]:not(:checked):focus::before {
  background-color: var(--sdds-radio-button-background-focus);
  opacity: 0.24;
  animation: rb-focus 0.4s cubic-bezier(0.65, 0.05, 0.38, 0.95) forwards
}

.sdds-radio-button-group .sdds-form-input[type=radio]:checked:disabled,
.sdds-radio-button-group .sdds-form-input[type=radio]:checked.disabled,
.sdds-radio-button-group .sdds-form-input[type=radio]:not(:checked):disabled,
.sdds-radio-button-group .sdds-form-input[type=radio]:not(:checked).disabled {
  cursor: not-allowed
}

.sdds-radio-button-group .sdds-form-input[type=radio]:checked:disabled::after,
.sdds-radio-button-group .sdds-form-input[type=radio]:checked.disabled::after,
.sdds-radio-button-group .sdds-form-input[type=radio]:not(:checked):disabled::after,
.sdds-radio-button-group .sdds-form-input[type=radio]:not(:checked).disabled::after {
  border-color: var(--sdds-radio-button-border-color-disabled-after);
  background-color: var(--sdds-radio-button-interaction-02)
}

.sdds-radio-button-group .sdds-form-input[type=radio]:checked:disabled+label,
.sdds-radio-button-group .sdds-form-input[type=radio]:checked.disabled+label,
.sdds-radio-button-group .sdds-form-input[type=radio]:not(:checked):disabled+label,
.sdds-radio-button-group .sdds-form-input[type=radio]:not(:checked).disabled+label {
  color: var(--sdds-grey-600);
  cursor: not-allowed
}

.sdds-radio-button-group .sdds-form-input[type=radio]:checked:disabled:hover::before,
.sdds-radio-button-group .sdds-form-input[type=radio]:checked.disabled:hover::before,
.sdds-radio-button-group .sdds-form-input[type=radio]:not(:checked):disabled:hover::before,
.sdds-radio-button-group .sdds-form-input[type=radio]:not(:checked).disabled:hover::before {
  display: none;
  opacity: 1
}

.sdds-radio-button-group .sdds-form-input[type=radio]:checked::after {
  border: 4px solid var(--sdds-radio-button-interaction-01)
}

.sdds-radio-button-group .sdds-form-input[type=radio]:checked:disabled:hover::before,
.sdds-radio-button-group .sdds-form-input[type=radio]:checked:disabled::before,
.sdds-radio-button-group .sdds-form-input[type=radio]:checked.disabled:hover::before,
.sdds-radio-button-group .sdds-form-input[type=radio]:checked.disabled::before {
  display: block;
  width: 16px;
  height: 16px;
  left: 4px;
  top: 4px;
  border: 1px solid var(--sdds-radio-button-color-disabled-before);
  box-sizing: content-box
}

.sdds-radio-button-group .sdds-form-input[type=radio]:checked:disabled::after,
.sdds-radio-button-group .sdds-form-input[type=radio]:checked.disabled::after {
  border: 4px solid var(--sdds-radio-button-interaction-02);
  background-color: var(--sdds-radio-button-color-disabled-after);
  left: 5px;
  top: 5px
}

@keyframes rb-focus {
  0% {
    transform: scale(0);
    opacity: 0
  }

  50% {
    transform: scale(1);
    opacity: 0.24
  }

  100% {
    transform: scale(1);
    opacity: 0
  }
}

:root,
.sdds-mode-light {
  --sdds-sidebar-mobile-menu-btn-hover: var(--sdds-blue-700);
  --sdds-sidebar-mobile-menu-btn-icon: var(--sdds-white);
  --sdds-sidebar-item-state-focus: var(--sdds-grey-50);
  --sdds-sidebar-item-state-border-focus: var(--sdds-blue-400);
  --sdds-sidebar-item-state-hover: var(--sdds-grey-50);
  --sdds-sidebar-item-state-active: var(--sdds-grey-300);
  --sdds-sidebar-side-menu: var(--sdds-white);
  --sdds-sidebar-side-menu-border-right: var(--sdds-grey-300);
  --sdds-sidebar-side-menu-backgrund-cover: var(--sdds-black);
  --sdds-sidebar-side-menu-mobile-header-border-bottom: var(--sdds-grey-300);
  --sdds-sidebar-side-menu-bottom-menu-background: var(--sdds-white);
  --sdds-sidebar-side-menu-bottom-menu-border-top: var(--sdds-grey-300);
  --sdds-sidebar-side-menu-single-item-border-bottom: var(--sdds-grey-300);
  --sdds-sidebar-side-menu-single-item-color: var(--sdds-grey-958);
  --sdds-sidebar-side-menu-single-item-background-active: var(--sdds-grey-50);
  --sdds-sidebar-side-menu-single-item-border-left-active: var(--sdds-blue-400);
  --sdds-sidebar-side-menu-single-subitem-color: var(--sdds-grey-800);
  --sdds-sidebar-side-menu-single-subitem-title-color: var(--sdds-grey-958);
  --sdds-sidebar-side-menu-single-subitem-color-active: var(--sdds-grey-958);
  --sdds-sidebar-side-menu-single-subitem-backgrund: var(--sdds-white);
  --sdds-sidebar-side-menu-single-subitem-divider: var(--sdds-grey-300);
  --sdds-sidebar-side-menu-subnav-backgrund: var(--sdds-white)
}

.sdds-mode-dark {
  --sdds-sidebar-mobile-menu-btn-hover: var(--sdds-blue-700);
  --sdds-sidebar-mobile-menu-btn-icon: var(--sdds-white);
  --sdds-sidebar-item-state-focus: var(--sdds-grey-958);
  --sdds-sidebar-item-state-border-focus: var(--sdds-blue-400);
  --sdds-sidebar-item-state-hover: var(--sdds-grey-868);
  --sdds-sidebar-item-state-active: var(--sdds-grey-800);
  --sdds-sidebar-side-menu: var(--sdds-grey-958);
  --sdds-sidebar-side-menu-border-right: var(--sdds-grey-868);
  --sdds-sidebar-side-menu-backgrund-cover: var(--sdds-black);
  --sdds-sidebar-side-menu-mobile-header-border-bottom: var(--sdds-grey-868);
  --sdds-sidebar-side-menu-bottom-menu-background: var(--sdds-grey-958);
  --sdds-sidebar-side-menu-bottom-menu-border-top: var(--sdds-grey-868);
  --sdds-sidebar-side-menu-single-item-border-bottom: var(--sdds-grey-868);
  --sdds-sidebar-side-menu-single-item-color: var(--sdds-grey-50);
  --sdds-sidebar-side-menu-single-item-background-active: var(--sdds-grey-50);
  --sdds-sidebar-side-menu-single-item-border-left-active: var(--sdds-blue-400);
  --sdds-sidebar-side-menu-single-subitem-color: var(--sdds-grey-100);
  --sdds-sidebar-side-menu-single-subitem-title-color: var(--sdds-grey-50);
  --sdds-sidebar-side-menu-single-subitem-color-active: var(--sdds-grey-958);
  --sdds-sidebar-side-menu-single-subitem-backgrund: var(--sdds-grey-958);
  --sdds-sidebar-side-menu-single-subitem-divider: var(--sdds-grey-700);
  --sdds-sidebar-side-menu-subnav-backgrund: var(--sdds-grey-800)
}

.sdds-nav__mob-menu-btn {
  display: none;
  transition: background-color 0.15s ease-in-out
}

@media all and (max-width: 800px) {
  .sdds-nav__mob-menu-btn {
    display: flex
  }
}

.sdds-nav__mob-menu-btn:hover {
  background-color: var(--sdds-sidebar-mobile-menu-btn-hover)
}

.sdds-nav__mob-menu-btn svg {
  height: 21px;
  width: 21px
}

.sdds-nav__mob-menu-btn svg path {
  fill: var(--sdds-sidebar-mobile-menu-btn-icon)
}

.sdds-sidebar {
  box-sizing: border-box;
}

.sdds-sidebar * {
  box-sizing: border-box
}

.sdds-sidebar.side-menu {
  background-color: var(--sdds-sidebar-side-menu);
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  transition: 0.15s ease-in-out;
  flex-basis: auto;
  min-width: auto;
  flex-shrink: 0;
  width: 288px;
  position: relative;
  border-right: 1px solid var(--sdds-sidebar-side-menu-border-right);
  z-index: 30
}

@media all and (max-width: 800px) {
  .sdds-sidebar.side-menu {
    position: absolute;
    top: 0;
    bottom: 0;
    padding-top: 64px;
    transform: translateX(-100%);
    pointer-events: none;
    width: 75%
  }

  .sdds-sidebar.side-menu.mobile-menu-open {
    transform: translateX(0);
    pointer-events: all
  }

  .sdds-sidebar.side-menu.mobile-menu-open::after {
    opacity: 0.4
  }

  .sdds-sidebar.side-menu::after {
    content: " ";
    position: absolute;
    background: var(--sdds-sidebar-side-menu-backgrund-cover);
    opacity: 0;
    pointer-events: none;
    top: 0;
    bottom: 0;
    left: 100%;
    width: 1000px;
    transition: 0.15s ease-in-out
  }
}

@media all and (max-width: 375px) {
  .sdds-sidebar.side-menu {
    width: 100%
  }
}

.sdds-sidebar-mheader {
  position: absolute;
  height: 64px;
  top: 0;
  width: 100%;
  display: none;
  border-bottom: 1px solid var(--sdds-sidebar-side-menu-mobile-header-border-bottom)
}

@media all and (max-width: 800px) {
  .sdds-sidebar-mheader {
    display: block
  }
}

.sdds-sidebar-mheader__close {
  all: unset;
  cursor: pointer;
  height: 64px;
  width: 68px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0
}

.sdds-sidebar-nav {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: hidden
}

.sdds-sidebar-nav--main {
  margin-bottom: 68px;
  overflow-y: auto
}

@media all and (max-width: 800px) {
  .sdds-sidebar-nav--main {
    margin-bottom: -1px;
    padding-top: 1px
  }
}

.sdds-sidebar-nav--main:last-child {
  margin-bottom: 0
}

.sdds-sidebar-nav--bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: var(--sdds-sidebar-side-menu-bottom-menu-background)
}

@media all and (max-width: 800px) {
  .sdds-sidebar-nav--bottom {
    display: none
  }
}

.sdds-sidebar-nav--bottom .sdds-sidebar-nav__item-link {
  border-bottom: none;
  border-top: 1px solid var(--sdds-sidebar-side-menu-bottom-menu-border-top)
}

.sdds-sidebar-nav--bottom .sdds-sidebar-nav__item {
  border-bottom: none
}

.sdds-sidebar-nav--bottom .sdds-sidebar-nav__item:last-child .sdds-sidebar-nav__item-link:focus::after {
  bottom: 0
}

.sdds-sidebar-nav.icons-disabled .sdds-sidebar-nav__icon {
  display: none
}

.sdds-sidebar-nav.icons-disabled .sdds-sidebar-nav-subnav__item .sdds-sidebar-nav__item-link {
  padding-left: 24px
}

.sdds-sidebar-nav__extended .sdds-sidebar-nav__item-link {
  border: none;
  background: none;
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
  text-align: left
}

.sdds-sidebar-nav__item {
  border-bottom: 1px solid var(--sdds-sidebar-side-menu-single-item-border-bottom)
}

.sdds-sidebar-nav__item-link {
  font: var(--sdds-headline-07);
  line-height: var(--sdds-headline-07-ls);
  color: var(--sdds-sidebar-side-menu-single-item-color);
  text-decoration: none;
  padding: 24px 40px 24px 24px;
  display: flex;
  align-items: flex-start;
  position: relative;
  min-height: 20px;
  box-sizing: content-box;
  transition: background-color 0.15s ease-in-out
}

.sdds-sidebar-nav__item-link:focus {
  background-color: var(--sdds-sidebar-item-state-focus)
}

.sdds-sidebar-nav__item-link:focus::after {
  content: " ";
  display: block;
  position: absolute;
  top: -1px;
  bottom: -1px;
  left: 0;
  right: 0;
  border: 1px solid var(--sdds-sidebar-item-state-border-focus);
  z-index: 100
}

.sdds-sidebar-nav__item-link:hover {
  background-color: var(--sdds-sidebar-item-state-hover)
}

.sdds-sidebar-nav__item-link:active {
  background-color: var(--sdds-sidebar-item-state-active) !important
}

.sdds-sidebar-nav__item-link .sdds-sidebar-nav__item-text {
  margin-top: 3px;
  opacity: 1;
  transition: opacity 0.15s ease-in-out;
  min-width: 160px
}

.sdds-sidebar-nav__item.subnav-open .sdds-sidebar-nav-subnav {
  display: block
}

.sdds-sidebar-nav__item.subnav-open .sdds-sidebar-nav__chevron {
  transform: rotate(180deg)
}

.sdds-sidebar-nav .sdds-item--active .sdds-sidebar-nav__item-link {
  background-color: var(--sdds-sidebar-side-menu-single-item-background-active);
  position: relative
}

.sdds-sidebar-nav .sdds-item--active .sdds-sidebar-nav__item-link::before {
  content: " ";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 4px;
  background-color: var(--sdds-sidebar-side-menu-single-item-border-left-active)
}

.sdds-sidebar-nav-subnav {
  list-style: none;
  margin: 0;
  padding: 0;
  display: none
}

.sdds-sidebar-nav-subnav__item .sdds-sidebar-nav__item-link {
  font: var(--sdds-detail-02);
  line-height: var(--sdds-detail-02-ls);
  color: var(--sdds-sidebar-side-menu-single-subitem-color);
  text-decoration: none;
  display: block;
  padding: 16px 24px 16px 56px;
  white-space: nowrap;
  transition: opacity 0.15s ease-in-out, background-color 0.15s ease-in-out;
  font-weight: normal;
  min-height: auto
}

.sdds-sidebar-nav-subnav__item .sdds-sidebar-nav__item-link:focus {
  background-color: var(--sdds-sidebar-item-state-focus)
}

.sdds-sidebar-nav-subnav__item .sdds-sidebar-nav__item-link:focus::after {
  content: " ";
  display: block;
  position: absolute;
  top: -1px;
  bottom: -1px;
  left: 0;
  right: 0;
  border: 1px solid var(--sdds-sidebar-item-state-border-focus);
  z-index: 100
}

.sdds-sidebar-nav-subnav__item .sdds-sidebar-nav__item-link:hover {
  background-color: var(--sdds-sidebar-item-state-hover)
}

.sdds-sidebar-nav-subnav__item .sdds-sidebar-nav__item-link:active {
  background-color: var(--sdds-sidebar-item-state-active) !important
}

.sdds-sidebar-nav-subnav__item .sdds-sidebar-nav__item-link .sdds-sidebar-nav__item-text {
  font: var(--sdds-detail-02);
  line-height: var(--sdds-detail-02-ls);
  transition: opacity 0.15s ease-in-out, height 0.15s ease-in-out
}

.sdds-sidebar-nav-subnav__item .sdds-sidebar-nav__item-title {
  font: var(--sdds-detail-02);
  line-height: var(--sdds-detail-02-ls);
  color: var(--sdds-sidebar-side-menu-single-subitem-title-color);
  text-decoration: none;
  padding: 16px 24px 16px 56px;
  white-space: nowrap;
  transition: opacity 0.15s ease-in-out, background-color 0.15s ease-in-out;
  min-height: auto;
  font-weight: 600;
  display: none
}

.sdds-sidebar-nav-subnav .sdds-item--active .sdds-sidebar-nav__item-link {
  font: var(--sdds-headline-07);
  line-height: var(--sdds-headline-07-ls);
  color: var(--sdds-sidebar-side-menu-single-subitem-color-active)
}

.sdds-sidebar-nav__icon {
  margin-right: 12px;
  line-height: 0;
  width: 20px;
  height: 20px;
  min-width: 20px;
  transition: 0.15s ease-in-out
}

.sdds-sidebar-nav__chevron {
  position: absolute;
  right: 16px;
  top: calc(50% - 4px);
  transform-origin: center;
  transition: 0.15s ease-in-out
}

.sdds-sidebar.collapsed {
  width: 68px
}

.sdds-sidebar.collapsed .sdds-sidebar-nav__item .sdds-sidebar-nav__item-text,
.sdds-sidebar.collapsed .sdds-sidebar-nav__item .sdds-sidebar-nav__item-title {
  opacity: 0;
  height: 1px
}

.sdds-sidebar.collapsed .sdds-sidebar-nav__item:hover .sdds-sidebar-nav-subnav {
  opacity: 1;
  pointer-events: all;
  display: initial
}

.sdds-sidebar.collapsed .sdds-sidebar-nav-subnav__item {
  display: block
}

.sdds-sidebar.collapsed .sdds-sidebar-nav-subnav__item .sdds-sidebar-nav__item-title {
  opacity: 1;
  height: auto;
  display: block
}

.sdds-sidebar.collapsed .sdds-sidebar-nav__chevron {
  opacity: 0
}

.sdds-sidebar.collapsed .sdds-sidebar-toggle .sdds-sidebar-nav__icon {
  transform: rotate(180deg)
}

.sdds-sidebar.collapsed .sdds-sidebar-nav-subnav {
  left: 68px;
  position: absolute;
  background: var(--sdds-sidebar-side-menu-subnav-backgrund);
  width: 245px;
  transform: translateY(-69px);
  z-index: 1;
  opacity: 0;
  display: none;
  pointer-events: none;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15)
}

.sdds-sidebar.collapsed .sdds-sidebar-nav-subnav .sdds-sidebar-nav-subnav__item .sdds-sidebar-nav__item-title,
.sdds-sidebar.collapsed .sdds-sidebar-nav-subnav .sdds-sidebar-nav-subnav__item .sdds-sidebar-nav__item-link {
  padding-left: 24px;
  border-bottom: 1px solid var(--sdds-sidebar-side-menu-single-subitem-divider)
}

.sdds-sidebar.collapsed .sdds-sidebar-nav-subnav .sdds-sidebar-nav-subnav__item .sdds-sidebar-nav__item-link .sdds-sidebar-nav__item-text {
  opacity: 1
}

.sdds-sidebar ::-webkit-scrollbar {
  width: 2px;
  background-color: var(--sdds-grey-300)
}

.sdds-sidebar ::-webkit-scrollbar-track {
  background-color: var(--sdds-grey-300)
}

.sdds-sidebar ::-webkit-scrollbar-thumb {
  background-color: var(--sdds-grey-500);
  border-radius: 0
}

:root,
.sdds-mode-light {
  --sdds-stepper-icon-background: var(--sdds-grey-958);
  --sdds-stepper-color: var(--sdds-grey-958);
  --sdds-stepper-icon-step-border-color-warning: var(--sdds-negative);
  --sdds-stepper-icon-step-border-color-success: var(--sdds-positive);
  --sdds-stepper-icon-step-background-success: var(--sdds-positive);
  --sdds-stepper-label-text-color-upcoming: var(--sdds-blue-900);
  --sdds-stepper-label-border-color-upcoming: var(--sdds-blue-900);
  --sdds-stepper-label-text-opacity-upcoming: 0.38;
  --sdds-stepper-icon-sidetext-background-before: var(--sdds-blue-900);
  --sdds-stepper-icon-vertical-background-before: var(--sdds-blue-900);
  --sdds-stepper-icon-step-background-before: var(--sdds-blue-900);
  --sdds-stepper-icon-step-background-after: var(--sdds-blue-900);
  --sdds-stepper-color--inverted: var(--sdds-white);
  --sdds-stepper-border-color--inverted: var(--sdds-white);
  --sdds-stepper-background--inverted-before: var(--sdds-white);
  --sdds-stepper-background--inverted-after: var(--sdds-white);
  --sdds-stepper-icon-border-color-waring--inverted: var(--sdds-negative);
  --sdds-stepper-icon-border-color-success-after--inverted: var(--sdds-positive)
}

.sdds-mode-dark {
  --sdds-stepper-icon-background: var(--sdds-white);
  --sdds-stepper-color: var(--sdds-white);
  --sdds-stepper-icon-step-border-color-warning: var(--sdds-negative);
  --sdds-stepper-icon-step-border-color-success: var(--sdds-positive);
  --sdds-stepper-icon-step-background-success: var(--sdds-positive);
  --sdds-stepper-icon-sidetext-background-before: var(--sdds-white);
  --sdds-stepper-icon-vertical-background-before: var(--sdds-white);
  --sdds-stepper-icon-step-background-before: var(--sdds-white);
  --sdds-stepper-icon-step-background-after: var(--sdds-white);
  --sdds-stepper-label-text-color-upcoming: var(--sdds-blue-50);
  --sdds-stepper-label-border-color-upcoming: var(--sdds-blue-50);
  --sdds-stepper-label-text-opacity-upcoming: 0.38;
  --sdds-stepper-color--inverted: var(--sdds-grey-958);
  --sdds-stepper-border-color--inverted: var(--sdds-grey-958);
  --sdds-stepper-background--inverted-before: var(--sdds-grey-958);
  --sdds-stepper-background--inverted-after: var(--sdds-grey-958);
  --sdds-stepper-icon-border-color-waring--inverted: var(--sdds-negative);
  --sdds-stepper-icon-border-color-success-after--inverted: var(--sdds-positive)
}

.sdds-stepper {
  box-sizing: border-box;
  font: var(--sdds-detail-02);
  letter-spacing: var(--sdds-detail-02-ls);
  color: var(--sdds-stepper-color);
  display: flex;
  flex: 1
}

.sdds-stepper * {
  box-sizing: border-box
}

.sdds-stepper--sidetext {
  display: inline-flex
}

.sdds-stepper--sidetext .sdds-stepper__step {
  flex-direction: row;
  padding: 0 20px 0 16px;
  min-width: unset;
  flex: unset
}

.sdds-stepper--sidetext .sdds-stepper__step_label {
  margin-top: -8px;
  margin-left: 8px;
  padding: 0
}

.sdds-stepper--sidetext .sdds-stepper__step-icon::before {
  content: " ";
  position: absolute;
  height: 1px;
  background-color: var(--sdds-stepper-icon-sidetext-background-before);
  opacity: 0.16;
  right: auto;
  left: -12px;
  width: 20px
}

.sdds-stepper--sidetext .sdds-stepper__step-icon::after {
  display: none
}

.sdds-stepper--vertical {
  flex-direction: column
}

.sdds-stepper--vertical .sdds-stepper__step {
  flex-direction: row;
  padding: 0 20px 0 16px;
  min-width: unset;
  flex: unset
}

.sdds-stepper--vertical .sdds-stepper__step:not(:last-child) {
  margin-bottom: 52px
}

.sdds-stepper--vertical .sdds-stepper__step_label {
  margin-top: -8px;
  margin-left: 8px;
  padding: 0
}

.sdds-stepper--vertical .sdds-stepper__step-icon::after {
  content: " ";
  position: absolute;
  height: 45px;
  background-color: var(--sdds-stepper-icon-sidetext-background-before);
  opacity: 0.16;
  top: 39px;
  left: 31px;
  width: 1px;
  right: auto
}

.sdds-stepper--vertical .sdds-stepper__step-icon::before {
  display: none
}

.sdds-stepper--vertical.sdds-stepper-sm .sdds-stepper__step,
.sdds-stepper--vertical.sdds-stepper--small .sdds-stepper__step {
  margin-bottom: 34px
}

.sdds-stepper--vertical.sdds-stepper-sm .sdds-stepper__step-icon::after,
.sdds-stepper--vertical.sdds-stepper--small .sdds-stepper__step-icon::after {
  top: 32px;
  left: 28px;
  height: 27px
}

.sdds-stepper__step {
  position: relative;
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 0;
  flex: 1 1 0
}

.sdds-stepper__step_label {
  white-space: nowrap;
  padding: 0 20px
}

.sdds-stepper__step-icon {
  height: 32px;
  width: 32px;
  border-radius: 100px;
  border: 1px solid var(--sdds-stepper-icon-background);
  margin-bottom: 8px;
  display: flex;
  justify-content: center;
  align-items: center
}

.sdds-stepper__step-icon::before {
  content: " ";
  position: absolute;
  height: 1px;
  background-color: var(--sdds-stepper-icon-step-background-before);
  opacity: 0.16;
  left: 0;
  right: calc(50% + 16px + 8px)
}

.sdds-stepper__step-icon::after {
  content: " ";
  position: absolute;
  height: 1px;
  background-color: var(--sdds-stepper-icon-step-background-after);
  opacity: 0.16;
  left: calc(50% + 16px + 8px);
  right: 0
}

.sdds-stepper__step-icon-warning {
  height: 18px;
  width: 18px;
  margin-top: -4px
}

.sdds-stepper__step-icon-success {
  height: 20px;
  width: 20px
}

.sdds-stepper__step:first-child .sdds-stepper__step-icon::before {
  display: none
}

.sdds-stepper__step:last-child .sdds-stepper__step-icon::after {
  display: none
}

.sdds-stepper__step--value .sdds-stepper__step-icon-success {
  display: none
}

.sdds-stepper__step--value .sdds-stepper__step-icon-warning {
  display: none
}

.sdds-stepper__step--warning .sdds-stepper__step-icon {
  border-color: var(--sdds-stepper-icon-step-border-color-warning)
}

.sdds-stepper__step--warning .sdds-stepper__step-icon-success {
  display: none
}

.sdds-stepper__step--warning .sdds-stepper__step-icon-value {
  display: none
}

.sdds-stepper__step--inactive .sdds-stepper__step-icon {
  border-color: var(--sdds-stepper-label-border-color-upcoming);
  opacity: var(--sdds-stepper-label-text-opacity-upcoming)
}

.sdds-stepper__step--inactive .sdds-stepper__step-icon::after,
.sdds-stepper__step--inactive .sdds-stepper__step-icon::before {
  opacity: unset;
  opacity: 0.38
}

.sdds-stepper__step--inactive .sdds-stepper__step-icon-value {
  opacity: 1
}

.sdds-stepper__step--inactive .sdds-stepper__step_label {
  opacity: 0.38
}

.sdds-stepper__step--inactive .sdds-stepper__step-icon-success {
  display: none
}

.sdds-stepper__step--inactive .sdds-stepper__step-icon-warning {
  display: none
}

.sdds-stepper__step--success .sdds-stepper__step-icon {
  background-color: var(--sdds-stepper-icon-step-background-success);
  border-color: var(--sdds-stepper-icon-step-border-color-success)
}

.sdds-stepper__step--success .sdds-stepper__step-icon-warning {
  display: none
}

.sdds-stepper__step--success .sdds-stepper__step-icon-value {
  display: none
}

.sdds-stepper-sm .sdds-stepper__step-icon,
.sdds-stepper--small .sdds-stepper__step-icon {
  height: 24px;
  width: 24px;
  font-size: 12px
}

.sdds-stepper-sm .sdds-stepper__step-icon-warning,
.sdds-stepper--small .sdds-stepper__step-icon-warning {
  height: 16px;
  width: 16px;
  margin-top: -2px
}

.sdds-stepper-sm .sdds-stepper__step-icon-success,
.sdds-stepper--small .sdds-stepper__step-icon-success {
  height: 16px;
  width: 16px
}

.sdds-stepper-sm .sdds-stepper__step_label,
.sdds-stepper--small .sdds-stepper__step_label {
  font-size: 12px
}

.sdds-stepper--inverted {
  color: var(--sdds-stepper-color--inverted)
}

.sdds-stepper--inverted .sdds-stepper__step-icon {
  border: 1px solid var(--sdds-stepper-border-color--inverted)
}

.sdds-stepper--inverted .sdds-stepper__step-icon::before {
  background-color: var(--sdds-stepper-background--inverted-before)
}

.sdds-stepper--inverted .sdds-stepper__step-icon::after {
  background-color: var(--sdds-stepper-background--inverted-after)
}

.sdds-stepper--inverted .sdds-stepper__step--warning .sdds-stepper__step-icon {
  border: 1px solid var(--sdds-stepper-icon-border-color-waring--inverted)
}

.sdds-stepper--inverted .sdds-stepper__step--success .sdds-stepper__step-icon {
  border: 1px solid var(--sdds-stepper-icon-border-color-success-after--inverted)
}

.sdds-stepper--inverted .sdds-stepper__step--inactive .sdds-stepper__step-value {
  color: rgba(255, 255, 255, 0.16)
}

.sdds-stepper--inverted .sdds-stepper__step--inactive .sdds-stepper__step_label {
  color: rgba(255, 255, 255, 0.38)
}

.sdds-stepper--inverted .sdds-stepper__step--inactive .sdds-stepper__step-icon {
  border-color: rgba(255, 255, 255, 0.16)
}

.sdds-stepper .sdds-icon.warning {
  color: var(--sdds-negative)
}

.sdds-stepper .sdds-icon.tick {
  color: var(--sdds-white)
}

.sdds-stepper .sdds-icon.warning {
  font-size: 18px
}

.sdds-stepper .sdds-icon.warning::before {
  line-height: 1
}

.sdds-stepper .sdds-icon.tick {
  font-size: 20px
}

.sdds-stepper .sdds-icon.tick::before {
  line-height: 1
}

.sdds-stepper.sdds-stepper--sm .sdds-icon.warning,
.sdds-stepper.sdds-stepper--sm .sdds-icon.tick {
  font-size: 16px
}

.sdds-stepper sdds-icon[name=warning] {
  color: var(--sdds-negative)
}

.sdds-stepper sdds-icon[name=tick] {
  color: var(--sdds-white)
}

.sdds-stepper sdds-icon {
  line-height: 1
}

.sdds-mode-light {
  --sdds-toggle-switch: var(--sdds-white);
  --sdds-toggle-switch-disabled: var(--sdds-grey-200);
  --sdds-toggle-headline: var(--sdds-grey-700);
  --sdds-toggle-headline-disabled: var(--sdds-grey-600);
  --sdds-toggle-on-slider: var(--sdds-positive);
  --sdds-toggle-on-slider-hover: var(--sdds-positive);
  --sdds-toggle-on-slider-focus: var(--sdds-positive);
  --sdds-toggle-on-slider-pressed: var(--sdds-positive);
  --sdds-toggle-on-border-focus: var(--sdds-positive);
  --sdds-toggle-on-slider-disabled: var(--sdds-grey-400);
  --sdds-toggle-slider-disabled: var(--sdds-grey-400);
  --sdds-toggle-label-color: var(--sdds-grey-958);
  --sdds-toggle-label-color-disabled: var(--sdds-grey-600);
  --sdds-toggle-off-slider: var(--sdds-grey-500);
  --sdds-toggle-off-slider-hover: var(--sdds-grey-600);
  --sdds-toggle-off-slider-focus: var(--sdds-grey-600);
  --sdds-toggle-off-slider-pressed: var(--sdds-grey-700);
  --sdds-toggle-off-border-focus: var(--sdds-grey-800)
}

.sdds-mode-dark {
  --sdds-toggle-switch: var(--sdds-white);
  --sdds-toggle-switch-disabled: var(--sdds-grey-200);
  --sdds-toggle-headline: var(--sdds-grey-100);
  --sdds-toggle-headline-disabled: var(--sdds-grey-700);
  --sdds-toggle-on-slider: var(--sdds-positive);
  --sdds-toggle-on-slider-hover: var(--sdds-positive);
  --sdds-toggle-on-slider-focus: var(--sdds-positive);
  --sdds-toggle-on-slider-pressed: var(--sdds-positive);
  --sdds-toggle-on-border-focus: var(--sdds-positive);
  --sdds-toggle-on-slider-disabled: var(--sdds-grey-800);
  --sdds-toggle-slider-disabled: var(--sdds-grey-400);
  --sdds-toggle-label-color: var(--sdds-white);
  --sdds-toggle-label-color-disabled: var(--sdds-grey-400);
  --sdds-toggle-off-slider: var(--sdds-grey-500);
  --sdds-toggle-off-slider-hover: var(--sdds-grey-600);
  --sdds-toggle-off-slider-focus: var(--sdds-grey-600);
  --sdds-toggle-off-slider-pressed: var(--sdds-grey-700);
  --sdds-toggle-off-border-focus: var(--sdds-grey-800)
}

.sdds-toggle {
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap
}

.sdds-toggle * {
  box-sizing: border-box
}

.sdds-toggle,
.sdds-toggle * {
  box-sizing: border-box
}

.sdds-toggle .sdds-toggle-headline {
  font: var(--sdds-detail-02);
  letter-spacing: var(--sdds-detail-02-ls);
  color: var(--sdds-toggle-headline);
  flex: 1 100%;
  margin-bottom: 12px
}

.sdds-toggle .sdds-toggle-input {
  margin: 0;
  padding: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  opacity: 0;
  z-index: 1;
  cursor: pointer
}

.sdds-toggle .sdds-toggle-switch {
  width: 44px;
  height: 24px;
  position: relative;
  display: block
}

.sdds-toggle .sdds-toggle-switch::after,
.sdds-toggle .sdds-toggle-switch::before {
  content: "";
  position: absolute;
  box-sizing: border-box;
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1)
}

.sdds-toggle .sdds-toggle-switch::before {
  width: 44px;
  height: 24px;
  border-radius: 16px;
  background-color: var(--sdds-toggle-off-slider);
  left: 0
}

.sdds-toggle .sdds-toggle-switch::after {
  width: 16px;
  height: 16px;
  background-color: var(--sdds-toggle-switch);
  border-radius: 50%;
  left: 4px;
  top: 4px
}

.sdds-toggle .sdds-toggle-label {
  font: var(--sdds-detail-01);
  letter-spacing: var(--sdds-detail-01-ls);
  color: var(--sdds-toggle-label-color);
  padding-left: var(--sdds-spacing-element-8)
}

.sdds-toggle .sdds-toggle-input:checked+.sdds-toggle-switch::before {
  background-color: var(--sdds-toggle-on-slider)
}

.sdds-toggle .sdds-toggle-input:checked+.sdds-toggle-switch::after {
  left: 24px
}

.sdds-toggle .sdds-toggle-input:focus+.sdds-toggle-switch::before {
  background-color: var(--sdds-toggle-off-slider-focus);
  border: 1px solid var(--sdds-toggle-off-border-focus)
}

.sdds-toggle .sdds-toggle-input:focus:checked+.sdds-toggle-switch::before {
  background-color: var(--sdds-toggle-on-slider-focus);
  border: 1px solid var(--sdds-toggle-on-border-focus)
}

.sdds-toggle .sdds-toggle-input:active+.sdds-toggle-switch::before {
  background-color: var(--sdds-toggle-off-slider-pressed);
  border: 1px solid var(--sdds-toggle-off-slider-pressed)
}

.sdds-toggle .sdds-toggle-input:active:checked+.sdds-toggle-switch::before {
  background-color: var(--sdds-toggle-on-slider-pressed);
  border: 1px solid var(--sdds-toggle-on-slider-pressed)
}

.sdds-toggle.sdds-toggle-sm .sdds-toggle-switch,
.sdds-toggle.sdds-toggle-sm .sdds-toggle-switch::before {
  width: 28px;
  height: 16px
}

.sdds-toggle.sdds-toggle-sm .sdds-toggle-switch::after {
  width: 8px;
  height: 8px
}

.sdds-toggle.sdds-toggle-sm .sdds-toggle-label {
  font: var(--sdds-detail-02);
  letter-spacing: var(--sdds-detail-02-ls)
}

.sdds-toggle.sdds-toggle-sm .sdds-toggle-input:checked+.sdds-toggle-switch::after {
  left: 16px
}

.sdds-toggle:hover .sdds-toggle-switch::before {
  background-color: var(--sdds-toggle-off-slider-hover)
}

.sdds-toggle:hover .sdds-toggle-input:checked+.sdds-toggle-switch::before {
  background-color: var(--sdds-toggle-on-slider-hover)
}

.sdds-toggle:focus {
  outline: none
}

.sdds-toggle:active .sdds-toggle-input+.sdds-toggle-switch::before {
  background-color: var(--sdds-toggle-off-slider-pressed);
  border: 1px solid var(--sdds-toggle-off-slider-pressed)
}

.sdds-toggle:active .sdds-toggle-input:checked+.sdds-toggle-switch::before {
  background-color: var(--sdds-toggle-on-slider-pressed);
  border: 1px solid var(--sdds-toggle-on-slider-pressed)
}

.sdds-toggle.disabled {
  cursor: not-allowed
}

.sdds-toggle.disabled::before {
  background-color: var(--sdds-toggle-slider-disabled);
  border: 0
}

.sdds-toggle.disabled .sdds-toggle-input {
  pointer-events: none
}

.sdds-toggle.disabled .sdds-toggle-input:checked+.sdds-toggle-switch::before,
.sdds-toggle.disabled .sdds-toggle-input:checked:focus+.sdds-toggle-switch::before,
.sdds-toggle.disabled .sdds-toggle-input:focus+.sdds-toggle-switch::before {
  background-color: var(--sdds-toggle-on-slider-disabled);
  border: 0
}

.sdds-toggle.disabled .sdds-toggle-headline {
  color: var(--sdds-toggle-headline-disabled)
}

.sdds-toggle.disabled .sdds-toggle-label {
  color: var(--sdds-toggle-label-color-disabled);
  pointer-events: none
}

.sdds-toggle.disabled .sdds-toggle-switch::before {
  background-color: var(--sdds-toggle-slider-disabled)
}

.sdds-toggle.disabled .sdds-toggle-switch::after {
  background-color: var(--sdds-toggle-switch-disabled)
}

:root,
html,
.sdds-mode-light {
  --sdds-toast-background-color: var(--sdds-grey-958);
  --sdds-toast-headline-color: var(--sdds-grey-50);
  --sdds-toast-subheadline-color: var(--sdds-grey-50);
  --sdds-toast-link-color: var(--sdds-blue-300);
  --sdds-toast-dissmiss-color: var(--sdds-white)
}

.sdds-mode-dark {
  --sdds-toast-background-color: var(--sdds-grey-50);
  --sdds-toast-headline-color: var(--sdds-grey-958);
  --sdds-toast-subheadline-color: var(--sdds-grey-958);
  --sdds-toast-link-color: var(--sdds-blue-500);
  --sdds-toast-dissmiss-color: var(--sdds-black)
}

:root {
  --sdds-headline-01: normal normal bold 40px/40px "Scania Sans", arial, helvetica, sans-serif;
  --sdds-headline-01-ls: 0;
  --sdds-headline-02: normal normal bold 32px/32px "Scania Sans", arial, helvetica, sans-serif;
  --sdds-headline-02-ls: -0.04em;
  --sdds-headline-03: normal normal bold 24px/24px "Scania Sans", arial, helvetica, sans-serif;
  --sdds-headline-03-ls: -0.03em;
  --sdds-headline-04: normal normal bold 20px/24px "Scania Sans", arial, helvetica, sans-serif;
  --sdds-headline-04-ls: -0.02em;
  --sdds-headline-05: normal normal bold 14px/24px "Scania Sans", arial, helvetica, sans-serif;
  --sdds-headline-05-ls: -0.02em;
  --sdds-headline-06: normal normal bold 14px/16px "Scania Sans", arial, helvetica, sans-serif;
  --sdds-headline-06-ls: -0.02em;
  --sdds-headline-07: normal normal bold 14px/16px "Scania Sans Semi Condensed", arial, helvetica,
    sans-serif;
  --sdds-headline-07-ls: -0.01em;
  --sdds-paragraph-01: normal normal normal 24px/32px "Scania Sans", arial, helvetica, sans-serif;
  --sdds-paragraph-01-ls: -0.03em;
  --sdds-paragraph-02: normal normal normal 20px/24px "Scania Sans", arial, helvetica, sans-serif;
  --sdds-paragraph-02-ls: -0.01em;
  --sdds-body-01: normal normal normal 16px/24px "Scania Sans", arial, helvetica, sans-serif;
  --sdds-body-01-ls: -0.02em;
  --sdds-body-02: normal normal normal 14px/20px "Scania Sans", arial, helvetica, sans-serif;
  --sdds-body-02-ls: -0.02em;
  --sdds-detail-01: normal normal normal 16px/24px "Scania Sans Semi Condensed",
    "Scania Sans Condensed", arial, helvetica, sans-serif;
  --sdds-detail-01-ls: -0.01em;
  --sdds-detail-02: normal normal normal 14px/16px "Scania Sans Semi Condensed",
    "Scania Sans Condensed", arial, helvetica, sans-serif;
  --sdds-detail-02-ls: -0.01em;
  --sdds-detail-03: normal normal normal 14px/20px "Scania Sans Semi Condensed",
    "Scania Sans Condensed", arial, helvetica, sans-serif;
  --sdds-detail-03-ls: -0.01em;
  --sdds-detail-04: normal normal bold 12px/16px "Scania Sans", arial, helvetica, sans-serif;
  --sdds-detail-04-ls: 0.08em;
  --sdds-detail-04-tt: uppercase;
  --sdds-detail-05: normal normal normal 12px/16px "Scania Sans Semi Condensed",
    "Scania Sans Condensed", arial, helvetica, sans-serif;
  --sdds-detail-05-ls: 0;
  --sdds-detail-06: normal normal bold 10px/16px "Scania Sans", arial, helvetica, sans-serif;
  --sdds-detail-06-ls: 0.08em;
  --sdds-detail-06-tt: uppercase;
  --sdds-detail-07: normal normal normal 10px/8px "Scania Sans Semi Condensed",
    "Scania Sans Condensed", arial, helvetica, sans-serif;
  --sdds-detail-07-ls: 0;
  --sdds-expressive-headline-01: normal normal bold 80px/80px "Scania Sans Headline", arial,
    helvetica, sans-serif;
  --sdds-expressive-headline-01-ls: 0;
  --sdds-expressive-headline-02: normal normal bold 56px/56px "Scania Sans Headline", arial,
    helvetica, sans-serif;
  --sdds-expressive-headline-02-ls: 0;
  --sdds-headline-ex-01: normal normal bold 40px/40px "Scania Sans Headline", arial, helvetica,
    sans-serif;
  --sdds-headline-ex-01-ls: 0;
  --sdds-headline-ex-02: normal normal bold 32px/32px "Scania Sans Headline", arial, helvetica,
    sans-serif;
  --sdds-headline-ex-02-ls: -0.04em;
  --sdds-headline-ex-03: normal normal bold 24px/24px "Scania Sans Headline", arial, helvetica,
    sans-serif;
  --sdds-headline-ex-03-ls: -0.03em;
  --sdds-headline-ex-04: normal normal bold 20px/24px "Scania Sans Headline", arial, helvetica,
    sans-serif;
  --sdds-headline-ex-04-ls: -0.01em;
  --sdds-headline-ex-05: normal normal bold 16px/24px "Scania Sans Headline", arial, helvetica,
    sans-serif;
  --sdds-headline-ex-05-ls: -0.02em;
  --sdds-headline-ex-06: normal normal bold 14px/16px "Scania Sans Headline", arial, helvetica,
    sans-serif;
  --sdds-headline-ex-06-ls: -0.02em;
  --sdds-headline-ex-07: normal normal bold 14px/16px "Scania Sans Semi Condensed", arial, helvetica,
    sans-serif;
  --sdds-headline-ex-07-ls: -0.01em;
  --sdds-paragraph-ex-01: normal normal normal 24px/32px "Scania Sans", arial, helvetica, sans-serif;
  --sdds-paragraph-ex-01-ls: -0.03em;
  --sdds-paragraph-ex-02: normal normal normal 20px/24px "Scania Sans", arial, helvetica, sans-serif;
  --sdds-paragraph-ex-02-ls: -0.01em;
  --sdds-body-ex-01: normal normal normal 16px/24px "Scania Sans", arial, helvetica, sans-serif;
  --sdds-body-ex-01-ls: -0.02em;
  --sdds-body-ex-02: normal normal normal 14px/20px "Scania Sans", arial, helvetica, sans-serif;
  --sdds-body-ex-02-ls: -0.01em;
  --sdds-detail-ex-01: normal normal normal 16px/24px "Scania Sans Semi Condensed",
    "Scania Sans Condensed", arial, helvetica, sans-serif;
  --sdds-detail-ex-01-ls: -0.01em;
  --sdds-detail-ex-02: normal normal normal 14px/16px "Scania Sans Semi Condensed",
    "Scania Sans Condensed", arial, helvetica, sans-serif;
  --sdds-detail-ex-02-ls: -0.01em;
  --sdds-detail-ex-03: normal normal normal 14px/20px "Scania Sans Semi Condensed",
    "Scania Sans Condensed", arial, helvetica, sans-serif;
  --sdds-detail-ex-03-ls: -0.01em;
  --sdds-detail-ex-04: normal normal bold 12px/16px "Scania Sans", arial, helvetica, sans-serif;
  --sdds-detail-ex-04-ls: 0.08em;
  --sdds-detail-ex-04-tt: uppercase;
  --sdds-detail-ex-05: normal normal normal 12px/16px "Scania Sans Semi Condensed",
    "Scania Sans Condensed", arial, helvetica, sans-serif;
  --sdds-detail-ex-05-ls: 0;
  --sdds-detail-ex-06: normal normal bold 10px/16px "Scania Sans", arial, helvetica, sans-serif;
  --sdds-detail-ex-06-ls: 0.08em;
  --sdds-detail-ex-06-tt: uppercase;
  --sdds-detail-ex-07: normal normal normal 10px/8px "Scania Sans Semi Condensed",
    "Scania Sans Condensed", arial, helvetica, sans-serif;
  --sdds-detail-ex-07-ls: 0
}

.sdds-toast {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  background: var(--sdds-toast-background-color);
  border-radius: var(--sdds-spacing-element-4);
  border-left: 4px solid white;
  width: 352px;
  min-height: var(--sdds-spacing-element-48)
}

.sdds-toast * {
  box-sizing: border-box
}

.sdds-toast-icon {
  margin: 13.5px 0 0 12px
}

.sdds-toast i.sdds-icon {
  font-size: 20px
}

.sdds-toast-success {
  border-left-color: var(--sdds-positive)
}

.sdds-toast-success .sdds-toast-icon {
  color: var(--sdds-positive)
}

.sdds-toast-info {
  border-left-color: var(--sdds-information)
}

.sdds-toast-info .sdds-toast-icon {
  color: var(--sdds-information)
}

.sdds-toast-warning {
  border-left-color: var(--sdds-warning)
}

.sdds-toast-warning .sdds-toast-icon {
  color: var(--sdds-warning)
}

.sdds-toast-error {
  border-left-color: var(--sdds-negative)
}

.sdds-toast-error .sdds-toast-icon {
  color: var(--sdds-negative)
}

.sdds-toast-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 14px 16px 10px
}

.sdds-toast-header {
  height: var(--sdds-spacing-element-32)
}

.sdds-toast-headline {
  margin-top: 16px;
  font-family: "Scania Sans Semi Condensed", arial, helvetica, sans-serif;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: -0.01em;
  color: var(--sdds-toast-headline-color);
  width: 252px;
  float: left
}

.sdds-toast-close {
  all: unset;
  float: right;
  width: var(--sdds-spacing-element-20);
  height: var(--sdds-spacing-element-20);
  color: var(--sdds-toast-dissmiss-color);
  cursor: pointer;
  padding-top: 14px
}

.sdds-toast-close:focus-visible {
  outline: 2px solid var(--sdds-blue-400);
  outline-offset: -2px
}

.sdds-toast-body {
  display: flex;
  flex-direction: column;
  margin-top: var(--sdds-spacing-element-4)
}

.sdds-toast-subheadline {
  font-family: "Scania Sans Semi Condensed", arial, helvetica, sans-serif;
  font-size: 14px;
  letter-spacing: -0.01em;
  color: var(--sdds-toast-subheadline-color)
}

.sdds-toast-subheadline+.sdds-toast-link {
  margin-top: var(--sdds-spacing-element-16)
}

.sdds-toast-link {
  height: var(--sdds-spacing-element-16);
  color: var(--sdds-toast-link-color);
  font-family: "Scania Sans Semi Condensed", arial, helvetica, sans-serif;
  font-size: 14px;
  letter-spacing: -0.01em
}

:root,
.sdds-mode-light {
  --sdds-accordion-border: var(--sdds-grey-300);
  --sdds-accordion-background: transparent;
  --sdds-accordion-background-hover-primary: var(--sdds-grey-50);
  --sdds-accordion-background-hover-secondary: var(--sdds-white);
  --sdds-accordion-background-hover: var(--sdds-accordion-background-hover-primary);
  --sdds-accordion-background-focus: transparent;
  --sdds-accordion-background-active: var(--sdds-grey-100);
  --sdds-accordion-color: var(--sdds-grey-958);
  --sdds-accordion-border-focus: var(--sdds-grey-500);
  --sdds-accordion-color-disabled: var(--sdds-grey-400)
}

:root .sdds-mode-variant-primary,
.sdds-mode-light .sdds-mode-variant-primary {
  --sdds-accordion-background-hover: var(--sdds-accordion-background-hover-primary)
}

:root .sdds-mode-variant-secondary,
.sdds-mode-light .sdds-mode-variant-secondary {
  --sdds-accordion-background-hover: var(--sdds-accordion-background-hover-secondary)
}

.sdds-mode-dark {
  --sdds-accordion-border: var(--sdds-grey-700);
  --sdds-accordion-background: transparent;
  --sdds-accordion-background-hover-primary: var(--sdds-grey-900);
  --sdds-accordion-background-hover-secondary: var(--sdds-grey-868);
  --sdds-accordion-background-hover: var(--sdds-accordion-background-hover-primary);
  --sdds-accordion-background-focus: transparent;
  --sdds-accordion-background-active: var(--sdds-grey-900);
  --sdds-accordion-color: var(--sdds-white);
  --sdds-accordion-border-focus: var(--sdds-grey-500);
  --sdds-accordion-color-disabled: var(--sdds-grey-800)
}

.sdds-mode-dark .sdds-mode-variant-primary {
  --sdds-accordion-background-hover: var(--sdds-accordion-background-hover-primary)
}

.sdds-mode-dark .sdds-mode-variant-secondary {
  --sdds-accordion-background-hover: var(--sdds-accordion-background-hover-secondary)
}

:root,
.sdds-mode-light {
  --sdds-banner-text-color: var(--sdds-grey-958);
  --sdds-banner-x-color: var(--sdds-black);
  --sdds-banner-background-default: var(--sdds-grey-50);
  --sdds-banner-prefix-default-color: var(--sdds-grey-700);
  --sdds-banner-background-info: var(--sdds-grey-300);
  --sdds-banner-prefix-info-color: var(--sdds-blue-400);
  --sdds-banner-background-error: var(--sdds-red-100);
  --sdds-banner-prefix-error-color: var(--sdds-red-500)
}

.sdds-mode-dark {
  --sdds-banner-text-color: var(--sdds-grey-50);
  --sdds-banner-x-color: var(--sdds-white);
  --sdds-banner-background-default: var(--sdds-grey-900);
  --sdds-banner-prefix-default-color: var(--sdds-grey-400);
  --sdds-banner-background-info: var(--sdds-blue-700);
  --sdds-banner-prefix-info-color: var(--sdds-information);
  --sdds-banner-background-error: var(--sdds-red-900);
  --sdds-banner-prefix-error-color: var(--sdds-negative)
}

:root,
.sdds-mode-light {
  --sdds-breadcrumb-color: var(--sdds-grey-900);
  --sdds-breadcrumb-color-hover: var(--sdds-grey-700);
  --sdds-breadcrumb-color-focus: var(--sdds-grey-700);
  --sdds-breadcrumb-color-disabled: var(--sdds-grey-500);
  --sdds-breadcrumb-separator-color: var(--sdds-grey-500)
}

.sdds-mode-dark {
  --sdds-breadcrumb-color: var(--sdds-grey-300);
  --sdds-breadcrumb-color-hover: var(--sdds-grey-500);
  --sdds-breadcrumb-color-focus: var(--sdds-grey-500);
  --sdds-breadcrumb-color-disabled: var(--sdds-grey-700);
  --sdds-breadcrumb-separator-color: var(--sdds-grey-700)
}

:root,
html,
.sdds-mode-light {
  --sdds-data-table-color: var(--sdds-grey-958);
  --sdds-data-table-body-cell-background-hover: var(--sdds-grey-200);
  --sdds-data-table-body-row-background: var(--sdds-grey-50);
  --sdds-data-table-body-row-background-hover: var(--sdds-grey-200);
  --sdds-data-table-body-row-background-selected: var(--sdds-grey-300);
  --sdds-data-table-body-row-background-selected-hover: var(--sdds-grey-300);
  --sdds-data-table-footer-background: var(--sdds-grey-100);
  --sdds-data-table-footer-page-selector-input-background: var(--sdds-grey-50);
  --sdds-data-table-footer-page-selector-input-background-hover: var(--sdds-white);
  --sdds-data-table-footer-page-selector-input-background-disabled: var(--sdds-grey-600);
  --sdds-data-table-footer-btn-hover: var(--sdds-grey-300);
  --sdds-data-table-header-background: var(--sdds-grey-300);
  --sdds-data-table-header-background-hover: var(--sdds-grey-400);
  --sdds-data-table-header-btn-color: var(--sdds-grey-958);
  --sdds-data-table-header-btn-background: var(--sdds-grey-500);
  --sdds-data-table-header-btn-background-hover: var(--sdds-grey-600);
  --sdds-data-table-header-btn-focus: var(--sdds-blue-400);
  --sdds-data-table-toolbar-background: var(--sdds-grey-400);
  --sdds-data-table-toolbar-searchbar-background: var(--sdds-grey-50);
  --sdds-data-table-toolbar-searchbar-background-hover: var(--sdds-grey-300);
  --sdds-data-table-divider: var(--sdds-grey-400)
}

:root .sdds-mode-variant-primary,
html .sdds-mode-variant-primary,
.sdds-mode-light .sdds-mode-variant-primary {
  --sdds-data-table-body-row-background: var(--sdds-grey-50);
  --sdds-data-table-body-row-background-hover: var(--sdds-grey-200)
}

:root .sdds-mode-variant-secondary,
html .sdds-mode-variant-secondary,
.sdds-mode-light .sdds-mode-variant-secondary {
  --sdds-data-table-body-row-background: var(--sdds-white);
  --sdds-data-table-body-row-background-hover: var(--sdds-grey-100)
}

.sdds-mode-dark {
  --sdds-data-table-color: var(--sdds-grey-50);
  --sdds-data-table-body-cell-background-hover: var(--sdds-grey-846);
  --sdds-data-table-body-row-background: var(--sdds-grey-868);
  --sdds-data-table-body-row-background-hover: var(--sdds-grey-846);
  --sdds-data-table-body-row-background-selected: var(--sdds-grey-800);
  --sdds-data-table-body-row-background-selected-hover: var(--sdds-grey-700);
  --sdds-data-table-footer-background: var(--sdds-grey-846);
  --sdds-data-table-footer-page-selector-input-background: var(--sdds-grey-900);
  --sdds-data-table-footer-page-selector-input-background-hover: var(--sdds-grey-958);
  --sdds-data-table-footer-page-selector-input-background-disabled: var(--sdds-grey-700);
  --sdds-data-table-footer-btn-hover: var(--sdds-grey-900);
  --sdds-data-table-header-background: var(--sdds-grey-800);
  --sdds-data-table-header-background-hover: var(--sdds-grey-700);
  --sdds-data-table-header-btn-color: var(--sdds-grey-50);
  --sdds-data-table-header-btn-background: var(--sdds-grey-600);
  --sdds-data-table-header-btn-background-hover: var(--sdds-grey-700);
  --sdds-data-table-header-btn-focus: var(--sdds-blue-400);
  --sdds-data-table-toolbar-background: var(--sdds-grey-700);
  --sdds-data-table-toolbar-searchbar-background: var(--sdds-grey-900);
  --sdds-data-table-toolbar-searchbar-background-hover: var(--sdds-grey-800);
  --sdds-data-table-divider: var(--sdds-grey-700)
}

.sdds-mode-dark .sdds-mode-variant-primary {
  --sdds-data-table-body-row-background: var(--sdds-grey-868);
  --sdds-data-table-body-row-background-hover: var(--sdds-grey-846)
}

.sdds-mode-dark .sdds-mode-variant-secondary {
  --sdds-data-table-body-row-background: var(--sdds-grey-900);
  --sdds-data-table-body-row-background-hover: var(--sdds-grey-846)
}

:root,
.sdds-mode-light {
  --sdds-block-color: var(--sdds-grey-985);
  --sdds-block-background-primary: var(--sdds-grey-50);
  --sdds-block-background-nested-primary: var(--sdds-white);
  --sdds-block-background-secondary: var(--sdds-white);
  --sdds-block-background-nested-secondary: var(--sdds-grey-50);
  --sdds-block-background: var(--sdds-block-background-primary);
  --sdds-block-background-nested: var(--sdds-block-background-nested-primary)
}

:root .sdds-mode-variant-primary,
.sdds-mode-light .sdds-mode-variant-primary {
  --sdds-block-background: var(--sdds-block-background-primary);
  --sdds-block-background-nested: var(--sdds-block-background-nested-primary)
}

:root .sdds-mode-variant-secondary,
.sdds-mode-light .sdds-mode-variant-secondary {
  --sdds-block-background: var(--sdds-block-background-secondary);
  --sdds-block-background-nested: var(--sdds-block-background-nested-secondary)
}

.sdds-mode-dark {
  --sdds-block-color: var(--sdds-grey-50);
  --sdds-block-background-primary: var(--sdds-grey-900);
  --sdds-block-background-nested-primary: var(--sdds-grey-868);
  --sdds-block-background-secondary: var(--sdds-grey-868);
  --sdds-block-background-nested-secondary: var(--sdds-grey-900);
  --sdds-block-background: var(--sdds-block-background-primary);
  --sdds-block-background-nested: var(--sdds-block-background-nested-primary)
}

.sdds-mode-dark .sdds-mode-variant-primary {
  --sdds-block-background: var(--sdds-block-background-primary);
  --sdds-block-background-nested: var(--sdds-block-background-nested-primary)
}

.sdds-mode-dark .sdds-mode-variant-secondary {
  --sdds-block-background: var(--sdds-block-background-secondary);
  --sdds-block-background-nested: var(--sdds-block-background-nested-secondary)
}

:root,
.sdds-mode-light {
  --sdds-btn-primary-background: var(--sdds-blue-600);
  --sdds-btn-primary-color: var(--sdds-white);
  --sdds-btn-primary-border-color: transparent;
  --sdds-btn-primary-background-hover: var(--sdds-blue-500);
  --sdds-btn-primary-color-hover: var(--sdds-white);
  --sdds-btn-primary-border-color-hover: transparent;
  --sdds-btn-primary-background-active: var(--sdds-blue-700);
  --sdds-btn-primary-color-active: var(--sdds-white);
  --sdds-btn-primary-border-color-active: var(--sdds-blue-700);
  --sdds-btn-primary-background-focus: var(--sdds-blue-400);
  --sdds-btn-primary-color-focus: var(--sdds-white);
  --sdds-btn-primary-border-color-focus: var(--sdds-blue-600);
  --sdds-btn-primary-outline-color-focus: var(--sdds-blue-600);
  --sdds-btn-primary-background-disabled-primary: var(--sdds-grey-50);
  --sdds-btn-primary-background-disabled-secondary: var(--sdds-white);
  --sdds-btn-primary-background-disabled: var(--sdds-btn-primary-background-disabled-primary);
  --sdds-btn-primary-color-disabled: var(--sdds-grey-400);
  --sdds-btn-primary-border-color-disabled: transparent;
  --sdds-btn-icon-primary-fill: var(--sdds-grey-50);
  --sdds-btn-icon-primary-color: var(--sdds-grey-50);
  --sdds-btn-secondary-background: transparent;
  --sdds-btn-secondary-color: var(--sdds-black);
  --sdds-btn-secondary-border-color: rgb(0 0 0 / 48%);
  --sdds-btn-secondary-background-hover: var(--sdds-blue-500);
  --sdds-btn-secondary-color-hover: var(--sdds-white);
  --sdds-btn-secondary-border-color-hover: var(--sdds-blue-500);
  --sdds-btn-secondary-background-active: transparent;
  --sdds-btn-secondary-color-active: var(--sdds-black);
  --sdds-btn-secondary-border-color-active: rgb(0 0 0 / 87%);
  --sdds-btn-secondary-background-focus: transparent;
  --sdds-btn-secondary-color-focus: var(--sdds-black);
  --sdds-btn-secondary-border-color-focus: var(--sdds-blue-400);
  --sdds-btn-secondary-outline-color: rgb(0 0 0 / 38%);
  --sdds-btn-secondary-outline-color-focus: var(--sdds-blue-400);
  --sdds-btn-secondary-background-disabled: transparent;
  --sdds-btn-secondary-color-disabled: rgb(0 0 0 / 38%);
  --sdds-btn-secondary-border-color-disabled: rgb(0 0 0 / 38%);
  --sdds-btn-icon-secondary-color-focus: var(--sdds-black);
  --sdds-btn-icon-secondary-fill-focus: var(--sdds-black);
  --sdds-btn-icon-secondary-fill: var(--sdds-black);
  --sdds-btn-icon-secondary-color: var(--sdds-black);
  --sdds-btn-icon-secondary-fill-hover: var(--sdds-grey-50);
  --sdds-btn-icon-secondary-color-hover: var(--sdds-grey-50);
  --sdds-btn-ghost-background: transparent;
  --sdds-btn-ghost-color: var(--sdds-black);
  --sdds-btn-ghost-border-color: transparent;
  --sdds-btn-ghost-background-hover: transparent;
  --sdds-btn-ghost-color-hover: var(--sdds-black);
  --sdds-btn-ghost-border-color-hover: rgb(0 0 0 / 60%);
  --sdds-btn-ghost-outline-color-hover: rgb(0 0 0 / 60%);
  --sdds-btn-ghost-background-active: transparent;
  --sdds-btn-ghost-color-active: var(--sdds-black);
  --sdds-btn-ghost-border-color-active: rgb(0 0 0 / 87%);
  --sdds-btn-ghost-background-focus: transparent;
  --sdds-btn-ghost-color-focus: var(--sdds-black);
  --sdds-btn-ghost-border-color-focus: var(--sdds-blue-300);
  --sdds-btn-ghost-outline-color-focus: var(--sdds-blue-300);
  --sdds-btn-ghost-background-disabled: transparent;
  --sdds-btn-ghost-color-disabled: rgb(0 0 0 / 38%);
  --sdds-btn-ghost-border-color-disabled: transparent;
  --sdds-btn-icon-ghost-fill: var(--sdds-black);
  --sdds-btn-icon-ghost-color: var(--sdds-black);
  --sdds-btn-danger-background: var(--sdds-negative);
  --sdds-btn-danger-color: var(--sdds-white);
  --sdds-btn-danger-border-color: transparent;
  --sdds-btn-danger-background-hover: var(--sdds-red-600);
  --sdds-btn-danger-color-hover: var(--sdds-white);
  --sdds-btn-danger-border-color-hover: transparent;
  --sdds-btn-danger-background-active: var(--sdds-red-700);
  --sdds-btn-danger-color-active: var(--sdds-white);
  --sdds-btn-danger-border-color-active: var(--sdds-red-700);
  --sdds-btn-danger-background-focus: var(--sdds-red-400);
  --sdds-btn-danger-color-focus: var(--sdds-white);
  --sdds-btn-danger-border-color-focus: var(--sdds-red-400);
  --sdds-btn-danger-outline-color-focus: var(--sdds-red-700);
  --sdds-btn-danger-background-disabled-primary: var(--sdds-grey-50);
  --sdds-btn-danger-background-disabled-secondary: var(--sdds-white);
  --sdds-btn-danger-background-disabled: var(--sdds-btn-danger-background-disabled-primary);
  --sdds-btn-danger-color-disabled: rgb(0 0 0 / 38%);
  --sdds-btn-danger-border-color-disabled: transparent;
  --sdds-btn-icon-danger-fill: var(--sdds-white);
  --sdds-btn-icon-danger-color: var(--sdds-white)
}

:root .sdds-mode-variant-primary,
.sdds-mode-light .sdds-mode-variant-primary {
  --sdds-btn-danger-background-disabled: var(--sdds-btn-danger-background-disabled-primary);
  --sdds-btn-primary-background-disabled: var(--sdds-btn-primary-background-disabled-primary)
}

:root .sdds-mode-variant-secondary,
.sdds-mode-light .sdds-mode-variant-secondary {
  --sdds-btn-danger-background-disabled: var(--sdds-btn-danger-background-disabled-secondary);
  --sdds-btn-primary-background-disabled: var(--sdds-btn-primary-background-disabled-secondary)
}

.sdds-mode-dark {
  --sdds-btn-primary-background: var(--sdds-blue-400);
  --sdds-btn-primary-background-hover: var(--sdds-blue-500);
  --sdds-btn-primary-background-active: var(--sdds-blue-300);
  --sdds-btn-primary-background-focus: var(--sdds-blue-600);
  --sdds-btn-primary-border-color-focus: var(--sdds-blue-400);
  --sdds-btn-primary-outline-color-focus: var(--sdds-blue-400);
  --sdds-btn-primary-background-disabled-primary: var(--sdds-grey-900);
  --sdds-btn-primary-background-disabled-secondary: var(--sdds-grey-868);
  --sdds-btn-primary-background-disabled: var(--sdds-btn-primary-background-disabled-primary);
  --sdds-btn-primary-color-disabled: var(--sdds-grey-600);
  --sdds-btn-icon-primary-fill: var(--sdds-grey-50);
  --sdds-btn-icon-primary-color: var(--sdds-grey-50);
  --sdds-btn-secondary-background: transparent;
  --sdds-btn-secondary-color: var(--sdds-white);
  --sdds-btn-secondary-border-color: var(--sdds-white);
  --sdds-btn-secondary-background-hover: var(--sdds-blue-500);
  --sdds-btn-secondary-color-hover: var(--sdds-white);
  --sdds-btn-secondary-border-color-hover: var(--sdds-blue-500);
  --sdds-btn-secondary-background-active: transparent;
  --sdds-btn-secondary-color-active: var(--sdds-white);
  --sdds-btn-secondary-border-color-active: var(--sdds-white);
  --sdds-btn-secondary-background-focus: transparent;
  --sdds-btn-secondary-color-focus: var(--sdds-white);
  --sdds-btn-secondary-border-color-focus: var(--sdds-blue-400);
  --sdds-btn-secondary-outline-color-focus: var(--sdds-blue-400);
  --sdds-btn-secondary-outline-color: var(--sdds-white);
  --sdds-btn-secondary-background-disabled: transparent;
  --sdds-btn-secondary-color-disabled: rgb(255 255 255 / 38%);
  --sdds-btn-secondary-border-color-disabled: rgb(255 255 255 / 38%);
  --sdds-btn-icon-secondary-color-focus: var(--sdds-white);
  --sdds-btn-icon-secondary-fill-focus: var(--sdds-white);
  --sdds-btn-icon-secondary-fill: var(--sdds-grey-50);
  --sdds-btn-icon-secondary-color: var(--sdds-grey-50);
  --sdds-btn-ghost-background: transparent;
  --sdds-btn-ghost-color: var(--sdds-white);
  --sdds-btn-ghost-border-color: transparent;
  --sdds-btn-ghost-background-hover: transparent;
  --sdds-btn-ghost-color-hover: var(--sdds-white);
  --sdds-btn-ghost-border-color-hover: rgb(255 255 255 / 41%);
  --sdds-btn-ghost-outline-color-hover: rgb(255 255 255 / 41%);
  --sdds-btn-ghost-background-active: transparent;
  --sdds-btn-ghost-color-active: var(--sdds-white);
  --sdds-btn-ghost-border-color-active: rgb(255 255 255 / 87%);
  --sdds-btn-ghost-background-focus: transparent;
  --sdds-btn-ghost-color-focus: var(--sdds-white);
  --sdds-btn-ghost-border-color-focus: var(--sdds-blue-300);
  --sdds-btn-ghost-outline-color-focus: var(--sdds-blue-300);
  --sdds-btn-ghost-background-disabled: transparent;
  --sdds-btn-ghost-color-disabled: rgb(255 255 255/ 38%);
  --sdds-btn-ghost-border-color-disabled: transparent;
  --sdds-btn-icon-ghost-fill: var(--sdds-grey-50);
  --sdds-btn-icon-ghost-color: var(--sdds-grey-50);
  --sdds-btn-danger-background: var(--sdds-negative);
  --sdds-btn-danger-color: var(--sdds-white);
  --sdds-btn-danger-border-color: transparent;
  --sdds-btn-danger-background-hover: var(--sdds-red-600);
  --sdds-btn-danger-color-hover: var(--sdds-white);
  --sdds-btn-danger-border-color-hover: transparent;
  --sdds-btn-danger-background-active: var(--sdds-red-700);
  --sdds-btn-danger-color-active: var(--sdds-white);
  --sdds-btn-danger-border-color-active: transparent;
  --sdds-btn-danger-background-focus: var(--sdds-red-400);
  --sdds-btn-danger-color-focus: var(--sdds-white);
  --sdds-btn-danger-border-color-focus: var(--sdds-red-400);
  --sdds-btn-danger-outline-color-focus: var(--sdds-red-700);
  --sdds-btn-danger-background-disabled-primary: var(--sdds-grey-900);
  --sdds-btn-danger-background-disabled-secondary: var(--sdds-grey-868);
  --sdds-btn-danger-background-disabled: var(--sdds-btn-danger-background-disabled-primary);
  --sdds-btn-danger-color-disabled: rgb(255 255 255 / 38%);
  --sdds-btn-danger-border-color-disabled: transparent;
  --sdds-btn-icon-danger-fill: var(--sdds-white);
  --sdds-btn-icon-danger-color: var(--sdds-white)
}

.sdds-mode-dark .sdds-mode-variant-primary {
  --sdds-btn-danger-background-disabled: var(--sdds-btn-danger-background-disabled-primary);
  --sdds-btn-primary-background-disabled: var(--sdds-btn-primary-background-disabled-primary)
}

.sdds-mode-dark .sdds-mode-variant-secondary {
  --sdds-btn-danger-background-disabled: var(--sdds-btn-danger-background-disabled-secondary);
  --sdds-btn-primary-background-disabled: var(--sdds-btn-primary-background-disabled-secondary)
}

:root,
html,
.sdds-mode-light {
  --sdds-data-table-color: var(--sdds-grey-958);
  --sdds-data-table-body-cell-background-hover: var(--sdds-grey-200);
  --sdds-data-table-body-row-background: var(--sdds-grey-50);
  --sdds-data-table-body-row-background-hover: var(--sdds-grey-200);
  --sdds-data-table-body-row-background-selected: var(--sdds-grey-300);
  --sdds-data-table-body-row-background-selected-hover: var(--sdds-grey-300);
  --sdds-data-table-footer-background: var(--sdds-grey-100);
  --sdds-data-table-footer-page-selector-input-background: var(--sdds-grey-50);
  --sdds-data-table-footer-page-selector-input-background-hover: var(--sdds-white);
  --sdds-data-table-footer-page-selector-input-background-disabled: var(--sdds-grey-600);
  --sdds-data-table-footer-btn-hover: var(--sdds-grey-300);
  --sdds-data-table-header-background: var(--sdds-grey-300);
  --sdds-data-table-header-background-hover: var(--sdds-grey-400);
  --sdds-data-table-header-btn-color: var(--sdds-grey-958);
  --sdds-data-table-header-btn-background: var(--sdds-grey-500);
  --sdds-data-table-header-btn-background-hover: var(--sdds-grey-600);
  --sdds-data-table-header-btn-focus: var(--sdds-blue-400);
  --sdds-data-table-toolbar-background: var(--sdds-grey-400);
  --sdds-data-table-toolbar-searchbar-background: var(--sdds-grey-50);
  --sdds-data-table-toolbar-searchbar-background-hover: var(--sdds-grey-300);
  --sdds-data-table-divider: var(--sdds-grey-400)
}

:root .sdds-mode-variant-primary,
html .sdds-mode-variant-primary,
.sdds-mode-light .sdds-mode-variant-primary {
  --sdds-data-table-body-row-background: var(--sdds-grey-50);
  --sdds-data-table-body-row-background-hover: var(--sdds-grey-200)
}

:root .sdds-mode-variant-secondary,
html .sdds-mode-variant-secondary,
.sdds-mode-light .sdds-mode-variant-secondary {
  --sdds-data-table-body-row-background: var(--sdds-white);
  --sdds-data-table-body-row-background-hover: var(--sdds-grey-100)
}

.sdds-mode-dark {
  --sdds-data-table-color: var(--sdds-grey-50);
  --sdds-data-table-body-cell-background-hover: var(--sdds-grey-846);
  --sdds-data-table-body-row-background: var(--sdds-grey-868);
  --sdds-data-table-body-row-background-hover: var(--sdds-grey-846);
  --sdds-data-table-body-row-background-selected: var(--sdds-grey-800);
  --sdds-data-table-body-row-background-selected-hover: var(--sdds-grey-700);
  --sdds-data-table-footer-background: var(--sdds-grey-846);
  --sdds-data-table-footer-page-selector-input-background: var(--sdds-grey-900);
  --sdds-data-table-footer-page-selector-input-background-hover: var(--sdds-grey-958);
  --sdds-data-table-footer-page-selector-input-background-disabled: var(--sdds-grey-700);
  --sdds-data-table-footer-btn-hover: var(--sdds-grey-900);
  --sdds-data-table-header-background: var(--sdds-grey-800);
  --sdds-data-table-header-background-hover: var(--sdds-grey-700);
  --sdds-data-table-header-btn-color: var(--sdds-grey-50);
  --sdds-data-table-header-btn-background: var(--sdds-grey-600);
  --sdds-data-table-header-btn-background-hover: var(--sdds-grey-700);
  --sdds-data-table-header-btn-focus: var(--sdds-blue-400);
  --sdds-data-table-toolbar-background: var(--sdds-grey-700);
  --sdds-data-table-toolbar-searchbar-background: var(--sdds-grey-900);
  --sdds-data-table-toolbar-searchbar-background-hover: var(--sdds-grey-800);
  --sdds-data-table-divider: var(--sdds-grey-700)
}

.sdds-mode-dark .sdds-mode-variant-primary {
  --sdds-data-table-body-row-background: var(--sdds-grey-868);
  --sdds-data-table-body-row-background-hover: var(--sdds-grey-846)
}

.sdds-mode-dark .sdds-mode-variant-secondary {
  --sdds-data-table-body-row-background: var(--sdds-grey-900);
  --sdds-data-table-body-row-background-hover: var(--sdds-grey-846)
}

:root,
.sdds-mode-light {
  --sdds-datetime-color: var(--sdds-grey-868);
  --sdds-datetime-background-primary: var(--sdds-grey-50);
  --sdds-datetime-background-secondary: var(--sdds-white);
  --sdds-datetime-background: var(--sdds-datetime-background-primary);
  --sdds-datetime-icon: var(--sdds-grey-868);
  --sdds-datetime-border-bottom: var(--sdds-grey-400);
  --sdds-datetime-placeholder: var(--sdds-grey-500);
  --sdds-datetime-border-bottom-hover: var(--sdds-grey-600);
  --sdds-datetime-background-disabled: var(--sdds-white);
  --sdds-datetime-color-disabled: var(--sdds-grey-400);
  --sdds-datetime-placeholder-disabled: var(--sdds-grey-400);
  --sdds-datetime-label-disabled: var(--sdds-grey-400);
  --sdds-datetime-label-color: var(--sdds-grey-958);
  --sdds-datetime-label-inside-color: var(--sdds-grey-700);
  --sdds-datetime-placeholder-color-focus: var(--sdds-grey-500);
  --sdds-datetime-bar: var(--sdds-blue-400);
  --sdds-datetime-helper: var(--sdds-grey-700);
  --sdds-datetime-border-bottom-success: var(--sdds-grey-800);
  --sdds-datetime-border-bottom-error: var(--sdds-negative);
  --sdds-datetime-helper-error: var(--sdds-negative);
  --sdds-datetime-bar-error: var(--sdds-negative);
  --sdds-datetime-icon-error: var(--sdds-negative);
  --sdds-datetime-textcounter: var(--sdds-grey-700);
  --sdds-datetime-textcounter-divider: var(--sdds-grey-500);
  --sdds-datetime-ps-color: var(--sdds-grey-600)
}

.sdds-mode-dark {
  --sdds-datetime-color: var(--sdds-grey-600);
  --sdds-datetime-background-primary: var(--sdds-grey-900);
  --sdds-datetime-background-secondary: var(--sdds-grey-868);
  --sdds-datetime-background: var(--sdds-datetime-background-primary);
  --sdds-datetime-icon: var(--sdds-grey-50);
  --sdds-datetime-border-bottom: var(--sdds-grey-600);
  --sdds-datetime-placeholder: var(--sdds-grey-500);
  --sdds-datetime-border-bottom-hover: var(--sdds-grey-600);
  --sdds-datetime-background-disabled: var(--sdds-grey-900);
  --sdds-datetime-color-disabled: var(--sdds-grey-700);
  --sdds-datetime-placeholder-disabled: var(--sdds-grey-700);
  --sdds-datetime-label-disabled: var(--sdds-grey-700);
  --sdds-datetime-icon-disabled: var(--sdds-grey-800);
  --sdds-datetime-label-color: var(--sdds-grey-100);
  --sdds-datetime-label-inside-color: var(--sdds-grey-700);
  --sdds-datetime-placeholder-color-focus: var(--sdds-grey-500);
  --sdds-datetime-bar: var(--sdds-blue-400);
  --sdds-datetime-helper: var(--sdds-grey-500);
  --sdds-datetime-border-bottom-success: var(--sdds-grey-50);
  --sdds-datetime-color-success: var(--sdds-grey-50);
  --sdds-datetime-border-bottom-error: var(--sdds-negative);
  --sdds-datetime-helper-error: var(--sdds-negative);
  --sdds-datetime-bar-error: var(--sdds-negative);
  --sdds-datetime-icon-error: var(--sdds-negative);
  --sdds-datetime-textcounter: var(--sdds-grey-700);
  --sdds-datetime-textcounter-divider: var(--sdds-grey-500);
  --sdds-datetime-ps-color: var(--sdds-grey-600)
}

:root {
  --sdds-headline-01: normal normal bold 40px/40px "Scania Sans", arial, helvetica, sans-serif;
  --sdds-headline-01-ls: 0;
  --sdds-headline-02: normal normal bold 32px/32px "Scania Sans", arial, helvetica, sans-serif;
  --sdds-headline-02-ls: -0.04em;
  --sdds-headline-03: normal normal bold 24px/24px "Scania Sans", arial, helvetica, sans-serif;
  --sdds-headline-03-ls: -0.03em;
  --sdds-headline-04: normal normal bold 20px/24px "Scania Sans", arial, helvetica, sans-serif;
  --sdds-headline-04-ls: -0.02em;
  --sdds-headline-05: normal normal bold 14px/24px "Scania Sans", arial, helvetica, sans-serif;
  --sdds-headline-05-ls: -0.02em;
  --sdds-headline-06: normal normal bold 14px/16px "Scania Sans", arial, helvetica, sans-serif;
  --sdds-headline-06-ls: -0.02em;
  --sdds-headline-07: normal normal bold 14px/16px "Scania Sans Semi Condensed", arial, helvetica,
    sans-serif;
  --sdds-headline-07-ls: -0.01em;
  --sdds-paragraph-01: normal normal normal 24px/32px "Scania Sans", arial, helvetica, sans-serif;
  --sdds-paragraph-01-ls: -0.03em;
  --sdds-paragraph-02: normal normal normal 20px/24px "Scania Sans", arial, helvetica, sans-serif;
  --sdds-paragraph-02-ls: -0.01em;
  --sdds-body-01: normal normal normal 16px/24px "Scania Sans", arial, helvetica, sans-serif;
  --sdds-body-01-ls: -0.02em;
  --sdds-body-02: normal normal normal 14px/20px "Scania Sans", arial, helvetica, sans-serif;
  --sdds-body-02-ls: -0.02em;
  --sdds-detail-01: normal normal normal 16px/24px "Scania Sans Semi Condensed",
    "Scania Sans Condensed", arial, helvetica, sans-serif;
  --sdds-detail-01-ls: -0.01em;
  --sdds-detail-02: normal normal normal 14px/16px "Scania Sans Semi Condensed",
    "Scania Sans Condensed", arial, helvetica, sans-serif;
  --sdds-detail-02-ls: -0.01em;
  --sdds-detail-03: normal normal normal 14px/20px "Scania Sans Semi Condensed",
    "Scania Sans Condensed", arial, helvetica, sans-serif;
  --sdds-detail-03-ls: -0.01em;
  --sdds-detail-04: normal normal bold 12px/16px "Scania Sans", arial, helvetica, sans-serif;
  --sdds-detail-04-ls: 0.08em;
  --sdds-detail-04-tt: uppercase;
  --sdds-detail-05: normal normal normal 12px/16px "Scania Sans Semi Condensed",
    "Scania Sans Condensed", arial, helvetica, sans-serif;
  --sdds-detail-05-ls: 0;
  --sdds-detail-06: normal normal bold 10px/16px "Scania Sans", arial, helvetica, sans-serif;
  --sdds-detail-06-ls: 0.08em;
  --sdds-detail-06-tt: uppercase;
  --sdds-detail-07: normal normal normal 10px/8px "Scania Sans Semi Condensed",
    "Scania Sans Condensed", arial, helvetica, sans-serif;
  --sdds-detail-07-ls: 0;
  --sdds-expressive-headline-01: normal normal bold 80px/80px "Scania Sans Headline", arial,
    helvetica, sans-serif;
  --sdds-expressive-headline-01-ls: 0;
  --sdds-expressive-headline-02: normal normal bold 56px/56px "Scania Sans Headline", arial,
    helvetica, sans-serif;
  --sdds-expressive-headline-02-ls: 0;
  --sdds-headline-ex-01: normal normal bold 40px/40px "Scania Sans Headline", arial, helvetica,
    sans-serif;
  --sdds-headline-ex-01-ls: 0;
  --sdds-headline-ex-02: normal normal bold 32px/32px "Scania Sans Headline", arial, helvetica,
    sans-serif;
  --sdds-headline-ex-02-ls: -0.04em;
  --sdds-headline-ex-03: normal normal bold 24px/24px "Scania Sans Headline", arial, helvetica,
    sans-serif;
  --sdds-headline-ex-03-ls: -0.03em;
  --sdds-headline-ex-04: normal normal bold 20px/24px "Scania Sans Headline", arial, helvetica,
    sans-serif;
  --sdds-headline-ex-04-ls: -0.01em;
  --sdds-headline-ex-05: normal normal bold 16px/24px "Scania Sans Headline", arial, helvetica,
    sans-serif;
  --sdds-headline-ex-05-ls: -0.02em;
  --sdds-headline-ex-06: normal normal bold 14px/16px "Scania Sans Headline", arial, helvetica,
    sans-serif;
  --sdds-headline-ex-06-ls: -0.02em;
  --sdds-headline-ex-07: normal normal bold 14px/16px "Scania Sans Semi Condensed", arial, helvetica,
    sans-serif;
  --sdds-headline-ex-07-ls: -0.01em;
  --sdds-paragraph-ex-01: normal normal normal 24px/32px "Scania Sans", arial, helvetica, sans-serif;
  --sdds-paragraph-ex-01-ls: -0.03em;
  --sdds-paragraph-ex-02: normal normal normal 20px/24px "Scania Sans", arial, helvetica, sans-serif;
  --sdds-paragraph-ex-02-ls: -0.01em;
  --sdds-body-ex-01: normal normal normal 16px/24px "Scania Sans", arial, helvetica, sans-serif;
  --sdds-body-ex-01-ls: -0.02em;
  --sdds-body-ex-02: normal normal normal 14px/20px "Scania Sans", arial, helvetica, sans-serif;
  --sdds-body-ex-02-ls: -0.01em;
  --sdds-detail-ex-01: normal normal normal 16px/24px "Scania Sans Semi Condensed",
    "Scania Sans Condensed", arial, helvetica, sans-serif;
  --sdds-detail-ex-01-ls: -0.01em;
  --sdds-detail-ex-02: normal normal normal 14px/16px "Scania Sans Semi Condensed",
    "Scania Sans Condensed", arial, helvetica, sans-serif;
  --sdds-detail-ex-02-ls: -0.01em;
  --sdds-detail-ex-03: normal normal normal 14px/20px "Scania Sans Semi Condensed",
    "Scania Sans Condensed", arial, helvetica, sans-serif;
  --sdds-detail-ex-03-ls: -0.01em;
  --sdds-detail-ex-04: normal normal bold 12px/16px "Scania Sans", arial, helvetica, sans-serif;
  --sdds-detail-ex-04-ls: 0.08em;
  --sdds-detail-ex-04-tt: uppercase;
  --sdds-detail-ex-05: normal normal normal 12px/16px "Scania Sans Semi Condensed",
    "Scania Sans Condensed", arial, helvetica, sans-serif;
  --sdds-detail-ex-05-ls: 0;
  --sdds-detail-ex-06: normal normal bold 10px/16px "Scania Sans", arial, helvetica, sans-serif;
  --sdds-detail-ex-06-ls: 0.08em;
  --sdds-detail-ex-06-tt: uppercase;
  --sdds-detail-ex-07: normal normal normal 10px/8px "Scania Sans Semi Condensed",
    "Scania Sans Condensed", arial, helvetica, sans-serif;
  --sdds-detail-ex-07-ls: 0
}

:root,
.sdds-mode-light {
  --sdds-link-text-decoration: none;
  --sdds-dropdown-color: var(--sdds-grey-958);
  --sdds-dropdown-background: var(--sdds-grey-50);
  --sdds-dropdown-background-hover: var(--sdds-grey-100);
  --sdds-dropdown-disabled: var(--sdds-grey-400);
  --sdds-dropdown-border: var(--sdds-grey-100);
  --sdds-dropdown-label-inside: var(--sdds-grey-700);
  --sdds-dropdown-label-outside: var(--sdds-grey-958);
  --sdds-dropdown-placeholder: var(--sdds-grey-700);
  --sdds-dropdown-border-not-focused: var(--sdds-grey-600);
  --sdds-dropdown-option-color: var(--sdds-grey-900);
  --sdds-dropdown-option-disabled-color: var(--sdds-grey-400);
  --sdds-dropdown-background-disabled: var(--sdds-dropdown-background)
}

:root .sdds-mode-variant-primary,
.sdds-mode-light .sdds-mode-variant-primary {
  --sdds-dropdown-background: var(--sdds-grey-50);
  --sdds-dropdown-background-hover: var(--sdds-grey-100)
}

:root .sdds-mode-variant-secondary,
.sdds-mode-light .sdds-mode-variant-secondary {
  --sdds-dropdown-background: var(--sdds-white);
  --sdds-dropdown-background-hover: var(--sdds-grey-50)
}

.sdds-mode-dark {
  --sdds-link-text-decoration: none;
  --sdds-dropdown-color: var(--sdds-grey-400);
  --sdds-dropdown-background: var(--sdds-grey-868);
  --sdds-dropdown-background-hover: var(--sdds-grey-800);
  --sdds-dropdown-disabled: var(--sdds-grey-800);
  --sdds-dropdown-border: var(--sdds-grey-800);
  --sdds-dropdown-label-inside: var(--sdds-grey-400);
  --sdds-dropdown-label-outside: var(--sdds-grey-100);
  --sdds-dropdown-placeholder: var(--sdds-grey-400);
  --sdds-dropdown-border-not-focused: var(--sdds-grey-600);
  --sdds-dropdown-option-color: var(--sdds-grey-50);
  --sdds-dropdown-option-disabled-color: var(--sdds-grey-800);
  --sdds-dropdown-background-disabled: var(--sdds-dropdown-background)
}

.sdds-mode-dark .sdds-mode-variant-primary {
  --sdds-dropdown-background: var(--sdds-grey-868);
  --sdds-dropdown-background-hover: var(--sdds-grey-800)
}

.sdds-mode-dark .sdds-mode-variant-secondary {
  --sdds-dropdown-background: var(--sdds-grey-900);
  --sdds-dropdown-background-hover: var(--sdds-grey-800)
}

.sdds-dropdown-helper {
  font: var(--sdds-detail-ex-05);
  letter-spacing: var(--sdds-detail-ex-05-ls);
  color: var(--sdds-grey-700);
  margin-top: var(--sdds-spacing-element-4);
  display: flex
}

.sdds-dropdown-helper .sdds-dropdown-error-icon {
  width: 16px;
  height: 16px;
  padding-right: 9px;
  display: none
}

.sdds-dropdown-label-inside {
  font: var(--sdds-detail-ex-07);
  letter-spacing: var(--sdds-detail-ex-07-ls);
  color: var(--sdds-dropdown-label-inside)
}

.sdds-dropdown-label-outside {
  font: var(--sdds-detail-ex-05);
  letter-spacing: var(--sdds-detail-ex-05-ls);
  display: block;
  color: var(--sdds-dropdown-label-outside);
  margin-bottom: var(--sdds-spacing-element-8)
}

:root {
  --sdds-headline-01: normal normal bold 40px/40px "Scania Sans", arial, helvetica, sans-serif;
  --sdds-headline-01-ls: 0;
  --sdds-headline-02: normal normal bold 32px/32px "Scania Sans", arial, helvetica, sans-serif;
  --sdds-headline-02-ls: -0.04em;
  --sdds-headline-03: normal normal bold 24px/24px "Scania Sans", arial, helvetica, sans-serif;
  --sdds-headline-03-ls: -0.03em;
  --sdds-headline-04: normal normal bold 20px/24px "Scania Sans", arial, helvetica, sans-serif;
  --sdds-headline-04-ls: -0.02em;
  --sdds-headline-05: normal normal bold 14px/24px "Scania Sans", arial, helvetica, sans-serif;
  --sdds-headline-05-ls: -0.02em;
  --sdds-headline-06: normal normal bold 14px/16px "Scania Sans", arial, helvetica, sans-serif;
  --sdds-headline-06-ls: -0.02em;
  --sdds-headline-07: normal normal bold 14px/16px "Scania Sans Semi Condensed", arial, helvetica,
    sans-serif;
  --sdds-headline-07-ls: -0.01em;
  --sdds-paragraph-01: normal normal normal 24px/32px "Scania Sans", arial, helvetica, sans-serif;
  --sdds-paragraph-01-ls: -0.03em;
  --sdds-paragraph-02: normal normal normal 20px/24px "Scania Sans", arial, helvetica, sans-serif;
  --sdds-paragraph-02-ls: -0.01em;
  --sdds-body-01: normal normal normal 16px/24px "Scania Sans", arial, helvetica, sans-serif;
  --sdds-body-01-ls: -0.02em;
  --sdds-body-02: normal normal normal 14px/20px "Scania Sans", arial, helvetica, sans-serif;
  --sdds-body-02-ls: -0.02em;
  --sdds-detail-01: normal normal normal 16px/24px "Scania Sans Semi Condensed",
    "Scania Sans Condensed", arial, helvetica, sans-serif;
  --sdds-detail-01-ls: -0.01em;
  --sdds-detail-02: normal normal normal 14px/16px "Scania Sans Semi Condensed",
    "Scania Sans Condensed", arial, helvetica, sans-serif;
  --sdds-detail-02-ls: -0.01em;
  --sdds-detail-03: normal normal normal 14px/20px "Scania Sans Semi Condensed",
    "Scania Sans Condensed", arial, helvetica, sans-serif;
  --sdds-detail-03-ls: -0.01em;
  --sdds-detail-04: normal normal bold 12px/16px "Scania Sans", arial, helvetica, sans-serif;
  --sdds-detail-04-ls: 0.08em;
  --sdds-detail-04-tt: uppercase;
  --sdds-detail-05: normal normal normal 12px/16px "Scania Sans Semi Condensed",
    "Scania Sans Condensed", arial, helvetica, sans-serif;
  --sdds-detail-05-ls: 0;
  --sdds-detail-06: normal normal bold 10px/16px "Scania Sans", arial, helvetica, sans-serif;
  --sdds-detail-06-ls: 0.08em;
  --sdds-detail-06-tt: uppercase;
  --sdds-detail-07: normal normal normal 10px/8px "Scania Sans Semi Condensed",
    "Scania Sans Condensed", arial, helvetica, sans-serif;
  --sdds-detail-07-ls: 0;
  --sdds-expressive-headline-01: normal normal bold 80px/80px "Scania Sans Headline", arial,
    helvetica, sans-serif;
  --sdds-expressive-headline-01-ls: 0;
  --sdds-expressive-headline-02: normal normal bold 56px/56px "Scania Sans Headline", arial,
    helvetica, sans-serif;
  --sdds-expressive-headline-02-ls: 0;
  --sdds-headline-ex-01: normal normal bold 40px/40px "Scania Sans Headline", arial, helvetica,
    sans-serif;
  --sdds-headline-ex-01-ls: 0;
  --sdds-headline-ex-02: normal normal bold 32px/32px "Scania Sans Headline", arial, helvetica,
    sans-serif;
  --sdds-headline-ex-02-ls: -0.04em;
  --sdds-headline-ex-03: normal normal bold 24px/24px "Scania Sans Headline", arial, helvetica,
    sans-serif;
  --sdds-headline-ex-03-ls: -0.03em;
  --sdds-headline-ex-04: normal normal bold 20px/24px "Scania Sans Headline", arial, helvetica,
    sans-serif;
  --sdds-headline-ex-04-ls: -0.01em;
  --sdds-headline-ex-05: normal normal bold 16px/24px "Scania Sans Headline", arial, helvetica,
    sans-serif;
  --sdds-headline-ex-05-ls: -0.02em;
  --sdds-headline-ex-06: normal normal bold 14px/16px "Scania Sans Headline", arial, helvetica,
    sans-serif;
  --sdds-headline-ex-06-ls: -0.02em;
  --sdds-headline-ex-07: normal normal bold 14px/16px "Scania Sans Semi Condensed", arial, helvetica,
    sans-serif;
  --sdds-headline-ex-07-ls: -0.01em;
  --sdds-paragraph-ex-01: normal normal normal 24px/32px "Scania Sans", arial, helvetica, sans-serif;
  --sdds-paragraph-ex-01-ls: -0.03em;
  --sdds-paragraph-ex-02: normal normal normal 20px/24px "Scania Sans", arial, helvetica, sans-serif;
  --sdds-paragraph-ex-02-ls: -0.01em;
  --sdds-body-ex-01: normal normal normal 16px/24px "Scania Sans", arial, helvetica, sans-serif;
  --sdds-body-ex-01-ls: -0.02em;
  --sdds-body-ex-02: normal normal normal 14px/20px "Scania Sans", arial, helvetica, sans-serif;
  --sdds-body-ex-02-ls: -0.01em;
  --sdds-detail-ex-01: normal normal normal 16px/24px "Scania Sans Semi Condensed",
    "Scania Sans Condensed", arial, helvetica, sans-serif;
  --sdds-detail-ex-01-ls: -0.01em;
  --sdds-detail-ex-02: normal normal normal 14px/16px "Scania Sans Semi Condensed",
    "Scania Sans Condensed", arial, helvetica, sans-serif;
  --sdds-detail-ex-02-ls: -0.01em;
  --sdds-detail-ex-03: normal normal normal 14px/20px "Scania Sans Semi Condensed",
    "Scania Sans Condensed", arial, helvetica, sans-serif;
  --sdds-detail-ex-03-ls: -0.01em;
  --sdds-detail-ex-04: normal normal bold 12px/16px "Scania Sans", arial, helvetica, sans-serif;
  --sdds-detail-ex-04-ls: 0.08em;
  --sdds-detail-ex-04-tt: uppercase;
  --sdds-detail-ex-05: normal normal normal 12px/16px "Scania Sans Semi Condensed",
    "Scania Sans Condensed", arial, helvetica, sans-serif;
  --sdds-detail-ex-05-ls: 0;
  --sdds-detail-ex-06: normal normal bold 10px/16px "Scania Sans", arial, helvetica, sans-serif;
  --sdds-detail-ex-06-ls: 0.08em;
  --sdds-detail-ex-06-tt: uppercase;
  --sdds-detail-ex-07: normal normal normal 10px/8px "Scania Sans Semi Condensed",
    "Scania Sans Condensed", arial, helvetica, sans-serif;
  --sdds-detail-ex-07-ls: 0
}

.sdds-dropdown {
  box-sizing: border-box;
  position: relative
}

.sdds-dropdown * {
  box-sizing: border-box
}

.sdds-dropdown select {
  font: var(--sdds-detail-ex-02);
  letter-spacing: var(--sdds-detail-ex-02-ls);
  color: var(--sdds-dropdown-color);
  display: flex;
  align-items: center;
  background-color: var(--sdds-dropdown-background);
  cursor: pointer;
  transition: box-shadow 0.1s ease-in, border-bottom-color 150ms ease;
  border-radius: 4px 4px 0 0;
  border: none;
  border-bottom: 1px solid var(--sdds-dropdown-border-not-focused);
  box-shadow: none;
  width: 100%;
  text-align: left;
  outline: none;
  padding: 20px 16px;
  color: var(--sdds-dropdown-color);
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg class='sdds-dropdown-arrow' width='12' height='7' viewBox='0 0 12 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L6 6L11 1' stroke='currentColor' stroke-width='1.25' stroke-linecap='round' stroke-linejoin='round' /%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 16px center
}

.sdds-dropdown select:hover {
  border-bottom-color: var(--sdds-grey-600)
}

.sdds-dropdown select:focus::before {
  content: " ";
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 0;
  width: 100%;
  border-bottom: 2px solid var(--sdds-blue-400)
}

.sdds-dropdown select:active,
.sdds-dropdown select.active .sdds-dropdown select.selected {
  border-bottom-color: var(--sdds-dropdown-border-not-focused)
}

.sdds-dropdown select.is-filter {
  transition: border 0.1s ease-in 0.1s;
  cursor: text
}

.sdds-dropdown select:focus {
  border-bottom: 2px solid var(--sdds-blue-400)
}

.sdds-dropdown select:disabled {
  cursor: not-allowed;
  border-bottom: none;
  color: var(--sdds-dropdown-option-disabled-color);
  pointer-events: none;
  background-image: url("data:image/svg+xml,%3Csvg class='sdds-dropdown-arrow' width='12' height='7' viewBox='0 0 12 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L6 6L11 1' stroke='%23cdd1db' stroke-width='1.25' stroke-linecap='round' stroke-linejoin='round' /%3E%3C/svg%3E")
}

.sdds-dropdown.sdds-dropdown-medium select,
.sdds-dropdown.sdds-dropdown select {
  padding: 20px
}

.sdds-dropdown.sdds-dropdown-medium select,
.sdds-dropdown.sdds-dropdown-md select {
  padding: 16px
}

.sdds-dropdown.sdds-dropdown-small select,
.sdds-dropdown.sdds-dropdown-sm select {
  padding: 12px 16px
}

.sdds-dropdown.sdds-dropdown--error select {
  border-color: var(--sdds-negative)
}

.sdds-dropdown.sdds-dropdown--error .sdds-dropdown-helper {
  color: var(--sdds-negative)
}

.sdds-mode-dark select {
  background-image: url("data:image/svg+xml,%3Csvg class='sdds-dropdown-arrow' width='12' height='7' viewBox='0 0 12 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L6 6L11 1' stroke='%23cdd1db' stroke-width='1.25' stroke-linecap='round' stroke-linejoin='round' /%3E%3C/svg%3E")
}

:root {
  --sdds-headline-01: normal normal bold 40px/40px "Scania Sans", arial, helvetica, sans-serif;
  --sdds-headline-01-ls: 0;
  --sdds-headline-02: normal normal bold 32px/32px "Scania Sans", arial, helvetica, sans-serif;
  --sdds-headline-02-ls: -0.04em;
  --sdds-headline-03: normal normal bold 24px/24px "Scania Sans", arial, helvetica, sans-serif;
  --sdds-headline-03-ls: -0.03em;
  --sdds-headline-04: normal normal bold 20px/24px "Scania Sans", arial, helvetica, sans-serif;
  --sdds-headline-04-ls: -0.02em;
  --sdds-headline-05: normal normal bold 14px/24px "Scania Sans", arial, helvetica, sans-serif;
  --sdds-headline-05-ls: -0.02em;
  --sdds-headline-06: normal normal bold 14px/16px "Scania Sans", arial, helvetica, sans-serif;
  --sdds-headline-06-ls: -0.02em;
  --sdds-headline-07: normal normal bold 14px/16px "Scania Sans Semi Condensed", arial, helvetica,
    sans-serif;
  --sdds-headline-07-ls: -0.01em;
  --sdds-paragraph-01: normal normal normal 24px/32px "Scania Sans", arial, helvetica, sans-serif;
  --sdds-paragraph-01-ls: -0.03em;
  --sdds-paragraph-02: normal normal normal 20px/24px "Scania Sans", arial, helvetica, sans-serif;
  --sdds-paragraph-02-ls: -0.01em;
  --sdds-body-01: normal normal normal 16px/24px "Scania Sans", arial, helvetica, sans-serif;
  --sdds-body-01-ls: -0.02em;
  --sdds-body-02: normal normal normal 14px/20px "Scania Sans", arial, helvetica, sans-serif;
  --sdds-body-02-ls: -0.02em;
  --sdds-detail-01: normal normal normal 16px/24px "Scania Sans Semi Condensed",
    "Scania Sans Condensed", arial, helvetica, sans-serif;
  --sdds-detail-01-ls: -0.01em;
  --sdds-detail-02: normal normal normal 14px/16px "Scania Sans Semi Condensed",
    "Scania Sans Condensed", arial, helvetica, sans-serif;
  --sdds-detail-02-ls: -0.01em;
  --sdds-detail-03: normal normal normal 14px/20px "Scania Sans Semi Condensed",
    "Scania Sans Condensed", arial, helvetica, sans-serif;
  --sdds-detail-03-ls: -0.01em;
  --sdds-detail-04: normal normal bold 12px/16px "Scania Sans", arial, helvetica, sans-serif;
  --sdds-detail-04-ls: 0.08em;
  --sdds-detail-04-tt: uppercase;
  --sdds-detail-05: normal normal normal 12px/16px "Scania Sans Semi Condensed",
    "Scania Sans Condensed", arial, helvetica, sans-serif;
  --sdds-detail-05-ls: 0;
  --sdds-detail-06: normal normal bold 10px/16px "Scania Sans", arial, helvetica, sans-serif;
  --sdds-detail-06-ls: 0.08em;
  --sdds-detail-06-tt: uppercase;
  --sdds-detail-07: normal normal normal 10px/8px "Scania Sans Semi Condensed",
    "Scania Sans Condensed", arial, helvetica, sans-serif;
  --sdds-detail-07-ls: 0;
  --sdds-expressive-headline-01: normal normal bold 80px/80px "Scania Sans Headline", arial,
    helvetica, sans-serif;
  --sdds-expressive-headline-01-ls: 0;
  --sdds-expressive-headline-02: normal normal bold 56px/56px "Scania Sans Headline", arial,
    helvetica, sans-serif;
  --sdds-expressive-headline-02-ls: 0;
  --sdds-headline-ex-01: normal normal bold 40px/40px "Scania Sans Headline", arial, helvetica,
    sans-serif;
  --sdds-headline-ex-01-ls: 0;
  --sdds-headline-ex-02: normal normal bold 32px/32px "Scania Sans Headline", arial, helvetica,
    sans-serif;
  --sdds-headline-ex-02-ls: -0.04em;
  --sdds-headline-ex-03: normal normal bold 24px/24px "Scania Sans Headline", arial, helvetica,
    sans-serif;
  --sdds-headline-ex-03-ls: -0.03em;
  --sdds-headline-ex-04: normal normal bold 20px/24px "Scania Sans Headline", arial, helvetica,
    sans-serif;
  --sdds-headline-ex-04-ls: -0.01em;
  --sdds-headline-ex-05: normal normal bold 16px/24px "Scania Sans Headline", arial, helvetica,
    sans-serif;
  --sdds-headline-ex-05-ls: -0.02em;
  --sdds-headline-ex-06: normal normal bold 14px/16px "Scania Sans Headline", arial, helvetica,
    sans-serif;
  --sdds-headline-ex-06-ls: -0.02em;
  --sdds-headline-ex-07: normal normal bold 14px/16px "Scania Sans Semi Condensed", arial, helvetica,
    sans-serif;
  --sdds-headline-ex-07-ls: -0.01em;
  --sdds-paragraph-ex-01: normal normal normal 24px/32px "Scania Sans", arial, helvetica, sans-serif;
  --sdds-paragraph-ex-01-ls: -0.03em;
  --sdds-paragraph-ex-02: normal normal normal 20px/24px "Scania Sans", arial, helvetica, sans-serif;
  --sdds-paragraph-ex-02-ls: -0.01em;
  --sdds-body-ex-01: normal normal normal 16px/24px "Scania Sans", arial, helvetica, sans-serif;
  --sdds-body-ex-01-ls: -0.02em;
  --sdds-body-ex-02: normal normal normal 14px/20px "Scania Sans", arial, helvetica, sans-serif;
  --sdds-body-ex-02-ls: -0.01em;
  --sdds-detail-ex-01: normal normal normal 16px/24px "Scania Sans Semi Condensed",
    "Scania Sans Condensed", arial, helvetica, sans-serif;
  --sdds-detail-ex-01-ls: -0.01em;
  --sdds-detail-ex-02: normal normal normal 14px/16px "Scania Sans Semi Condensed",
    "Scania Sans Condensed", arial, helvetica, sans-serif;
  --sdds-detail-ex-02-ls: -0.01em;
  --sdds-detail-ex-03: normal normal normal 14px/20px "Scania Sans Semi Condensed",
    "Scania Sans Condensed", arial, helvetica, sans-serif;
  --sdds-detail-ex-03-ls: -0.01em;
  --sdds-detail-ex-04: normal normal bold 12px/16px "Scania Sans", arial, helvetica, sans-serif;
  --sdds-detail-ex-04-ls: 0.08em;
  --sdds-detail-ex-04-tt: uppercase;
  --sdds-detail-ex-05: normal normal normal 12px/16px "Scania Sans Semi Condensed",
    "Scania Sans Condensed", arial, helvetica, sans-serif;
  --sdds-detail-ex-05-ls: 0;
  --sdds-detail-ex-06: normal normal bold 10px/16px "Scania Sans", arial, helvetica, sans-serif;
  --sdds-detail-ex-06-ls: 0.08em;
  --sdds-detail-ex-06-tt: uppercase;
  --sdds-detail-ex-07: normal normal normal 10px/8px "Scania Sans Semi Condensed",
    "Scania Sans Condensed", arial, helvetica, sans-serif;
  --sdds-detail-ex-07-ls: 0
}

:root,
.sdds-mode-light {
  --sdds-link-text-decoration: none;
  --sdds-dropdown-color: var(--sdds-grey-958);
  --sdds-dropdown-background: var(--sdds-grey-50);
  --sdds-dropdown-background-hover: var(--sdds-grey-100);
  --sdds-dropdown-disabled: var(--sdds-grey-400);
  --sdds-dropdown-border: var(--sdds-grey-100);
  --sdds-dropdown-label-inside: var(--sdds-grey-700);
  --sdds-dropdown-label-outside: var(--sdds-grey-958);
  --sdds-dropdown-placeholder: var(--sdds-grey-700);
  --sdds-dropdown-border-not-focused: var(--sdds-grey-600);
  --sdds-dropdown-option-color: var(--sdds-grey-900);
  --sdds-dropdown-option-disabled-color: var(--sdds-grey-400);
  --sdds-dropdown-background-disabled: var(--sdds-dropdown-background)
}

:root .sdds-mode-variant-primary,
.sdds-mode-light .sdds-mode-variant-primary {
  --sdds-dropdown-background: var(--sdds-grey-50);
  --sdds-dropdown-background-hover: var(--sdds-grey-100)
}

:root .sdds-mode-variant-secondary,
.sdds-mode-light .sdds-mode-variant-secondary {
  --sdds-dropdown-background: var(--sdds-white);
  --sdds-dropdown-background-hover: var(--sdds-grey-50)
}

.sdds-mode-dark {
  --sdds-link-text-decoration: none;
  --sdds-dropdown-color: var(--sdds-grey-400);
  --sdds-dropdown-background: var(--sdds-grey-868);
  --sdds-dropdown-background-hover: var(--sdds-grey-800);
  --sdds-dropdown-disabled: var(--sdds-grey-800);
  --sdds-dropdown-border: var(--sdds-grey-800);
  --sdds-dropdown-label-inside: var(--sdds-grey-400);
  --sdds-dropdown-label-outside: var(--sdds-grey-100);
  --sdds-dropdown-placeholder: var(--sdds-grey-400);
  --sdds-dropdown-border-not-focused: var(--sdds-grey-600);
  --sdds-dropdown-option-color: var(--sdds-grey-50);
  --sdds-dropdown-option-disabled-color: var(--sdds-grey-800);
  --sdds-dropdown-background-disabled: var(--sdds-dropdown-background)
}

.sdds-mode-dark .sdds-mode-variant-primary {
  --sdds-dropdown-background: var(--sdds-grey-868);
  --sdds-dropdown-background-hover: var(--sdds-grey-800)
}

.sdds-mode-dark .sdds-mode-variant-secondary {
  --sdds-dropdown-background: var(--sdds-grey-900);
  --sdds-dropdown-background-hover: var(--sdds-grey-800)
}

:root,
.sdds-mode-light {
  --sdds-modal-backdrop: rgba(0 0 0 / 40%);
  --sdds-modal-background: var(--sdds-white);
  --sdds-modal-text: var(--sdds-grey-958);
  --sdds-modal-icon: var(--sdds-black);
  --sdds-modal-icon-native: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3.40338 2.34308C3.11048 2.05019 2.63561 2.05019 2.34272 2.34308C2.04982 2.63598 2.04982 3.11085 2.34272 3.40374L6.93897 8L2.34283 12.5961C2.04994 12.889 2.04994 13.3639 2.34283 13.6568C2.63572 13.9497 3.1106 13.9497 3.40349 13.6568L7.99963 9.06066L12.5958 13.6568C12.8887 13.9497 13.3635 13.9497 13.6564 13.6568C13.9493 13.3639 13.9493 12.889 13.6564 12.5961L9.06029 8L13.6565 3.40376C13.9494 3.11086 13.9494 2.63599 13.6565 2.3431C13.3636 2.0502 12.8888 2.0502 12.5959 2.3431L7.99963 6.93934L3.40338 2.34308Z' fill='black'/%3E%3C/svg%3E%0A");
  --sdds-modal-scrollbar: var(--sdds-grey-300);
  --sdds-modal-scrollbar-track: var(--sdds-grey-300);
  --sdds-modal-scrollbar-thumb: var(--sdds-grey-500)
}

.sdds-mode-dark {
  --sdds-modal-backdrop: rgba(0 0 0 / 40%);
  --sdds-modal-background: var(--sdds-grey-900);
  --sdds-modal-text: var(--sdds-grey-50);
  --sdds-modal-icon: var(--sdds-white);
  --sdds-modal-icon-native: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3.40338 2.34308C3.11048 2.05019 2.63561 2.05019 2.34272 2.34308C2.04982 2.63598 2.04982 3.11085 2.34272 3.40374L6.93897 8L2.34283 12.5961C2.04994 12.889 2.04994 13.3639 2.34283 13.6568C2.63572 13.9497 3.1106 13.9497 3.40349 13.6568L7.99963 9.06066L12.5958 13.6568C12.8887 13.9497 13.3635 13.9497 13.6564 13.6568C13.9493 13.3639 13.9493 12.889 13.6564 12.5961L9.06029 8L13.6565 3.40376C13.9494 3.11086 13.9494 2.63599 13.6565 2.3431C13.3636 2.0502 12.8888 2.0502 12.5959 2.3431L7.99963 6.93934L3.40338 2.34308Z' fill='white'/%3E%3C/svg%3E%0A");
  --sdds-modal-scrollbar: var(--sdds-grey-300);
  --sdds-modal-scrollbar-track: var(--sdds-grey-300);
  --sdds-modal-scrollbar-thumb: var(--sdds-grey-500)
}

.sdds-modal {
  box-sizing: border-box;
  background-color: var(--sdds-modal-background);
  margin: auto;
  position: relative;
  border-radius: 4px;
  padding: 16px;
  max-height: 85vh;
  overflow-y: auto;
}

.sdds-modal * {
  box-sizing: border-box
}

.sdds-modal::-webkit-scrollbar {
  width: 5px;
  background-color: var(--sdds-grey-300);
  border-radius: 0 1em 1em 0
}

.sdds-modal::-webkit-scrollbar-track {
  background-color: var(--sdds-grey-300);
  border-radius: 0 1em 1em 0
}

.sdds-modal::-webkit-scrollbar-thumb {
  background-color: var(--sdds-grey-500);
  border-radius: 0 1em 1em 0
}

.sdds-modal__actions-sticky {
  overflow: hidden
}

.sdds-modal__actions-sticky .sdds-modal-body {
  font: var(--sdds-body-01);
  letter-spacing: var(--sdds-body-01-ls);
  padding-bottom: 96px;
  margin: 0 -16px 0 0;
  max-height: calc(85vh - 36px);
  overflow-y: auto
}

.sdds-modal__actions-sticky .sdds-modal-actions {
  position: absolute;
  bottom: -1px;
  left: 0;
  right: 0;
  background-color: var(--sdds-modal-background);
  padding: var(--sdds-spacing-element-16);
  display: flex;
  gap: 16px
}

.sdds-modal__actions-static .sdds-modal-actions {
  background-color: var(--sdds-modal-background);
  display: flex;
  gap: 16px
}

@media (min-width: 320px) {
  .sdds-modal-xs {
    width: 100%
  }

  .sdds-modal-sm {
    width: 100%
  }

  .sdds-modal-md {
    width: 100%
  }

  .sdds-modal-lg {
    width: 100%
  }
}

@media (min-width: 672px) {
  .sdds-modal-xs {
    width: 75%
  }

  .sdds-modal-sm {
    width: 62.5%
  }

  .sdds-modal-md {
    width: 75%
  }

  .sdds-modal-lg {
    width: 100%
  }
}

@media (min-width: 1056px) {
  .sdds-modal-xs {
    width: 31.25%
  }

  .sdds-modal-sm {
    width: 43.75%
  }

  .sdds-modal-md {
    width: 62.5%
  }

  .sdds-modal-lg {
    width: 75%
  }
}

@media (min-width: 1312px) {
  .sdds-modal-xs {
    width: 31.25%
  }

  .sdds-modal-sm {
    width: 37.5%
  }

  .sdds-modal-md {
    width: 62.5%
  }

  .sdds-modal-lg {
    width: 75%
  }
}

@media (min-width: 1584px) {
  .sdds-modal-xs {
    width: 25%
  }

  .sdds-modal-sm {
    width: 37.5%
  }

  .sdds-modal-md {
    width: 50%
  }

  .sdds-modal-lg {
    width: 75%
  }
}

@media (max-width: 320px) {

  .sdds-modal-md,
  .sdds-modal-lg,
  .sdds-modal-sm {
    height: 100%
  }

  .sdds-modal-md slot[name=sdds-modal-actions]::slotted(*),
  .sdds-modal-lg slot[name=sdds-modal-actions]::slotted(*),
  .sdds-modal-sm slot[name=sdds-modal-actions]::slotted(*) {
    display: flex
  }
}

.sdds-modal-header {
  display: flex;
  padding-bottom: var(--sdds-spacing-element-8);
  position: sticky;
  top: 0;
  background-color: var(--sdds-modal-background);
  z-index: 1
}

.sdds-modal-header::before {
  content: " ";
  position: absolute;
  height: 16px;
  top: -16px;
  width: 100%;
  background-color: var(--sdds-modal-background)
}

.sdds-modal-headline {
  color: var(--sdds-modal-text);
  font: var(--sdds-headline-05);
  letter-spacing: var(--sdds-headline-05-ls);
  margin: 0;
  flex: 1;
  color: var(--sdds-modal-text)
}

.sdds-modal-body {
  color: var(--sdds-modal-text);
  font: var(--sdds-body-01);
  letter-spacing: var(--sdds-body-01-ls);
  padding-bottom: 40px;
  overflow-y: visible;
  padding-right: 16px;
}

.sdds-modal-body::-webkit-scrollbar {
  width: 5px;
  background-color: var(--sdds-grey-300)
}

.sdds-modal-body::-webkit-scrollbar-track {
  background-color: var(--sdds-grey-300)
}

.sdds-modal-body::-webkit-scrollbar-thumb {
  background-color: var(--sdds-grey-500)
}

@media (min-width: 1056px) {
  .sdds-modal-body {
    padding-right: 64px
  }
}

.sdds-modal-backdrop {
  box-sizing: border-box;
  left: 0;
  top: 0;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 600;
  background-color: var(--sdds-modal-backdrop);
  padding: 0 16px
}

.sdds-modal-backdrop * {
  box-sizing: border-box
}

@media (max-width: 320px) {
  .sdds-modal-backdrop {
    padding: 0
  }
}

.sdds-modal-backdrop.show {
  display: flex
}

.sdds-modal-backdrop.hide {
  display: none
}

button.sdds-modal-close {
  background-color: transparent;
  border: 0;
  padding: 0;
  appearance: unset
}

.sdds-modal-close {
  display: inline-block;
  height: auto;
  color: var(--sdds-modal-icon);
  cursor: pointer
}

.sdds-modal-close:focus {
  outline: 2px solid var(--sdds-blue-400);
  outline-offset: -2px
}

@media (min-width: 320px) {
  .sdds-modal-close {
    margin-left: var(--sdds-spacing-element-16)
  }
}

@media (min-width: 1056px) {
  .sdds-modal-close {
    margin-left: var(--sdds-spacing-element-48)
  }
}

.sdds-modal-close-btn {
  display: inline-block;
  height: auto;
  background-repeat: no-repeat;
  cursor: pointer
}

@media (min-width: 320px) {
  .sdds-modal-close-btn {
    margin-left: var(--sdds-spacing-element-16)
  }
}

@media (min-width: 1056px) {
  .sdds-modal-close-btn {
    margin-left: var(--sdds-spacing-element-48)
  }
}

.sdds-modal-close-btn svg {
  fill: var(--sdds-modal-icon)
}

.sdds-modal-overflow {
  overflow: hidden
}

:host {
  left: 0;
  top: 0;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 600;
  background-color: var(--sdds-modal-backdrop);
  padding: 0 16px
}

@media (max-width: 320px) {
  :host {
    padding: 0
  }
}

:host.show {
  display: flex
}

:host.hide {
  display: none
}

:host .sdds-modal-close {
  border: none;
  background-color: transparent
}

:host .sdds-modal-close-btn {
  border: none;
  background-color: transparent
}

:host(.show) {
  display: flex
}

:host(.hide) {
  display: none
}

:root,
.sdds-mode-light {
  --sdds-divider-background: var(--sdds-grey-300)
}

.sdds-mode-dark {
  --sdds-divider-background: var(--sdds-grey-700)
}

.sdds-theme-colored {
  --sdds-divider-background: var(--sdds-blue-700)
}

:root,
.sdds-mode-light {
  --sdds-popover-canvas-color: var(--sdds-grey-958);
  --sdds-popover-canvas-background: var(--sdds-white)
}

.sdds-mode-dark {
  --sdds-popover-canvas-color: var(--sdds-grey-50);
  --sdds-popover-canvas-background: var(--sdds-grey-900)
}

ul.sdds-popover-menu-wrapper {
  box-sizing: border-box;
  list-style-type: none;
  margin: 0;
  margin-block: 0;
  padding: 0
}

ul.sdds-popover-menu-wrapper * {
  box-sizing: border-box
}

ul.sdds-popover-menu-wrapper li {
  font: var(--sdds-detail-02);
  letter-spacing: var(--sdds-detail-02-ls);
  color: var(--sdds-popover-menu-color);
  margin-bottom: 16px;
  position: relative;
  width: 100%
}

ul.sdds-popover-menu-wrapper li:last-child {
  margin-bottom: 0
}

ul.sdds-popover-menu-wrapper li a {
  text-decoration: none;
  position: relative;
  width: 100%;
  color: var(--sdds-popover-menu-color);
  display: flex;
  flex-wrap: nowrap
}

ul.sdds-popover-menu-wrapper li a i {
  position: relative;
  top: -1px;
  display: block;
  margin-right: 10px;
  width: 12px;
  height: 12px;
  object-fit: contain
}

ul.sdds-popover-menu-wrapper li a i svg {
  width: auto;
  height: auto
}

ul.sdds-popover-menu-wrapper li a:hover::before {
  z-index: -1;
  content: " ";
  display: block;
  position: absolute;
  width: calc(100% + 32px);
  height: calc(100% + 16px);
  top: -8px;
  left: -16px;
  background-color: var(--sdds-popover-menu-background-hover)
}

ul.sdds-popover-menu-wrapper li.divider {
  display: block;
  height: 1px;
  width: 100%
}

ul.sdds-popover-menu-wrapper li.divider::before {
  position: relative;
  content: " ";
  display: block;
  background-color: var(--sdds-popover-menu-divider-color);
  height: 1px;
  left: -16px;
  width: calc(100% + 32px)
}

:root,
.sdds-mode-light {
  --sdds-popover-menu-color: var(--sdds-grey-958);
  --sdds-popover-menu-background: var(--sdds-white);
  --sdds-popover-menu-background-hover: var(--sdds-grey-100);
  --sdds-popover-menu-divider-color: var(--sdds-grey-300)
}

.sdds-mode-dark {
  --sdds-popover-menu-color: var(--sdds-grey-50);
  --sdds-popover-menu-background: var(--sdds-grey-958);
  --sdds-popover-menu-background-hover: var(--sdds-grey-800);
  --sdds-popover-menu-divider-color: var(--sdds-grey-800)
}

:root,
html {
  --sdds-spinner-background: var(--sdds-blue-400);
  --sdds-spinner-background-inverted: var(--sdds-white);
  --sdds-spinner-speed: 1.8s;
  --sdds-spinner-speed-lg: 2s;
  --sdds-spinner-radius-xs: 8px;
  --sdds-spinner-radius-sm: 12px;
  --sdds-spinner-radius-md: 26px;
  --sdds-spinner-radius-lg: 42px;
  --sdds-spinner-radius: var(--sdds-spinner-radius-lg);
  --sdds-spinner-stroke-width-xs: 3px;
  --sdds-spinner-stroke-width-sm: 4px;
  --sdds-spinner-stroke-width-md: 6px;
  --sdds-spinner-stroke-width-lg: 8px;
  --sdds-spinner-stroke-width: var(--sdds-spinner-stroke-width-lg);
  --PI: 3.14159265358979
}

:host {
  box-sizing: border-box;
  display: block
}

:host * {
  box-sizing: border-box
}

.sdds-spinner-svg {
  width: calc(var(--sdds-spinner-radius) * 2);
  height: calc(var(--sdds-spinner-radius) * 2);
  transform: scale(-1, 1) rotate(-90deg)
}

.sdds-spinner-svg-xs {
  --sdds-spinner-radius: var(--sdds-spinner-radius-xs);
  --sdds-spinner-stroke-width: var(--sdds-spinner-stroke-width-xs);
  --sdds-spinner-animation-speed: var(--sdds-spinner-speed)
}

.sdds-spinner-svg-sm {
  --sdds-spinner-radius: var(--sdds-spinner-radius-sm);
  --sdds-spinner-stroke-width: var(--sdds-spinner-stroke-width-sm);
  --sdds-spinner-animation-speed: var(--sdds-spinner-speed)
}

.sdds-spinner-svg-md {
  --sdds-spinner-radius: var(--sdds-spinner-radius-md);
  --sdds-spinner-stroke-width: var(--sdds-spinner-stroke-width-md);
  --sdds-spinner-animation-speed: var(--sdds-spinner-speed)
}

.sdds-spinner-svg-lg {
  --sdds-spinner-radius: var(--sdds-spinner-radius-lg);
  --sdds-spinner-stroke-width: var(--sdds-spinner-stroke-width-lg);
  --sdds-spinner-animation-speed: var(--sdds-spinner-speed-lg)
}

.sdds-spinner-circle {
  cx: var(--sdds-spinner-radius);
  cy: var(--sdds-spinner-radius);
  r: calc(var(--sdds-spinner-radius) - var(--sdds-spinner-stroke-width) / 2);
  stroke-width: var(--sdds-spinner-stroke-width);
  fill: none;
  --sdds-spinner-stroke-dash: calc((2 * var(--PI)) * var(--sdds-spinner-radius));
  stroke-dasharray: var(--sdds-spinner-stroke-dash);
  stroke-width: var(--sdds-spinner-stroke-width);
  animation: dash var(--sdds-spinner-animation-speed) cubic-bezier(0.55, 0.15, 0.45, 0.85) infinite
}

.sdds-spinner-circle-standard {
  stroke: var(--sdds-spinner-background)
}

.sdds-spinner-circle-inverted {
  stroke: var(--sdds-spinner-background-inverted)
}

@keyframes dash {
  from {
    stroke-dashoffset: calc(-1 * var(--sdds-spinner-stroke-dash))
  }

  to {
    stroke-dashoffset: var(--sdds-spinner-stroke-dash)
  }
}

:root,
.sdds-mode-light {
  --sdds-stepper-icon-background: var(--sdds-grey-958);
  --sdds-stepper-color: var(--sdds-grey-958);
  --sdds-stepper-icon-step-border-color-warning: var(--sdds-negative);
  --sdds-stepper-icon-step-border-color-success: var(--sdds-positive);
  --sdds-stepper-icon-step-background-success: var(--sdds-positive);
  --sdds-stepper-label-text-color-upcoming: var(--sdds-blue-900);
  --sdds-stepper-label-border-color-upcoming: var(--sdds-blue-900);
  --sdds-stepper-label-text-opacity-upcoming: 0.38;
  --sdds-stepper-icon-sidetext-background-before: var(--sdds-blue-900);
  --sdds-stepper-icon-vertical-background-before: var(--sdds-blue-900);
  --sdds-stepper-icon-step-background-before: var(--sdds-blue-900);
  --sdds-stepper-icon-step-background-after: var(--sdds-blue-900);
  --sdds-stepper-color--inverted: var(--sdds-white);
  --sdds-stepper-border-color--inverted: var(--sdds-white);
  --sdds-stepper-background--inverted-before: var(--sdds-white);
  --sdds-stepper-background--inverted-after: var(--sdds-white);
  --sdds-stepper-icon-border-color-waring--inverted: var(--sdds-negative);
  --sdds-stepper-icon-border-color-success-after--inverted: var(--sdds-positive)
}

.sdds-mode-dark {
  --sdds-stepper-icon-background: var(--sdds-white);
  --sdds-stepper-color: var(--sdds-white);
  --sdds-stepper-icon-step-border-color-warning: var(--sdds-negative);
  --sdds-stepper-icon-step-border-color-success: var(--sdds-positive);
  --sdds-stepper-icon-step-background-success: var(--sdds-positive);
  --sdds-stepper-icon-sidetext-background-before: var(--sdds-white);
  --sdds-stepper-icon-vertical-background-before: var(--sdds-white);
  --sdds-stepper-icon-step-background-before: var(--sdds-white);
  --sdds-stepper-icon-step-background-after: var(--sdds-white);
  --sdds-stepper-label-text-color-upcoming: var(--sdds-blue-50);
  --sdds-stepper-label-border-color-upcoming: var(--sdds-blue-50);
  --sdds-stepper-label-text-opacity-upcoming: 0.38;
  --sdds-stepper-color--inverted: var(--sdds-grey-958);
  --sdds-stepper-border-color--inverted: var(--sdds-grey-958);
  --sdds-stepper-background--inverted-before: var(--sdds-grey-958);
  --sdds-stepper-background--inverted-after: var(--sdds-grey-958);
  --sdds-stepper-icon-border-color-waring--inverted: var(--sdds-negative);
  --sdds-stepper-icon-border-color-success-after--inverted: var(--sdds-positive)
}

:root,
.sdds-mode-light {
  --sdds-folder-tab-background-selected: var(--sdds-white);
  --sdds-folder-tab-background: var(--sdds-grey-100);
  --sdds-folder-tab-background-hover: var(--sdds-grey-200);
  --sdds-folder-tab-color: var(--sdds-blue-900);
  --sdds-folder-tab-color-opacity: 0.6;
  --sdds-folder-tab-color-opacity-disabled: 0.16;
  --sdds-folder-tab-color-opacity-selected: 1;
  --sdds-folder-tab-color-opacity-focus: 1;
  --sdds-folder-tab-color-opacity-hover: 1;
  --sdds-folder-tab-border-selected: var(--sdds-blue-400);
  --sdds-folder-tabs-scroll-btn-background: var(--sdds-folder-tab-background-primary);
  --sdds-folder-tabs-scroll-btn-background-hover: var(--sdds-grey-300);
  --sdds-folder-tabs-scroll-btn-background-active: var(--sdds-grey-400);
  --sdds-folder-tabs-scroll-btn-color: var(--sdds-grey-958);
  --sdds-folder-tab-background-primary: var(--sdds-grey-100);
  --sdds-folder-tab-background-secondary: var(--sdds-grey-100);
  --sdds-folder-tab-background: var(--sdds-folder-tab-background-primary);
  --sdds-folder-tab-background-selected-primary: var(--sdds-white);
  --sdds-folder-tab-background-selected-secondary: var(--sdds-grey-50);
  --sdds-folder-tab-divider-color: rgb(0 21 51 / 16%)
}

:root .sdds-mode-variant-primary,
.sdds-mode-light .sdds-mode-variant-primary {
  --sdds-folder-tab-background-selected: var(--sdds-folder-tab-background-selected-primary);
  --sdds-folder-tab-background: var(--sdds-folder-tab-background-primary)
}

:root .sdds-mode-variant-secondary,
.sdds-mode-light .sdds-mode-variant-secondary {
  --sdds-folder-tab-background-selected: var(--sdds-folder-tab-background-selected-secondary);
  --sdds-folder-tab-background: var(--sdds-folder-tab-background-secondary)
}

.sdds-mode-dark {
  --sdds-folder-tab-background-selected: var(--sdds-grey-900);
  --sdds-folder-tab-background: var(--sdds-grey-846);
  --sdds-folder-tab-background-hover: var(--sdds-grey-800);
  --sdds-folder-tab-color: var(--sdds-grey-50);
  --sdds-folder-tab-color-opacity: 0.6;
  --sdds-folder-tab-color-opacity-disabled: 0.16;
  --sdds-folder-tab-color-opacity-selected: 1;
  --sdds-folder-tab-color-opacity-focus: 1;
  --sdds-folder-tab-color-opacity-hover: 1;
  --sdds-folder-tab-border-selected: var(--sdds-blue-400);
  --sdds-folder-tabs-scroll-btn-color: var(--sdds-grey-50);
  --sdds-folder-tabs-scroll-btn-background: var(--sdds-folder-tab-background-primary);
  --sdds-folder-tabs-scroll-btn-background-hover: var(--sdds-grey-900);
  --sdds-folder-tabs-scroll-btn-background-active: var(--sdds-grey-900);
  --sdds-folder-tab-background-primary: var(--sdds-grey-846);
  --sdds-folder-tab-background-secondary: var(--sdds-grey-846);
  --sdds-folder-tab-background: var(--sdds-folder-tab-background-primary);
  --sdds-folder-tab-background-selected-primary: var(--sdds-grey-900);
  --sdds-folder-tab-background-selected-secondary: var(--sdds-grey-868);
  --sdds-folder-tab-divider-color: var(--sdds-blue-100)
}

.sdds-mode-dark .sdds-mode-variant-primary {
  --sdds-folder-tab-background-selected: var(--sdds-folder-tab-background-selected-primary);
  --sdds-folder-tab-background: var(--sdds-folder-tab-background-primary)
}

.sdds-mode-dark .sdds-mode-variant-secondary {
  --sdds-folder-tab-background-selected: var(--sdds-folder-tab-background-selected-secondary);
  --sdds-folder-tab-background: var(--sdds-folder-tab-background-secondary)
}

:root,
.sdds-mode-light {
  --sdds-inline-tabs-horizontal-divider-background: var(--sdds-blue-900);
  --sdds-inline-tabs-horizontal-divider-opacity: 0.16;
  --sdds-inline-tabs-tab-background-primary: var(--sdds-white);
  --sdds-inline-tabs-tab-background-secondary: var(--sdds-grey-50);
  --sdds-inline-tabs-background: var(--sdds-inline-tabs-tab-background-primary);
  --sdds-inline-tabs-scroll-btn-color: var(--sdds-grey-958);
  --sdds-inline-tabs-scroll-btn-background: var(--sdds-white);
  --sdds-inline-tabs-scroll-btn-background-hover: var(--sdds-grey-300);
  --sdds-inline-tabs-scroll-btn-background-active: var(--sdds-grey-400);
  --sdds-inline-tabs-scroll-btn-primary: var(--sdds-white);
  --sdds-inline-tabs-scroll-btn-secondary: var(--sdds-grey-50);
  --sdds-inline-tabs-tab-color: rgba(0 21 51 /60%);
  --sdds-inline-tabs-tab-color-selected: var(--sdds-grey-958);
  --sdds-inline-tabs-tab-color-disabled: var(--sdds-blue-900);
  --sdds-inline-tabs-tab-color-opacity-disabled: 0.38;
  --sdds-inline-tabs-tab-indicator-background-hover: #d1d5db;
  --sdds-inline-tabs-tab-color-hover: var(--sdds-grey-958);
  --sdds-inline-tabs-tab-color-focus: var(--sdds-grey-958);
  --sdds-inline-tabs-tab-color-active: var(--sdds-grey-958);
  --sdds-inline-tabs-tab-indicator-background-active: var(--sdds-blue-400)
}

:root .sdds-mode-variant-primary,
.sdds-mode-light .sdds-mode-variant-primary {
  --sdds-inline-tabs-background: var(--sdds-inline-tabs-tab-background-primary);
  --sdds-inline-tabs-scroll-btn-background: var(--sdds-inline-tabs-scroll-btn-primary)
}

:root .sdds-mode-variant-secondary,
.sdds-mode-light .sdds-mode-variant-secondary {
  --sdds-inline-tabs-background: var(--sdds-inline-tabs-tab-background-secondary);
  --sdds-inline-tabs-scroll-btn-background: var(--sdds-inline-tabs-scroll-btn-secondary)
}

.sdds-mode-dark {
  --sdds-inline-tabs-horizontal-divider-background: var(--sdds-blue-100);
  --sdds-inline-tabs-horizontal-divider-opacity: 0.24;
  --sdds-inline-tabs-tab-background-primary: var(--sdds-grey-900);
  --sdds-inline-tabs-tab-background-secondary: var(--sdds-grey-868);
  --sdds-inline-tabs-background: var(--sdds-inline-tabs-tab-background-primary);
  --sdds-inline-tabs-scroll-btn-color: var(--sdds-grey-50);
  --sdds-inline-tabs-scroll-btn-background: var(--sdds-grey-900);
  --sdds-inline-tabs-scroll-btn-background-hover: var(--sdds-grey-900);
  --sdds-inline-tabs-scroll-btn-background-active: var(--sdds-grey-900);
  --sdds-inline-tabs-scroll-btn-primary: var(--sdds-grey-900);
  --sdds-inline-tabs-scroll-btn-secondary: var(--sdds-grey-800);
  --sdds-inline-tab-color-opacity: 0.6;
  --sdds-inline-tabs-tab-color-opacity-disabled: 0.38;
  --sdds-inline-tab-color-opacity-selected: 1;
  --sdds-inline-tabs-tab-color: rgba(228 233 241/60%);
  --sdds-inline-tabs-tab-color-selected: var(--sdds-grey-50);
  --sdds-inline-tabs-tab-color-disabled: var(--sdds-blue-50);
  --sdds-inline-tabs-tab-indicator-background-hover: #373d46;
  --sdds-inline-tabs-tab-color-hover: var(--sdds-grey-50);
  --sdds-inline-tabs-tab-color-focus: var(--sdds-grey-50);
  --sdds-inline-tabs-tab-color-active: var(--sdds-grey-50);
  --sdds-inline-tabs-tab-indicator-background-active: var(--sdds-blue-300)
}

.sdds-mode-dark .sdds-mode-variant-primary {
  --sdds-inline-tabs-background: var(--sdds-inline-tabs-tab-background-primary);
  --sdds-inline-tabs-scroll-btn-background: var(--sdds-inline-tabs-scroll-btn-primary)
}

.sdds-mode-dark .sdds-mode-variant-secondary {
  --sdds-inline-tabs-background: var(--sdds-inline-tabs-tab-background-secondary);
  --sdds-inline-tabs-scroll-btn-background: var(--sdds-inline-tabs-scroll-btn-secondary)
}

:root,
.sdds-mode-light {
  --sdds-navigation-tabs-horizontal-divider-background: var(--sdds-blue-900);
  --sdds-navigation-tabs-horizontal-divider-opacity: 0.16;
  --sdds-navigation-tabs-background: var(--sdds-white);
  --sdds-navigation-tabs-scroll-btn-background: var(--sdds-grey-50);
  --sdds-navigation-tabs-scroll-btn-background-hover: var(--sdds-grey-300);
  --sdds-navigation-tabs-scroll-btn-background-active: var(--sdds-grey-400);
  --sdds-navigation-tabs-tab-color: rgba(0 21 51/60%);
  --sdds-navigation-tabs-tab-color-selected: var(--sdds-grey-958);
  --sdds-navigation-tabs-tab-color-disabled: var(--sdds-blue-900);
  --sdds-navigation-tabs-tab-color-opacity-disabled: 0.38;
  --sdds-navigation-tabs-tab-indicator-background-hover: #d1d5db;
  --sdds-navigation-tabs-tab-color-hover: var(--sdds-grey-958);
  --sdds-navigation-tabs-tab-color-focus: var(--sdds-grey-958);
  --sdds-navigation-tabs-tab-color-active: var(--sdds-grey-958);
  --sdds-navigation-tabs-tab-indicator-background-active: var(--sdds-blue-400);
  --sdds-navigation-tabs-tab-indicator-background-hover: #d1d5db
}

.sdds-mode-dark {
  --sdds-navigation-tabs-horizontal-divider-background: var(--sdds-blue-100);
  --sdds-navigation-tabs-horizontal-divider-opacity: 0.24;
  --sdds-navigation-tabs-background: var(--sdds-grey-900);
  --sdds-navigation-tabs-scroll-btn-background: var(--sdds-grey-800);
  --sdds-navigation-tabs-scroll-btn-background-hover: var(--sdds-grey-900);
  --sdds-navigation-tabs-scroll-btn-background-active: var(--sdds-grey-900);
  --sdds-navigation-tabs-tab-color: rgba(228 233 241 /60%);
  --sdds-navigation-tabs-tab-color-selected: var(--sdds-grey-50);
  --sdds-navigation-tabs-tab-color-disabled: var(--sdds-blue-50);
  --sdds-navigation-tabs-tab-color-opacity-disabled: 0.38;
  --sdds-navigation-tabs-tab-indicator-background-hover: #373d46;
  --sdds-navigation-tabs-tab-color-hover: var(--sdds-grey-50);
  --sdds-navigation-tabs-tab-color-focus: var(--sdds-grey-50);
  --sdds-navigation-tabs-tab-color-active: var(--sdds-grey-50);
  --sdds-navigation-tabs-tab-indicator-background-active: var(--sdds-blue-300);
  --sdds-navigation-tabs-tab-indicator-background-hover: #373d46
}

.sdds-mode-light {
  --sdds-textarea-background-primary: var(--sdds-grey-50);
  --sdds-textarea-background-secondary: var(--sdds-white);
  --sdds-textarea-background: var(--sdds-textarea-background-primary);
  --sdds-textarea-color: var(--sdds-grey-868);
  --sdds-textarea-placeholder: var(--sdds-grey-500);
  --sdds-textarea-placeholder-focus-color: var(--sdds-grey-500);
  --sdds-textarea-disabled-color: var(--sdds-grey-400);
  --sdds-textarea-disabled-background-primary: var(--sdds-grey-50);
  --sdds-textarea-disabled-background-secondary: var(--sdds-white);
  --sdds-textarea-disabled-background: var(--sdds-textarea-disabled-background-primary);
  --sdds-textarea-disabled-placeholder: var(--sdds-grey-400);
  --sdds-textarea-disabled-label: var(--sdds-grey-400);
  --sdds-textarea-label-color: var(--sdds-grey-958);
  --sdds-textarea-label-inside-color: var(--sdds-grey-700);
  --sdds-textarea-placeholder-focus-color: var(--sdds-grey-500);
  --sdds-textarea-helper: var(--sdds-grey-700);
  --sdds-textarea-helper-error: var(--sdds-negative);
  --sdds-textarea-bar: var(--sdds-blue-400);
  --sdds-textarea-bar-error: var(--sdds-negative);
  --sdds-textarea-textcounter: var(--sdds-grey-700);
  --sdds-textarea-textcounter-divider: var(--sdds-grey-500);
  --sdds-textarea-border-bottom: var(--sdds-grey-600);
  --sdds-textarea-border-bottom-hover: var(--sdds-grey-800);
  --sdds-textarea-border-bottom-success: var(--sdds-grey-958);
  --sdds-textarea-border-bottom-error: var(--sdds-negative);
  --sdds-textarea-icon-read-only-color: var(--sdds-grey-958);
  --sdds-textarea-icon-read-only-label-color: var(--sdds-grey-958);
  --sdds-textarea-resize-icon: var(--sdds-grey-500)
}

.sdds-mode-light .sdds-mode-variant-primary {
  --sdds-textarea-background: var(--sdds-textarea-background-primary);
  --sdds-textarea-disabled-background: var(--sdds-textarea-disabled-background-primary)
}

.sdds-mode-light .sdds-mode-variant-secondary {
  --sdds-textarea-background: var(--sdds-textarea-background-secondary);
  --sdds-textarea-disabled-background: var(--sdds-textarea-disabled-background-secondary)
}

.sdds-mode-dark {
  --sdds-textarea-background-primary: var(--sdds-grey-900);
  --sdds-textarea-background-secondary: var(--sdds-grey-868);
  --sdds-textarea-background: var(--sdds-textarea-background-primary);
  --sdds-textarea-color: var(--sdds-grey-200);
  --sdds-textarea-placeholder: var(--sdds-grey-600);
  --sdds-textarea-placeholder-focus-color: var(--sdds-grey-700);
  --sdds-textarea-disabled-color: var(--sdds-grey-800);
  --sdds-textarea-disabled-background-primary: var(--sdds-grey-900);
  --sdds-textarea-disabled-background-secondary: var(--sdds-grey-868);
  --sdds-textarea-disabled-background: var(--sdds-textarea-disabled-background-primary);
  --sdds-textarea-disabled-placeholder: var(--sdds-grey-800);
  --sdds-textarea-disabled-label: var(--sdds-grey-700);
  --sdds-textarea-label-color: var(--sdds-grey-100);
  --sdds-textarea-label-inside-color: var(--sdds-grey-400);
  --sdds-textarea-placeholder-focus-color: var(--sdds-grey-700);
  --sdds-textarea-helper: var(--sdds-grey-600);
  --sdds-textarea-helper-error: var(--sdds-negative);
  --sdds-textarea-bar: var(--sdds-blue-400);
  --sdds-textarea-bar-error: var(--sdds-negative);
  --sdds-textarea-textcounter: var(--sdds-grey-600);
  --sdds-textarea-textcounter-divider: var(--sdds-grey-700);
  --sdds-textarea-border-bottom: var(--sdds-grey-600);
  --sdds-textarea-border-bottom-hover: var(--sdds-grey-400);
  --sdds-textarea-border-bottom-success: var(--sdds-grey-50);
  --sdds-textarea-border-bottom-error: var(--sdds-negative);
  --sdds-textarea-icon-read-only-color: var(--sdds-grey-100);
  --sdds-textarea-icon-read-only-label-color: var(--sdds-grey-50);
  --sdds-textarea-resize-icon: var(--sdds-grey-500)
}

.sdds-mode-dark .sdds-mode-variant-primary {
  --sdds-textarea-background: var(--sdds-textarea-background-primary);
  --sdds-textarea-disabled-background: var(--sdds-textarea-disabled-background-primary)
}

.sdds-mode-dark .sdds-mode-variant-secondary {
  --sdds-textarea-background: var(--sdds-textarea-background-secondary);
  --sdds-textarea-disabled-background: var(--sdds-textarea-disabled-background-secondary)
}

:root,
.sdds-mode-light {
  --sdds-textfield-background-primary: var(--sdds-grey-50);
  --sdds-textfield-background-secondary: var(--sdds-white);
  --sdds-textfield-background: var(--sdds-textfield-background-primary);
  --sdds-textfield-color: var(--sdds-grey-868);
  --sdds-textfield-placeholder: var(--sdds-grey-500);
  --sdds-textfield-background-disabled-primary: var(--sdds-grey-50);
  --sdds-textfield-background-disabled-secondary: var(--sdds-white);
  --sdds-textfield-background-disabled: var(--sdds-textfield-background-disabled-primary);
  --sdds-textfield-color-disabled: var(--sdds-grey-400);
  --sdds-textfield-placeholder-disabled: var(--sdds-grey-400);
  --sdds-textfield-label-disabled: var(--sdds-grey-400);
  --sdds-textfield-ps-color-disabled: var(--sdds-grey-400);
  --sdds-textfield-label-color: var(--sdds-grey-958);
  --sdds-textfield-label-inside-color: var(--sdds-grey-700);
  --sdds-textfield-placeholder-focus-color: var(--sdds-grey-500);
  --sdds-textfield-bar: var(--sdds-blue-400);
  --sdds-textfield-helper: var(--sdds-grey-700);
  --sdds-textfield-border-bottom-error: var(--sdds-negative);
  --sdds-textfield-helper-error: var(--sdds-negative);
  --sdds-textfield-bar-error: var(--sdds-negative);
  --sdds-textfield-icon-error: var(--sdds-negative);
  --sdds-textfield-textcounter: var(--sdds-grey-700);
  --sdds-textfield-textcounter-divider: var(--sdds-grey-500);
  --sdds-textfield-ps-color: var(--sdds-grey-600);
  --sdds-textfield-ps-color-error: var(--sdds-negative);
  --sdds-textfield-resize-icon: var(--sdds-grey-500);
  --sdds-textfield-border-bottom: var(--sdds-grey-600);
  --sdds-textfield-border-bottom-hover: var(--sdds-grey-800);
  --sdds-textfield-border-bottom-success: var(--sdds-grey-958);
  --sdds-textfield-icon-read-only-color: var(--sdds-grey-958);
  --sdds-textfield-icon-read-only-label-color: var(--sdds-grey-958)
}

:root .sdds-mode-variant-primary,
.sdds-mode-light .sdds-mode-variant-primary {
  --sdds-textfield-background: var(--sdds-textfield-background-primary);
  --sdds-textfield-background-disabled: var(--sdds-textfield-background-disabled-primary)
}

:root .sdds-mode-variant-secondary,
.sdds-mode-light .sdds-mode-variant-secondary {
  --sdds-textfield-background: var(--sdds-textfield-background-secondary);
  --sdds-textfield-background-disabled: var(--sdds-textfield-background-disabled-secondary)
}

.sdds-mode-dark {
  --sdds-textfield-background-primary: var(--sdds-grey-900);
  --sdds-textfield-background: var(--sdds-textfield-background-primary);
  --sdds-textfield-background-secondary: var(--sdds-grey-868);
  --sdds-textfield-color: var(--sdds-grey-200);
  --sdds-textfield-placeholder: var(--sdds-grey-600);
  --sdds-textfield-ps-color-disabled: var(--sdds-grey-700);
  --sdds-textfield-color-disabled: var(--sdds-grey-800);
  --sdds-textfield-background-disabled-primary: var(--sdds-grey-900);
  --sdds-textfield-background-disabled-secondary: var(--sdds-grey-868);
  --sdds-textfield-background-disabled: var(--sdds-textfield-background-disabled-primary);
  --sdds-textfield-placeholder-disabled: var(--sdds-grey-800);
  --sdds-textfield-label-disabled: var(--sdds-grey-700);
  --sdds-textfield-label-color: var(--sdds-grey-100);
  --sdds-textfield-label-inside-color: var(--sdds-grey-400);
  --sdds-textfield-placeholder-focus-color: var(--sdds-grey-700);
  --sdds-textfield-bar: var(--sdds-blue-400);
  --sdds-textfield-helper: var(--sdds-grey-600);
  --sdds-textfield-helper-error: var(--sdds-negative);
  --sdds-textfield-bar-error: var(--sdds-negative);
  --sdds-textfield-icon-error: var(--sdds-negative);
  --sdds-textfield-textcounter: var(--sdds-grey-600);
  --sdds-textfield-textcounter-divider: var(--sdds-grey-700);
  --sdds-textfield-ps-color: var(--sdds-grey-100);
  --sdds-textfield-resize-icon: var(--sdds-grey-500);
  --sdds-textfield-border-bottom: var(--sdds-grey-600);
  --sdds-textfield-border-bottom-hover: var(--sdds-grey-400);
  --sdds-textfield-border-bottom-success: var(--sdds-grey-50);
  --sdds-textfield-border-bottom-error: var(--sdds-negative);
  --sdds-textfield-icon-read-only-color: var(--sdds-grey-100);
  --sdds-textfield-icon-read-only-label-color: var(--sdds-grey-50)
}

.sdds-mode-dark .sdds-mode-variant-primary {
  --sdds-textfield-background: var(--sdds-textfield-background-primary);
  --sdds-textfield-background-disabled: var(--sdds-textfield-background-disabled-primary)
}

.sdds-mode-dark .sdds-mode-variant-secondary {
  --sdds-textfield-background: var(--sdds-textfield-background-secondary);
  --sdds-textfield-background-disabled: var(--sdds-textfield-background-disabled-secondary)
}

:root,
.sdds-mode-light {
  --sdds-tooltip-background: var(--sdds-grey-800);
  --sdds-tooltip-color: var(--sdds-white)
}

.sdds-mode-dark {
  --sdds-tooltip-background: var(--sdds-grey-200);
  --sdds-tooltip-color: var(--sdds-grey-958)
}

:root {
  --johnny-red: green
}

body {
  font-family: "Scania Sans Semi Condensed", "Scania Sans Condensed", Arial, Helvetica, sans-serif;
  font-size: 14px;
  margin: 0;
  flex: 1
}

html,
.sdds-mode-light {
  color: var(--sdds-grey-958);
  background-color: var(--sdds-white)
}

html .sdds-mode-variant-primary,
.sdds-mode-light .sdds-mode-variant-primary {
  color: var(--sdds-grey-958);
  background-color: var(--sdds-white)
}

html .sdds-mode-variant-secondary,
.sdds-mode-light .sdds-mode-variant-secondary {
  color: var(--sdds-grey-958);
  background-color: var(--sdds-grey-50)
}

.sdds-mode-dark {
  color: var(--sdds-grey-100);
  background-color: var(--sdds-grey-958)
}

.sdds-mode-dark .sdds-mode-variant-primary {
  color: var(--sdds-grey-100);
  background-color: var(--sdds-grey-958)
}

.sdds-mode-dark .sdds-mode-variant-secondary {
  color: var(--sdds-grey-100);
  background-color: var(--sdds-grey-900)
}